// src/services/blogService.js
import axios from 'axios';
import API_URL from '../utils/config/api';

class BlogService {
    constructor() {
        this.baseURL = API_URL;
    }

    async createBlog(blogData) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post(
                `${this.baseURL}/api/blog/posts`,
                blogData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error creating blog:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to create blog post');
        }
    }

    async updateBlog(id, blogData) {
        try {

            // Ensure postId is a number
            const numericid = parseInt(id, 10);
            if (isNaN(numericid)) {
                throw new Error('Invalid post ID');
            }
            const token = localStorage.getItem('access_token');
            const response = await axios.put(
                `${this.baseURL}/api/blog/posts/${id}`,
                blogData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error updating blog:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to update blog post');
        }
    }

    async getAllBlogs(params = {}) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(
                `${this.baseURL}/api/blog/posts`,
                {
                    headers: {Authorization: `Bearer ${token}`},
                    params
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching blogs:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to fetch blog posts');
        }
    }

    async getBlogBySlug(slug) {
        try {
            const response = await axios.get(
                `${this.baseURL}/api/blog/posts/slug/${slug}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching blog:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to fetch blog post');
        }
    }

    async getBlogById(id) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(
                `${this.baseURL}/api/blog/posts/${id}`,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching blog:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to fetch blog post');
        }
    }

    async deleteBlog(id) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.delete(
                `${this.baseURL}/api/blog/posts/${id}`,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error deleting blog:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to delete blog post');
        }
    }

    // Add to src/services/blogService.js

    async getFeaturedPosts() {
        try {
            const response = await axios.get(
                `${this.baseURL}/api/blog/posts/featured`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching featured posts:', error);
            throw new Error(error.response?.data?.detail || 'Failed to fetch featured posts');
        }
    }

// For email subscription
    async subscribeNewsletter(email) {
        try {
            const response = await axios.post(
                `${this.baseURL}/api/blog/newsletter/subscribe`,
                {email},  // Send as request body
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error subscribing to newsletter:', error);
            throw new Error(error.response?.data?.detail || 'Failed to subscribe to newsletter');
        }
    }

// In blogService.js
    async deleteBlogPost(id) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.delete(
                `${this.baseURL}/api/blog/posts/${id}`,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error deleting blog post:', error);
            throw new Error(error.response?.data?.detail || 'Failed to delete blog post');
        }
    }

    async toggleFeaturePost(id) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.put(
                `${this.baseURL}/api/blog/posts/${id}/feature`,
                {},
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error toggling feature status:', error);
            throw new Error(error.response?.data?.detail || 'Failed to update feature status');
        }
    }

    async getPublishedBlogs(params = {}) {
        try {
            let queryParams = {};

            // If params is a string, it's the category
            if (typeof params === 'string') {
                queryParams.category = params;
            }
            // If params is an object, spread its properties
            else if (typeof params === 'object') {
                queryParams = {
                    page: params.page || 1,
                    limit: params.limit || 12,
                    ...(params.category && {category: params.category})
                };
            }

            const response = await axios.get(
                `${this.baseURL}/api/blog/posts/published`,
                {params: queryParams}
            );

            // If the response doesn't have posts property, wrap the data in a posts property
            if (response.data && !response.data.posts) {
                return {
                    posts: Array.isArray(response.data) ? response.data : [],
                    total: Array.isArray(response.data) ? response.data.length : 0,
                    page: queryParams.page || 1,
                    limit: queryParams.limit || 12
                };
            }

            return response.data;
        } catch (error) {
            console.error('Error fetching published blogs:', error.response?.data || error.message);
            throw new Error(error.response?.data?.detail || 'Failed to fetch published blog posts');
        }
    }

    // Add to your blogService.js

    async getRelatedPosts(category, excludeId = null) {
        try {
            const response = await axios.get(
                `${this.baseURL}/api/blog/posts/related`,
                {
                    params: {
                        category,
                        exclude_id: excludeId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching related posts:', error);
            throw new Error(error.response?.data?.detail || 'Failed to fetch related posts');
        }
    }

    async searchPosts(searchTerm, params = {}) {
        try {
            const response = await axios.get(
                `${this.baseURL}/api/blog/posts/search`,
                {
                    params: {
                        q: searchTerm,
                        ...params
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error searching posts:', error);
            throw new Error(error.response?.data?.detail || 'Failed to search posts');
        }
    }

    // Image handling methods
    async uploadImage(file, type = 'blog') {
        try {
            const token = localStorage.getItem('access_token');
            const formData = new FormData();
            formData.append('file', file);
            formData.append('image_type', type);

            const response = await axios.post(
                `${this.baseURL}/api/blog/upload/image`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error uploading image:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to upload image');
        }
    }

    async deleteImage(imageId) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.delete(
                `${this.baseURL}/api/blog/upload/image/${imageId}`,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error deleting image:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to delete image');
        }
    }

    // Draft management methods
    async saveDraft(blogData) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post(
                `${this.baseURL}/api/blog/posts`,
                {...blogData, status: 'draft'},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error saving draft:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to save draft');
        }
    }

    async getDrafts() {
        try {
            return await this.getAllBlogs({status: 'draft'});
        } catch (error) {
            console.error('Error fetching drafts:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to fetch drafts');
        }
    }

    // Blog analytics methods
    async getBlogStats(blogId) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(
                `${this.baseURL}/api/blog/posts/${blogId}/stats`,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching blog stats:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to fetch blog statistics');
        }
    }

    // Category operations
    async getCategories() {
        try {
            const response = await axios.get(`${this.baseURL}/api/blog/categories`);
            return response.data;
        } catch (error) {
            console.error('Error fetching categories:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to fetch categories');
        }
    }

    // Search functionality
    async searchBlogs(query, params = {}) {
        try {
            const response = await axios.get(
                `${this.baseURL}/api/blog/posts/search`,
                {params: {q: query, ...params}}
            );
            return response.data;
        } catch (error) {
            console.error('Error searching blogs:', error.response?.data);
            throw new Error(error.response?.data?.detail || 'Failed to search blogs');
        }
    }
}

const blogService = new BlogService();
export {blogService};
export default blogService;