import React, {useState} from 'react';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {Eye, EyeOff, X} from 'lucide-react';
import {toast} from 'react-hot-toast';
import {GoogleLogin} from '@react-oauth/google';
import '../styles/Signup.css';
import API_URL, {ENDPOINTS} from '../utils/config/api';
import Terms from './Legal/Terms';
import Privacy from './Legal/Privacy';

function Signup() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        agreeToTerms: false
    });

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (formData.password !== formData.confirmPassword) {
            toast.error('Passwords do not match');
            setLoading(false);
            return;
        }

        if (!formData.agreeToTerms) {
            toast.error('Please agree to the Terms and Conditions and Privacy Policy');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(`${API_URL}${ENDPOINTS.SIGNUP}`, formData, {
                withCredentials: false
            });

            if (response.data.message) {
                toast.success('Account created successfully');
                navigate('/verify', {
                    state: {email: formData.email},
                    replace: true
                });
            }
        } catch (error) {
            console.error('Signup error:', error);
            toast.error(error.response?.data?.detail || 'Signup failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}${ENDPOINTS.GOOGLE_AUTH}`, {
                token: credentialResponse.credential
            });

            toast.success('Account created successfully');
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            navigate('/dashboard');
        } catch (error) {
            console.error('Google signup error:', error);
            toast.error(error.response?.data?.detail || 'Google signup failed');
        } finally {
            setLoading(false);
        }
    };

    // Modal Component
    const Modal = ({isOpen, onClose, title, children}) => {
        if (!isOpen) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-header">
                        <h2>{title}</h2>
                        <button className="modal-close" onClick={onClose}>
                            <X size={24}/>
                        </button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="signup-page">
            <div className="galaxy-background"></div>
            <div className="galaxy-spiral"></div>

            <div className="signup-container">
                <h1>Create Account</h1>

                <div className="social-login">
                    <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onError={() => {
                            toast.error('Google Sign Up Failed');
                        }}
                        useOneTap={true}
                        theme="filled_black"
                        shape="pill"
                        text="signup_with"
                        size="large"
                        width="300"
                    />
                </div>

                <div className="divider">
                    <span>OR</span>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <input
                                type="text"
                                name="first_name"
                                placeholder="First Name *"
                                value={formData.first_name}
                                onChange={handleChange}
                                required
                                disabled={loading}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="last_name"
                                placeholder="Last Name *"
                                value={formData.last_name}
                                onChange={handleChange}
                                required
                                disabled={loading}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email Address *"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            disabled={loading}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="tel"
                            name="phoneNumber"
                            placeholder="Phone Number *"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    </div>

                    <div className="form-group password-group">
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password *"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            disabled={loading}
                        />
                        <button
                            type="button"
                            className="password-toggle"
                            onClick={() => setShowPassword(!showPassword)}
                            aria-label={showPassword ? "Hide password" : "Show password"}
                        >
                            {showPassword ? <EyeOff size={20}/> : <Eye size={20}/>}
                        </button>
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password *"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                            disabled={loading}
                        />
                    </div>

                    <div className="form-group checkbox-group">
                        <input
                            type="checkbox"
                            name="agreeToTerms"
                            id="agreeToTerms"
                            checked={formData.agreeToTerms}
                            onChange={handleChange}
                            required
                            disabled={loading}
                        />
                        <label htmlFor="agreeToTerms">
                            I agree to the{' '}
                            <button
                                type="button"
                                className="modal-link"
                                onClick={() => setShowTerms(true)}
                            >
                                Terms & Conditions
                            </button>
                            {' '}
                            and{' '}
                            <button
                                type="button"
                                className="modal-link"
                                onClick={() => setShowPrivacy(true)}
                            >
                                Privacy Policy
                            </button>
                        </label>
                    </div>

                    <button type="submit" disabled={loading || !formData.agreeToTerms}>
                        {loading ? 'Creating Account...' : 'Create Account'}
                    </button>

                    <div className="login-link">
                        Already have an account? <Link to="/login">Login here</Link>
                    </div>
                </form>
            </div>

            {/* Terms Modal */}
            <Modal
                isOpen={showTerms}
                onClose={() => setShowTerms(false)}
                title="Terms & Conditions"
            >
                <Terms isModal={true}/>
            </Modal>

            {/* Privacy Modal */}
            <Modal
                isOpen={showPrivacy}
                onClose={() => setShowPrivacy(false)}
                title="Privacy Policy"
            >
                <Privacy isModal={true}/>
            </Modal>
        </div>
    );
}

export default Signup;
