// src/pages/Blog/components/BlogEditor.jsx
import React, {useRef, useState} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {Button} from '../../../components/ui/button';
import {Input} from '../../../components/ui/input';
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue,} from '../../../components/ui/select';
import {AlertCircle} from 'lucide-react';

const BlogEditor = ({onSave, initialData, loading}) => {
    const [title, setTitle] = useState(initialData?.title || '');
    const [content, setContent] = useState(initialData?.content || '');
    const [category, setCategory] = useState(initialData?.category || 'federal-tax');
    const [metaTitle, setMetaTitle] = useState(initialData?.metaTitle || '');
    const [metaDescription, setMetaDescription] = useState(initialData?.metaDescription || '');
    const [keywords, setKeywords] = useState(initialData?.keywords?.join(', ') || '');
    const [status, setStatus] = useState(initialData?.status || 'draft');
    const [uploadError, setUploadError] = useState('');
    const editorRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (typeof onSave !== 'function') {
            console.error('onSave prop is missing or not a function');
            return;
        }

        const blogData = {
            title,
            content: editorRef.current?.getContent() || content,
            category,
            meta_title: metaTitle,
            meta_description: metaDescription,
            keywords: keywords.split(',').map(k => k.trim()).filter(Boolean),
            status,
            published_date: status === 'published' ? new Date().toISOString() : null
        };

        try {
            await onSave(blogData);
        } catch (error) {
            console.error('Failed to save blog:', error);
            // Handle save error appropriately
        }
    };

    const handleImageUpload = async (blobInfo) => {
        try {
            setUploadError('');
            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());

            const response = await fetch('/api/blog/upload/image', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Upload failed');
            }

            const data = await response.json();
            // Return the complete URL including domain
            return `${window.location.origin}${data.url}`;
        } catch (error) {
            console.error('Image upload failed:', error);
            setUploadError(error.message || 'Failed to upload image. Please try again.');
            throw error;
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            {uploadError && (
                <div className="p-4 bg-red-900/30 border border-red-500/50 rounded-lg flex items-center gap-2">
                    <AlertCircle className="text-red-400" size={20}/>
                    <span className="text-red-400">{uploadError}</span>
                </div>
            )}

            <div className="space-y-2">
                <label className="text-sm font-medium text-gray-200">Blog Title</label>
                <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter blog title"
                    className="w-full"
                    required
                />
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-200">Category</label>
                    <Select value={category} onValueChange={setCategory}>
                        <SelectTrigger>
                            <SelectValue/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="federal-tax">Federal Tax</SelectItem>
                            <SelectItem value="state-tax">State Tax</SelectItem>
                            <SelectItem value="stock-market">Stock Market</SelectItem>
                            <SelectItem value="ai-technology">AI Technology</SelectItem>
                            <SelectItem value="tax-planning">Tax Planning</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-200">Status</label>
                    <Select value={status} onValueChange={setStatus}>
                        <SelectTrigger>
                            <SelectValue/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="draft">Draft</SelectItem>
                            <SelectItem value="published">Published</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="space-y-2">
                <label className="text-sm font-medium text-gray-200">Meta Title (SEO)</label>
                <Input
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                    placeholder="Enter SEO title"
                    maxLength={60}
                />
                <p className="text-xs text-gray-400">{60 - metaTitle.length} characters remaining</p>
            </div>

            <div className="space-y-2">
                <label className="text-sm font-medium text-gray-200">Meta Description (SEO)</label>
                <textarea
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                    placeholder="Enter SEO description"
                    maxLength={160}
                    className="w-full min-h-[100px] rounded-md border border-gray-700 bg-gray-800 px-3 py-2 text-sm"
                />
                <p className="text-xs text-gray-400">{160 - metaDescription.length} characters remaining</p>
            </div>

            <div className="space-y-2">
                <label className="text-sm font-medium text-gray-200">Keywords (comma-separated)</label>
                <Input
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    placeholder="Enter keywords"
                />
            </div>

            <div className="space-y-2">
                <label className="text-sm font-medium text-gray-200">Content</label>
                <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    apiKey="gwq5i1vjtqd7j7cebpogafnv02a2s5ba3u0k23l410acblpa"
                    init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                            'code', 'codesample'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | image media | code codesample | help',
                        content_style: `
                            body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial; font-size: 14px; }
                            img { max-width: 100%; height: auto; }
                            pre { background-color: #2d3748; color: #e2e8f0; padding: 1rem; border-radius: 0.375rem; }
                            code { background-color: #2d3748; color: #e2e8f0; padding: 0.25rem; border-radius: 0.25rem; }
                        `,
                        // URL handling configurations
                        relative_urls: false,
                        remove_script_host: false,
                        convert_urls: true,

                        // Image handling configurations
                        images_upload_handler: handleImageUpload,
                        images_upload_credentials: true,
                        images_reuse_filename: true,
                        paste_data_images: true,
                        image_advtab: true,
                        images_file_types: 'jpg,jpeg,png,gif,webp',
                        automatic_uploads: true,
                        file_picker_types: 'image',

                        // Image upload timeout
                        images_upload_timeout: 30000,

                        // Additional image settings
                        image_dimensions: true,
                        image_class_list: [
                            {title: 'Responsive', value: 'img-fluid'}
                        ],

                        // Theme settings
                        skin: 'oxide-dark',
                        content_css: 'dark'
                    }}
                    value={content}
                    onEditorChange={setContent}
                />
            </div>

            <div className="flex justify-end gap-4">
                <Button
                    type="button"
                    variant="outline"
                    onClick={() => window.history.back()}
                    disabled={loading}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    className="bg-blue-600 hover:bg-blue-700"
                    disabled={loading}
                >
                    {loading ? 'Saving...' : (status === 'published' ? 'Publish' : 'Save Draft')}
                </Button>
            </div>
        </form>
    );
};

export default BlogEditor;