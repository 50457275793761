// src/components/TaxAnalysis/sections/SelfEmploymentAnalysis.jsx
import React from 'react';
import {Card} from '../../../components/ui/card';
import {motion} from 'framer-motion';
import {
    Briefcase,
    Building2,
    FileSpreadsheet,
    CheckCircle,
    XCircle,
    ArrowRight,
    Calculator,
    DollarSign,
    AlertTriangle
} from 'lucide-react';

export const SelfEmploymentAnalysis = ({data}) => {
    const formatCurrency = (amount) => {
        if (!amount) return '$0.00';
        const cleanAmount = amount.toString().replace(/[^0-9.]/g, '');
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(parseFloat(cleanAmount));
    };

    // Parse self-employment data
    const parseSelfEmployment = (content) => {
        if (!content) return null;

        const contentStr = content.toString();

        // Check if there's any self-employment income
        const hasIncome = !contentStr.toLowerCase().includes('no self-employment income');

        // Extract LLC analysis if present
        const llcAnalysis = {
            currentStructure: contentStr.match(/Current tax structure:\s*([^.]+)/i)?.[1] || '',
            llcCalculation: contentStr.match(/Calculation with LLC:\s*([^.]+)/i)?.[1] || '',
            potentialSavings: contentStr.match(/Potential tax savings:\s*\$?([\d,.]+)/i)?.[1] || '0',
            steps: contentStr.match(/Implementation steps:\s*([^.]+[.]?)/i)?.[1] || ''
        };

        // Extract business deductions
        const deductionsMatch = contentStr.match(/Business Deductions Review[^:]*:([\s\S]+?)(?=\n\n|\*\*|$)/i);
        const deductions = deductionsMatch ? {
            calculations: deductionsMatch[1].match(/Calculate[^:]*:\s*([^.]+)/i)?.[1] || '',
            savings: deductionsMatch[1].match(/savings comparison:\s*([^.]+)/i)?.[1] || ''
        } : null;

        // Extract specific recommendations if any
        const recommendations = [];
        const recPattern = /- ([^:\n]+):\s*([^.]+)/g;
        let recMatch;
        while ((recMatch = recPattern.exec(contentStr)) !== null) {
            recommendations.push({
                title: recMatch[1],
                description: recMatch[2]
            });
        }

        return {
            hasIncome,
            llcAnalysis,
            deductions,
            recommendations
        };
    };

    const selfEmploymentData = parseSelfEmployment(data);

    return (
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
        >
            <Card className="p-6 bg-gradient-to-r from-indigo-900/30 to-blue-900/30">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold text-white flex items-center">
                        <Briefcase className="w-8 h-8 mr-2 text-indigo-400"/>
                        Self-Employment Analysis
                    </h2>
                    <Building2 className="w-6 h-6 text-indigo-400"/>
                </div>

                {!selfEmploymentData?.hasIncome ? (
                    <div className="text-center py-8 bg-gray-900/50 rounded-lg">
                        <AlertTriangle className="w-12 h-12 text-yellow-500 mx-auto mb-3"/>
                        <p className="text-gray-400">No self-employment income reported in current tax situation.</p>
                    </div>
                ) : (
                    <div className="space-y-6">
                        {/* LLC Analysis Section */}
                        {selfEmploymentData.llcAnalysis && (
                            <motion.div
                                initial={{x: -20, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                className="bg-gray-900/50 p-4 rounded-lg border border-indigo-500/30"
                            >
                                <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
                                    <Building2 className="w-5 h-5 mr-2 text-indigo-400"/>
                                    LLC Formation Analysis
                                </h3>

                                <div className="space-y-4">
                                    {/* Current Structure */}
                                    <div className="flex items-center text-gray-300">
                                        <span className="text-gray-400 mr-2">Current Structure:</span>
                                        {selfEmploymentData.llcAnalysis.currentStructure}
                                    </div>

                                    {/* Calculation */}
                                    {selfEmploymentData.llcAnalysis.llcCalculation && (
                                        <div className="bg-yellow-900/20 p-3 rounded border border-yellow-500/30">
                                            <p className="text-sm text-yellow-300">
                                                <Calculator className="w-4 h-4 inline mr-2"/>
                                                {selfEmploymentData.llcAnalysis.llcCalculation}
                                            </p>
                                        </div>
                                    )}

                                    {/* Potential Savings */}
                                    <div className="bg-green-900/20 p-3 rounded border border-green-500/30">
                                        <div className="flex justify-between items-center">
                                            <span className="text-green-400">Potential Tax Savings:</span>
                                            <span className="text-2xl font-bold text-green-400">
                                                {formatCurrency(selfEmploymentData.llcAnalysis.potentialSavings)}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Implementation Steps */}
                                    {selfEmploymentData.llcAnalysis.steps && (
                                        <div className="bg-blue-900/20 p-3 rounded border border-blue-500/30">
                                            <h4 className="text-sm font-medium text-blue-400 mb-2">Implementation
                                                Steps:</h4>
                                            <div className="flex items-start">
                                                <ArrowRight className="w-4 h-4 text-blue-400 mr-2 mt-0.5"/>
                                                <p className="text-sm text-gray-300">{selfEmploymentData.llcAnalysis.steps}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </motion.div>
                        )}

                        {/* Business Deductions Section */}
                        {selfEmploymentData.deductions && (
                            <motion.div
                                initial={{x: -20, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                transition={{delay: 0.2}}
                                className="bg-gray-900/50 p-4 rounded-lg border border-indigo-500/30"
                            >
                                <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
                                    <FileSpreadsheet className="w-5 h-5 mr-2 text-indigo-400"/>
                                    Business Deductions Review
                                </h3>

                                {selfEmploymentData.deductions.calculations && (
                                    <div className="bg-yellow-900/20 p-3 rounded border border-yellow-500/30 mb-4">
                                        <p className="text-sm text-yellow-300">
                                            <Calculator className="w-4 h-4 inline mr-2"/>
                                            {selfEmploymentData.deductions.calculations}
                                        </p>
                                    </div>
                                )}

                                {selfEmploymentData.deductions.savings && (
                                    <div className="bg-green-900/20 p-3 rounded border border-green-500/30">
                                        <p className="text-sm text-green-400">
                                            <DollarSign className="w-4 h-4 inline mr-2"/>
                                            {selfEmploymentData.deductions.savings}
                                        </p>
                                    </div>
                                )}
                            </motion.div>
                        )}

                        {/* Additional Recommendations */}
                        {selfEmploymentData.recommendations.length > 0 && (
                            <motion.div
                                initial={{x: -20, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                transition={{delay: 0.4}}
                                className="space-y-4"
                            >
                                {selfEmploymentData.recommendations.map((rec, index) => (
                                    <div
                                        key={index}
                                        className="bg-gray-900/50 p-4 rounded-lg border border-indigo-500/30"
                                    >
                                        <h4 className="text-md font-medium text-white mb-2">{rec.title}</h4>
                                        <p className="text-gray-300 text-sm">{rec.description}</p>
                                    </div>
                                ))}
                            </motion.div>
                        )}
                    </div>
                )}
            </Card>
        </motion.div>
    );
};