// src/components/TaxAnalysis/sections/ItemizedAnalysis.jsx
import React from 'react';
import {Card} from '../../../components/ui/card';
import {motion} from 'framer-motion';
import {
    ListChecks,
    Calculator,
    TrendingUp,
    ArrowRight,
    CheckCircle,
    DollarSign,
    HelpCircle
} from 'lucide-react';

export const ItemizedAnalysis = ({data}) => {
    const formatCurrency = (amount) => {
        if (!amount) return '$0.00';
        const cleanAmount = typeof amount === 'string' ?
            amount.replace(/[^0-9.]/g, '') :
            amount.toString();
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(parseFloat(cleanAmount || 0));
    };

    const parseItemizedData = (content) => {
        if (!content) return null;
        const contentStr = content.toString();

        // Extract standard deduction
        const standardDeduction = contentStr.match(/standard deduction[^:]*:\s*\$?([\d,]+)/i)?.[1] || '0';

        // Extract itemized deductions
        const itemizedDeductions = {
            stateTaxes: contentStr.match(/State\/Local taxes[^:]*:\s*\$?([\d,]+)/i)?.[1] || '0',
            mortgageInterest: contentStr.match(/Mortgage interest[^:]*:\s*\$?([\d,]+)/i)?.[1] || '0',
            charitable: contentStr.match(/Charitable contributions[^:]*:\s*\$?([\d,]+)/i)?.[1] || '0',
            other: contentStr.match(/Other eligible items[^:]*:\s*\$?([\d,]+)/i)?.[1] || '0'
        };

        // Extract calculation details
        const calculation = contentStr.match(/Calculation:\s*([^.]+[.]?)/i)?.[1] || '';

        // Extract recommendation
        const recommendation = contentStr.match(/Recommendation:\s*([^.]+[.]?)/i)?.[1] || '';

        // Extract scenario example if exists
        const scenarioExample = contentStr.match(/example scenario[^:]*:\s*([^.]+[.]?)/i)?.[1] || '';

        // Calculate totals
        const totalItemized = Object.values(itemizedDeductions).reduce((acc, val) =>
            acc + parseFloat(val.replace(/,/g, '') || 0), 0);

        return {
            standardDeduction,
            itemizedDeductions,
            totalItemized,
            calculation,
            recommendation,
            scenarioExample,
            betterOption: parseFloat(standardDeduction.replace(/,/g, '')) > totalItemized ? 'standard' : 'itemized'
        };
    };

    const itemizedData = parseItemizedData(data);

    if (!itemizedData) return null;

    return (
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
        >
            <Card className="p-6 bg-gradient-to-r from-blue-900/30 to-indigo-900/30">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold text-white flex items-center">
                        <ListChecks className="w-8 h-8 mr-2 text-blue-400"/>
                        Itemized vs Standard Deduction Analysis
                    </h2>
                    <Calculator className="w-6 h-6 text-blue-400"/>
                </div>

                <div className="space-y-6">
                    {/* Comparison Section */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Standard Deduction */}
                        <motion.div
                            initial={{x: -20, opacity: 0}}
                            animate={{x: 0, opacity: 1}}
                            className={`bg-gray-900/50 p-4 rounded-lg border ${
                                itemizedData.betterOption === 'standard' ? 'border-green-500/50' : 'border-gray-700'
                            }`}
                        >
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold text-white">Standard Deduction</h3>
                                {itemizedData.betterOption === 'standard' && (
                                    <CheckCircle className="w-5 h-5 text-green-400"/>
                                )}
                            </div>
                            <p className="text-2xl font-bold text-blue-400">
                                {formatCurrency(itemizedData.standardDeduction)}
                            </p>
                        </motion.div>

                        {/* Itemized Deductions */}
                        <motion.div
                            initial={{x: 20, opacity: 0}}
                            animate={{x: 0, opacity: 1}}
                            className={`bg-gray-900/50 p-4 rounded-lg border ${
                                itemizedData.betterOption === 'itemized' ? 'border-green-500/50' : 'border-gray-700'
                            }`}
                        >
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold text-white">Total Itemized</h3>
                                {itemizedData.betterOption === 'itemized' && (
                                    <CheckCircle className="w-5 h-5 text-green-400"/>
                                )}
                            </div>
                            <p className="text-2xl font-bold text-blue-400">
                                {formatCurrency(itemizedData.totalItemized)}
                            </p>
                        </motion.div>
                    </div>

                    {/* Itemized Breakdown */}
                    <motion.div
                        initial={{y: 20, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        transition={{delay: 0.2}}
                        className="bg-gray-900/50 p-4 rounded-lg border border-gray-700"
                    >
                        <h3 className="text-lg font-semibold text-white mb-4">Itemized Deductions Breakdown</h3>
                        <div className="space-y-3">
                            {Object.entries(itemizedData.itemizedDeductions).map(([key, value], index) => (
                                <div key={key} className="flex justify-between items-center">
                                    <span className="text-gray-400">{key.replace(/([A-Z])/g, ' $1').trim()}</span>
                                    <span className="text-white">{formatCurrency(value)}</span>
                                </div>
                            ))}
                            <div className="border-t border-gray-700 pt-3 flex justify-between items-center">
                                <span className="text-gray-400 font-semibold">Total</span>
                                <span
                                    className="text-blue-400 font-bold">{formatCurrency(itemizedData.totalItemized)}</span>
                            </div>
                        </div>
                    </motion.div>

                    {/* Calculation Section */}
                    {itemizedData.calculation && (
                        <motion.div
                            initial={{y: 20, opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            transition={{delay: 0.3}}
                            className="bg-yellow-900/20 p-4 rounded-lg border border-yellow-500/30"
                        >
                            <h3 className="text-sm font-medium text-yellow-400 mb-2 flex items-center">
                                <Calculator className="w-4 h-4 mr-2"/>
                                Calculation Details
                            </h3>
                            <p className="text-yellow-300">{itemizedData.calculation}</p>
                        </motion.div>
                    )}

                    {/* Recommendation Section */}
                    {itemizedData.recommendation && (
                        <motion.div
                            initial={{y: 20, opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            transition={{delay: 0.4}}
                            className="bg-green-900/20 p-4 rounded-lg border border-green-500/30"
                        >
                            <h3 className="text-sm font-medium text-green-400 mb-2 flex items-center">
                                <TrendingUp className="w-4 h-4 mr-2"/>
                                Recommendation
                            </h3>
                            <p className="text-green-300">{itemizedData.recommendation}</p>
                        </motion.div>
                    )}

                    {/* Example Scenario */}
                    {itemizedData.scenarioExample && (
                        <motion.div
                            initial={{y: 20, opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            transition={{delay: 0.5}}
                            className="bg-blue-900/20 p-4 rounded-lg border border-blue-500/30"
                        >
                            <h3 className="text-sm font-medium text-blue-400 mb-2 flex items-center">
                                <HelpCircle className="w-4 h-4 mr-2"/>
                                Example Scenario
                            </h3>
                            <p className="text-blue-300">{itemizedData.scenarioExample}</p>
                        </motion.div>
                    )}
                </div>
            </Card>
        </motion.div>
    );
};