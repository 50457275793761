import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import {
    Briefcase,
    Building,
    CircleDollarSign,
    DollarSign,
    HelpCircle,
    Landmark,
    Minus,
    PiggyBank,
    Plus,
    TrendingUp
} from 'lucide-react';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '../../../../components/ui/tooltip';
import {Card} from '../../../../components/ui/card';
import Layout from '../shared/Layout';
import '../../../../styles/TaxCalculator/steps/Income.css';

const Income = ({formData, setFormData, error}) => {
    const [isFullWidth, setIsFullWidth] = useState(false);
    const toggleWidth = () => {
        setIsFullWidth(!isFullWidth);
    };
    const navigate = useNavigate();
    const {stateCode} = useParams();
    const [w2Forms, setW2Forms] = useState([{}]);

    useEffect(() => {
        if (!formData.state || !formData.year || !formData.filing_status) {
            navigate('/tax-calculator', {replace: true});
        }
    }, [formData.state, formData.year, formData.filing_status, navigate]);

    const handleIncomeChange = (field, value) => {
        const numValue = parseFloat(value) || 0;
        setFormData(prev => ({
            ...prev,
            income: {
                ...prev.income,
                [field]: numValue
            }
        }));
    };

    const addW2Form = () => {
        setW2Forms([...w2Forms, {}]);
    };

    const removeW2Form = (index) => {
        if (w2Forms.length > 1) {
            const newForms = w2Forms.filter((_, i) => i !== index);
            setW2Forms(newForms);
            updateTotalW2Income(newForms);
        }
    };

    const updateW2Form = (index, field, value) => {
        const numValue = parseFloat(value) || 0;
        const newForms = w2Forms.map((form, i) => {
            if (i === index) {
                return {...form, [field]: numValue};
            }
            return form;
        });
        setW2Forms(newForms);
        updateTotalW2Income(newForms);
    };

    const updateTotalW2Income = (forms) => {
        const totalWages = forms.reduce((sum, form) => sum + (form.box1 || 0), 0);
        const totalFederalWithholding = forms.reduce((sum, form) => sum + (form.box2 || 0), 0);
        const totalStateWithholding = forms.reduce((sum, form) => sum + (form.box17 || 0), 0);

        setFormData(prev => ({
            ...prev,
            income: {
                ...prev.income,
                wages: totalWages,
                w2_info: forms.map(form => ({
                    box1: form.box1 || 0,
                    box2: form.box2 || 0,
                    box17: form.box17 || 0
                }))
            },
            withholdings: {
                federal: totalFederalWithholding,
                state: totalStateWithholding
            }
        }));
    };

    const totalIncome = Object.values(formData.income)
        .filter(value => typeof value === 'number')
        .reduce((sum, val) => sum + (val || 0), 0);

    const incomeTypes = [

        {

            field: 'unemployment',

            label: '1099-G Unemployment Compensation',

            tooltip: 'Income received from unemployment benefits',

            icon: Building

        },

        {

            field: 'combat_pay',

            label: 'Combat Pay',

            tooltip: 'Military combat zone compensation',

            icon: Landmark

        },

        {

            field: 'social_security',

            label: 'SSA-1099 Social Security Benefits',

            tooltip: 'Social security benefits received',

            icon: CircleDollarSign

        },

        {

            field: 'self_employment',

            label: 'Total 1099-NEC Income',

            tooltip: 'Income from business ownership, freelancing, or contract work',

            icon: Briefcase

        },

        {

            field: 'investments',

            label: '1099-B 1099-DIV 1099-INT Income',

            tooltip: 'Dividends, capital gains, and interest income',

            icon: TrendingUp

        },

        {

            field: 'retirement',

            label: '1099-R Retirement Income',

            tooltip: 'Income from pensions, IRA distributions',

            icon: PiggyBank

        },

        {

            field: 'other',

            label: 'Other Income',

            tooltip: 'Rental income, royalties, and other miscellaneous income',

            icon: DollarSign

        }

    ];

    const handleNext = () => {
        navigate(`/tax-calculator/${stateCode}/deductions`, {replace: true});
    };

    const handleBack = () => {
        navigate(`/tax-calculator/${stateCode}/basic-info`, {replace: true});
    };

    return (
        <Layout formData={formData} setFormData={setFormData}>
            <div className={`income-container ${isFullWidth ? 'full-width' : ''}`}
                 onClick={toggleWidth}
            >
                {/* Header */}
                <div className="income-header">
                    <div className="header-content">
                        <h3>Income Information</h3>
                        <p>Enter your income from all sources</p>
                    </div>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger className="help-trigger">
                                <HelpCircle size={20}/>
                            </TooltipTrigger>
                            <TooltipContent side="left" sideOffset={5}>
                                <p>Include all sources of income for accurate tax calculation</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>

                {/* W-2 Forms Section */}
                <Card className="w2-section">
                    <div className="w2-header">
                        <h4>W-2 Information</h4>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger className="help-trigger">
                                    <HelpCircle size={16}/>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Enter information from your W-2 forms</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>

                    <div className="w2-forms-container">
                        {w2Forms.map((form, index) => (
                            <div key={index} className="w2-form">
                                <div className="w2-form-header">
                                    <h5>W-2 Form #{index + 1}</h5>
                                    {index > 0 && (
                                        <button
                                            onClick={() => removeW2Form(index)}
                                            className="remove-form-button"
                                        >
                                            <Minus size={16}/>
                                            <span>Remove</span>
                                        </button>
                                    )}
                                </div>

                                <div className="w2-form-grid">
                                    <div className="w2-input-group">
                                        <label>Box 1: Wages, tips, other compensation</label>
                                        <div className="input-with-icon">
                                            <DollarSign size={16}/>
                                            <input
                                                type="number"
                                                placeholder="0.00"
                                                value={form.box1 || ''}
                                                onChange={(e) => updateW2Form(index, 'box1', e.target.value)}
                                                min="0"
                                                step="0.01"
                                            />
                                        </div>
                                    </div>

                                    <div className="w2-input-group">
                                        <label>Box 2: Federal income tax withheld</label>
                                        <div className="input-with-icon">
                                            <DollarSign size={16}/>
                                            <input
                                                type="number"
                                                placeholder="0.00"
                                                value={form.box2 || ''}
                                                onChange={(e) => updateW2Form(index, 'box2', e.target.value)}
                                                min="0"
                                                step="0.01"
                                            />
                                        </div>
                                    </div>

                                    <div className="w2-input-group">
                                        <label>Box 17: State income tax withheld</label>
                                        <div className="input-with-icon">
                                            <DollarSign size={16}/>
                                            <input
                                                type="number"
                                                placeholder="0.00"
                                                value={form.box17 || ''}
                                                onChange={(e) => updateW2Form(index, 'box17', e.target.value)}
                                                min="0"
                                                step="0.01"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <button onClick={addW2Form} className="add-w2-button">
                            <Plus size={16}/>
                            <span>Add Another W-2</span>
                        </button>
                    </div>
                </Card>

                {/* Additional Income Types */}
                <div className="income-types-grid">
                    {incomeTypes.map(({field, label, tooltip, icon: Icon}) => (
                        <TooltipProvider key={field}>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div className="income-type-item">
                                        <label>
                                            <Icon size={16}/>
                                            <span>{label}</span>
                                        </label>
                                        <div className="input-with-icon">
                                            <DollarSign size={16}/>
                                            <input
                                                type="number"
                                                placeholder="0.00"
                                                value={formData.income[field] || ''}
                                                onChange={(e) => handleIncomeChange(field, e.target.value)}
                                                min="0"
                                                step="0.01"
                                            />
                                        </div>
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent sideOffset={5}>
                                    <p>{tooltip}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    ))}
                </div>

                {/* Total Income Summary */}
                <Card className="total-income-card">
                    <div className="total-income-content">
                        <div className="total-income-text">
                            <h4>Total Annual Income</h4>
                            <p>Sum of all income sources</p>
                        </div>
                        <div className="total-income-amount">
                            <p>${totalIncome.toLocaleString('en-US', {minimumFractionDigits: 2})}</p>
                            <span>Before taxes and deductions</span>
                        </div>
                    </div>
                </Card>

                {error && (
                    <div className="error-message">
                        {error}
                    </div>
                )}

                {/* Navigation */}
                <motion.div
                    className="nav-buttons"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0.3}}
                >
                    <motion.button
                        onClick={handleBack}
                        className="nav-button nav-button-back"
                        whileHover={{scale: 1.05}}
                        whileTap={{scale: 0.95}}
                    >
                        Back to Income
                    </motion.button>
                    <motion.button
                        onClick={handleNext}
                        disabled={!formData.filing_status}
                        className="nav-button nav-button-next"
                        whileHover={{scale: 1.05}}
                        whileTap={{scale: 0.95}}
                    >
                        Continue to Credits
                    </motion.button>
                </motion.div>
            </div>
        </Layout>
    );
};

export default Income;
