import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import IndustrySelector from './components/IndustrySelector';
import RiskLevel from './components/RiskLevel';
import StockResults from './components/StockResults';
import {Button} from '../../components/ui/button';
import {AlertTriangle, ChevronLeft} from 'lucide-react';
import '../../styles/StockAI/StockAI.css';

const DisclaimerModal = ({onAccept}) => (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
        <div className="max-w-lg w-full bg-gray-900 border border-gray-800 rounded-lg shadow-xl p-4">
            <div className="flex items-center gap-2 mb-3">
                <AlertTriangle className="text-yellow-500 h-5 w-5"/>
                <h2 className="text-lg font-bold text-white">Important Disclaimer</h2>
            </div>

            <div className="text-gray-300 space-y-2 text-sm">
                <p>
                    The stock analysis and recommendations provided by TaxMe AI are for informational purposes only
                    and should not be considered as financial advice.
                </p>
                <p>
                    Investment in stocks involves substantial risk. Past performance does not guarantee future results.
                    Please conduct your own research and consult with qualified financial advisors before making any
                    investment decisions.
                </p>
                <div className="pt-3 border-t border-gray-700 text-xs">
                    <p>
                        By clicking "Accept", you agree to our{' '}
                        <a
                            href="/legal/terms"
                            className="text-blue-400 hover:text-blue-300 underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms and Conditions
                        </a>
                        {' '}and{' '}
                        <a
                            href="/legal/privacy"
                            className="text-blue-400 hover:text-blue-300 underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>
                </div>
            </div>

            <div className="flex justify-end gap-3 mt-4">
                <Button
                    variant="outline"
                    onClick={() => window.location.href = '/dashboard'}
                    className="border-gray-700 text-sm px-3 py-1"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        localStorage.setItem('stockDisclaimerAccepted', 'true');
                        onAccept();
                    }}
                    className="bg-blue-600 hover:bg-blue-700 text-sm px-3 py-1"
                >
                    Accept & Continue
                </Button>
            </div>
        </div>
    </div>
);

const StockAI = () => {
    const [step, setStep] = useState(1);
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [selectedRiskLevel, setSelectedRiskLevel] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Check if returning from payment
        if (location.state?.fromPayment) {
            const storedData = sessionStorage.getItem('stockAnalysisReturn');
            if (storedData) {
                try {
                    const parsedData = JSON.parse(storedData);
                    setSelectedIndustry(parsedData.industry);
                    setSelectedRiskLevel(parsedData.riskLevel);
                    setStep(3); // Set to results step
                    navigate('/stock-ai/results', {
                        state: {
                            fromPayment: true,
                            ...parsedData
                        },
                        replace: true
                    });
                } catch (error) {
                    console.error('Error parsing stored data:', error);
                }
            }
        }
    }, [location.state, navigate]);


    useEffect(() => {
        const hasAccepted = localStorage.getItem('stockDisclaimerAccepted');
        setShowDisclaimer(!hasAccepted);
    }, []);

    const handleIndustrySelect = (industry) => {
        setSelectedIndustry(industry);
        setStep(2);
        navigate('/stock-ai/risk-level');
    };

    // Also update your StockAI component's routing logic
    const handleRiskLevelSelect = (riskLevel) => {
        setSelectedRiskLevel(riskLevel);
        setStep(3);
        navigate('/stock-ai/results', {
            state: {
                industry: selectedIndustry,
                riskLevel: riskLevel
            }
        });
    };

    const handleBack = () => {
        if (step === 1) {
            navigate('/dashboard');
        } else {
            setStep(step - 1);
            if (step === 3) {
                navigate('/stock-ai/risk-level');
            } else if (step === 2) {
                navigate('/stock-ai');
            }
        }
    };

    // Hide back button on results page
    const showBackButton = !location.pathname.includes('/stock-ai/results');

    return (
        <div className="stock-ai-container min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
            {showDisclaimer && (
                <DisclaimerModal onAccept={() => setShowDisclaimer(false)}/>
            )}

            <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 ${showDisclaimer ? 'blur-sm' : ''}`}>
                {/* Progress Bar */}
                {!location.pathname.includes('/stock-ai/results') && (
                    <div className="w-full max-w-xl mx-auto mb-8">
                        <div className="relative pt-1">
                            <div className="flex mb-2 items-center justify-between">
                                <div className="flex-1">
                                    <div className="relative">
                                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-700">
                                            <div
                                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500"
                                                style={{width: `${(step / 3) * 100}%`}}
                                            ></div>
                                        </div>
                                        <div className="flex justify-between text-xs text-gray-400">
                                            <span className={step >= 1 ? 'text-blue-400' : ''}>Industry</span>
                                            <span className={step >= 2 ? 'text-blue-400' : ''}>Risk Level</span>
                                            <span className={step >= 3 ? 'text-blue-400' : ''}>Results</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Content */}
                <div className="content-container">
                    {step === 1 && <IndustrySelector onSelect={handleIndustrySelect}/>}
                    {step === 2 && <RiskLevel onSelect={handleRiskLevelSelect} loading={loading}
                                              selectedIndustry={selectedIndustry}/>}
                    {step === 3 && (
                        <StockResults
                            industry={selectedIndustry}
                            riskLevel={selectedRiskLevel}
                            setLoading={setLoading}
                        />
                    )}
                </div>

                {/* Back Button - Only show if not on results page */}
                {showBackButton && (
                    <div className="mt-8 flex justify-center">
                        <Button
                            onClick={handleBack}
                            variant="outline"
                            className="flex items-center gap-2 text-gray-300 hover:text-white"
                        >
                            <ChevronLeft size={20}/>
                            {step === 1 ? 'Back to Dashboard' : 'Previous Step'}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StockAI;