import React from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeft} from 'lucide-react';
import '../../styles/Legal.css';

const Privacy = () => {
    return (
        <div className="legal-page">
            <Link to="/" className="back-button">
                <ArrowLeft size={20}/>
                Back to Home
            </Link>

            <div className="legal-container">
                <h1>Privacy Policy</h1>
                <div className="legal-content">
                    <section>
                        <h2>1. Introduction</h2>
                        <p>TaxMe AI ("we," "our," or "us") is committed to protecting your privacy and ensuring the
                            security of your personal and financial information. This Privacy Policy explains how we
                            collect, use, disclose, and safeguard your information when you use our AI-powered tax
                            preparation and financial advisory services, website, mobile application, and other related
                            services (collectively, the "Services").</p>
                        <p>By accessing or using our Services, you agree to this Privacy Policy. Please read this
                            Privacy Policy carefully to understand our practices regarding your information.</p>
                    </section>

                    <section>
                        <h2>2. Information Collection</h2>
                        <h3>2.1 Personal Information</h3>
                        <ul>
                            <li>Identity Information: Name, date of birth, Social Security number, government-issued
                                ID
                            </li>
                            <li>Contact Information: Email address, phone number, mailing address</li>
                            <li>Account Credentials: Username, password, security questions</li>
                            <li>Professional Information: Occupation, employer details, business information</li>
                            <li>Financial Information: Bank account details, credit card information, investment
                                accounts
                            </li>
                        </ul>

                        <h3>2.2 Tax-Related Information</h3>
                        <ul>
                            <li>Tax Returns and Related Documents: W-2s, 1099s, and other tax forms</li>
                            <li>Income Information: Salary, wages, investment income, business income</li>
                            <li>Deduction Information: Charitable contributions, medical expenses, mortgage interest
                            </li>
                            <li>Tax Credits: Child tax credits, education credits, energy credits</li>
                            <li>Filing Status: Individual, joint, head of household status</li>
                        </ul>

                        <h3>2.3 Technical Information</h3>
                        <ul>
                            <li>Device Information: IP address, browser type, operating system</li>
                            <li>Usage Data: Access times, pages viewed, features used</li>
                            <li>Location Data: GPS, IP-based location (with consent)</li>
                            <li>Communication Data: Customer service interactions, chat logs</li>
                        </ul>
                    </section>

                    <section>
                        <h2>3. Use of Information</h2>
                        <h3>3.1 Primary Purposes</h3>
                        <ul>
                            <li>Providing tax preparation and calculation services</li>
                            <li>Generating personalized financial insights and recommendations</li>
                            <li>Processing transactions and payments</li>
                            <li>Maintaining and servicing your account</li>
                            <li>Verifying your identity and preventing fraud</li>
                        </ul>

                        <h3>3.2 Additional Uses</h3>
                        <ul>
                            <li>Improving and optimizing our AI algorithms and services</li>
                            <li>Conducting research and analytics</li>
                            <li>Providing customer support and responding to inquiries</li>
                            <li>Sending service updates and marketing communications (with consent)</li>
                            <li>Complying with legal obligations and regulatory requirements</li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Data Protection and Security</h2>
                        <h3>4.1 Security Measures</h3>
                        <ul>
                            <li>256-bit SSL/TLS encryption for data transmission</li>
                            <li>Multi-factor authentication for account access</li>
                            <li>Regular security assessments and penetration testing</li>
                            <li>Encrypted data storage with AES-256 encryption</li>
                            <li>24/7 security monitoring and threat detection</li>
                        </ul>

                        <h3>4.2 Data Retention</h3>
                        <ul>
                            <li>Tax records retained for 7 years per IRS requirements</li>
                            <li>Account information maintained while account is active</li>
                            <li>Backup data retained for disaster recovery purposes</li>
                            <li>Data deleted or anonymized when no longer needed</li>
                        </ul>
                    </section>

                    <section>
                        <h2>5. Information Sharing and Disclosure</h2>
                        <h3>5.1 Third-Party Service Providers</h3>
                        <ul>
                            <li>Cloud hosting and storage providers</li>
                            <li>Payment processors and financial institutions</li>
                            <li>Identity verification services</li>
                            <li>Analytics and monitoring services</li>
                            <li>Customer support tools and platforms</li>
                        </ul>

                        <h3>5.2 Legal Requirements</h3>
                        <ul>
                            <li>Compliance with court orders and legal process</li>
                            <li>Response to law enforcement requests</li>
                            <li>Protection against fraud and illegal activities</li>
                            <li>Defense of legal rights and claims</li>
                        </ul>
                    </section>

                    <section>
                        <h2>6. Your Privacy Rights</h2>
                        <h3>6.1 General Rights</h3>
                        <ul>
                            <li>Right to access your personal information</li>
                            <li>Right to correct inaccurate information</li>
                            <li>Right to delete your personal information</li>
                            <li>Right to data portability</li>
                            <li>Right to opt-out of marketing communications</li>
                        </ul>

                        <h3>6.2 California Privacy Rights</h3>
                        <p>Under the California Consumer Privacy Act (CCPA) and California Privacy Rights Act (CPRA),
                            California residents have additional rights:</p>

                        <h4>Right to Know</h4>
                        <ul>
                            <li>Categories of personal information collected</li>
                            <li>Sources of personal information</li>
                            <li>Purpose for collecting information</li>
                            <li>Categories of third parties with whom we share information</li>
                            <li>Specific pieces of personal information collected</li>
                        </ul>

                        <h4>Right to Delete</h4>
                        <ul>
                            <li>Request deletion of personal information</li>
                            <li>Exceptions for regulatory requirements and essential operations</li>
                            <li>Verification process for deletion requests</li>
                        </ul>

                        <h4>Right to Correct</h4>
                        <ul>
                            <li>Request correction of inaccurate personal information</li>
                            <li>Documentation requirements for correction requests</li>
                        </ul>

                        <h4>Right to Opt-Out</h4>
                        <ul>
                            <li>Opt-out of personal information sales</li>
                            <li>Opt-out of automated decision-making</li>
                            <li>Opt-out of cross-context behavioral advertising</li>
                        </ul>

                        <h4>Right to Limit Use of Sensitive Personal Information</h4>
                        <ul>
                            <li>Control use of sensitive data including:</li>
                            <li>Social security numbers</li>
                            <li>Financial account information</li>
                            <li>Precise geolocation</li>
                            <li>Biometric information</li>
                        </ul>

                        <p>To exercise your California privacy rights, contact us at support@taxmeai.com or call
                            1-336-365-9994.</p>
                    </section>

                    <section>
                        <h2>7. Children's Privacy</h2>
                        <p>Our Services are not intended for individuals under 18 years of age. We do not knowingly
                            collect or maintain personal information from children. If we become aware that we have
                            inadvertently collected personal information from a child under 18, we will take steps to
                            delete such information.</p>
                    </section>

                    <section>
                        <h2>8. International Data Transfers</h2>
                        <p>We process and store information in the United States. If you are located outside the United
                            States, your information may be transferred to, stored, and processed in the United States
                            or other countries where our service providers maintain facilities. We ensure appropriate
                            safeguards are in place for international data transfers.</p>
                    </section>

                    <section>
                        <h2>9. Changes to Privacy Policy</h2>
                        <p>We may update this Privacy Policy periodically to reflect changes in our practices or legal
                            requirements. We will notify you of any material changes through:</p>
                        <ul>
                            <li>Email notification to your registered email address</li>
                            <li>Prominent notice on our website and mobile application</li>
                            <li>Update to the "Last Updated" date in this Policy</li>
                        </ul>
                    </section>

                    <section>
                        <h2>10. Contact Information</h2>
                        <p>For questions, concerns, or to exercise your privacy rights:</p>
                        <ul>
                            <li>Email: support@taxmeai.com</li>
                            <li>Phone: 1-336-365-9994</li>
                            <li>Mail: TaxMe AI, Privacy Department</li>
                            <li>Address: Winston-Salem, NC 27101</li>
                        </ul>
                    </section>
                </div>

                <div className="legal-footer">
                    <p>Last updated: January 2, 2025</p>
                    <p>
                        Review our{' '}
                        <Link to="/terms" className="legal-link">Terms and Conditions</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;