import React from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeft} from 'lucide-react';
import '../../styles/Legal.css';

const Terms = () => {
    return (
        <div className="legal-page">
            <Link to="/" className="back-button">
                <ArrowLeft size={20}/>
                Back to Home
            </Link>

            <div className="legal-container">
                <h1>Terms and Conditions</h1>
                <div className="legal-content">
                    <section>
                        <h2>1. Agreement to Terms</h2>
                        <p>By accessing or using TaxMe AI's services ("Services"), you agree to be bound by these Terms
                            and Conditions ("Terms"). If you disagree with any part of these Terms, you may not access
                            or use our Services.</p>

                        <h3>1.1 Binding Arbitration Agreement and Class Action Waiver</h3>
                        <p>PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO
                            FILE A LAWSUIT IN COURT.</p>
                        <ul>
                            <li>You agree that any dispute, claim, or controversy arising out of or relating to these
                                Terms or our Services shall be resolved exclusively through binding arbitration.
                            </li>
                            <li>You waive any right to participate in a class action lawsuit or class-wide
                                arbitration.
                            </li>
                            <li>The arbitration will be conducted by JAMS under its applicable rules.</li>
                            <li>The arbitration shall take place in Winston-Salem, North Carolina.</li>
                        </ul>
                    </section>

                    <section>
                        <h2>2. Services Overview</h2>
                        <h3>2.1 Service Description</h3>
                        <p>TaxMe AI provides AI-powered financial services including:</p>
                        <ul>
                            <li>Tax calculation and planning assistance</li>
                            <li>Investment insights and analysis</li>
                            <li>Financial planning tools</li>
                            <li>Market research and recommendations</li>
                        </ul>

                        <h3>2.2 Service Limitations</h3>
                        <p>Users acknowledge that:</p>
                        <ul>
                            <li>Our AI models provide estimates and suggestions only</li>
                            <li>Results may vary based on data accuracy and market conditions</li>
                            <li>Services may be subject to technical limitations or interruptions</li>
                            <li>We may modify or discontinue features without prior notice</li>
                        </ul>
                    </section>

                    <section>
                        <h2>3. User Accounts and Eligibility</h2>
                        <h3>3.1 Account Requirements</h3>
                        <ul>
                            <li>Must be at least 18 years old</li>
                            <li>Must provide accurate and complete information</li>
                            <li>Must maintain current contact and financial information</li>
                            <li>Must comply with all applicable laws and regulations</li>
                        </ul>

                        <h3>3.2 Account Security</h3>
                        <ul>
                            <li>Users are responsible for maintaining password confidentiality</li>
                            <li>Users must enable two-factor authentication</li>
                            <li>Immediate notification required for unauthorized access</li>
                            <li>We may suspend accounts for security concerns</li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Financial Disclaimers and Risk Disclosures</h2>
                        <h3>4.1 Not Professional Advice</h3>
                        <p>IMPORTANT: Our Services do not constitute professional financial, investment, or tax advice.
                            Users explicitly acknowledge:</p>
                        <ul>
                            <li>All information is for informational purposes only</li>
                            <li>Services are not a substitute for professional advisors</li>
                            <li>Users should verify all information independently</li>
                            <li>Past performance does not guarantee future results</li>
                        </ul>

                        <h3>4.2 Investment Risks</h3>
                        <ul>
                            <li>All investments carry inherent risks</li>
                            <li>Users may lose some or all of their investment</li>
                            <li>Market conditions can change rapidly</li>
                            <li>AI predictions are not guaranteed</li>
                        </ul>
                    </section>

                    <section>
                        <h2>5. Limitation of Liability and Indemnification</h2>
                        <h3>5.1 Limitation of Liability</h3>
                        <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW:</p>
                        <ul>
                            <li>We are not liable for any direct, indirect, incidental, consequential, special,
                                punitive, or exemplary damages
                            </li>
                            <li>Our total liability shall not exceed the amount paid by you for the Services in the past
                                12 months
                            </li>
                            <li>We are not liable for any losses resulting from unauthorized access to your account</li>
                            <li>We are not liable for any tax penalties or assessments</li>
                        </ul>

                        <h3>5.2 Indemnification</h3>
                        <p>You agree to indemnify and hold harmless TaxMe AI and its officers, directors, employees, and
                            agents from:</p>
                        <ul>
                            <li>Any claims arising from your use of the Services</li>
                            <li>Violations of these Terms or applicable laws</li>
                            <li>Unauthorized use of your account</li>
                            <li>Any tax-related claims or penalties</li>
                        </ul>
                    </section>

                    <section>
                        <h2>6. Data Usage and Privacy</h2>
                        <h3>6.1 Data Collection and Use</h3>
                        <ul>
                            <li>We collect and process data as described in our Privacy Policy</li>
                            <li>You grant us rights to use your data to improve our Services</li>
                            <li>We maintain industry-standard security measures</li>
                            <li>We may share data as required by law or regulation</li>
                        </ul>

                        <h3>6.2 Third-Party Services</h3>
                        <ul>
                            <li>We may integrate with third-party services</li>
                            <li>Third-party terms and privacy policies may apply</li>
                            <li>We are not responsible for third-party services</li>
                            <li>You use third-party services at your own risk</li>
                        </ul>
                    </section>

                    <section>
                        <h2>7. Intellectual Property Rights</h2>
                        <h3>7.1 Ownership</h3>
                        <ul>
                            <li>All Service content is our exclusive property</li>
                            <li>AI algorithms and models are proprietary</li>
                            <li>User feedback becomes our property</li>
                            <li>No rights are granted except as explicitly stated</li>
                        </ul>

                        <h3>7.2 Restrictions</h3>
                        <ul>
                            <li>No reverse engineering of our Services</li>
                            <li>No unauthorized access attempts</li>
                            <li>No copying or distributing our content</li>
                            <li>No competing service development</li>
                        </ul>
                    </section>

                    <section>
                        <h2>8. Dispute Resolution and Governing Law</h2>
                        <h3>8.1 Governing Law</h3>
                        <p>These Terms are governed by the laws of North Carolina, without regard to conflict of law
                            principles.</p>

                        <h3>8.2 Dispute Resolution Process</h3>
                        <ul>
                            <li>Informal resolution attempt required first</li>
                            <li>Mandatory binding arbitration for disputes</li>
                            <li>Class action waiver applies</li>
                            <li>Small claims court option preserved</li>
                        </ul>

                        <h3>8.3 Arbitration Procedures</h3>
                        <ul>
                            <li>Arbitration conducted by JAMS</li>
                            <li>Arbitration location in Winston-Salem, NC</li>
                            <li>Each party bears its own costs</li>
                            <li>Decision is final and binding</li>
                        </ul>
                    </section>

                    <section>
                        <h2>9. Service Modifications and Termination</h2>
                        <h3>9.1 Service Changes</h3>
                        <ul>
                            <li>We may modify or discontinue Services at any time</li>
                            <li>Features may change without notice</li>
                            <li>Pricing may be adjusted with notice</li>
                            <li>Access may be restricted for violations</li>
                        </ul>

                        <h3>9.2 Account Termination</h3>
                        <ul>
                            <li>We may terminate accounts for violations</li>
                            <li>Users may terminate at any time</li>
                            <li>Certain obligations survive termination</li>
                            <li>No refunds for termination</li>
                        </ul>
                    </section>

                    <section>
                        <h2>10. Contact Information</h2>
                        <p>For questions about these Terms:</p>
                        <ul>
                            <li>Email: support@taxmeai.com</li>
                            <li>Phone: +1-336-365-9994</li>
                            <li>Address: TaxMe AI, Legal Department</li>
                            <li>Winston-Salem, NC 27101</li>
                        </ul>
                    </section>
                </div>

                <div className="legal-footer">
                    <p>Last updated: January 2, 2025</p>
                    <p>
                        Review our{' '}
                        <Link to="/privacy" className="legal-link">Privacy Policy</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Terms;