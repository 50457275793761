// src/components/TaxAnalysis/sections/CurrentSituation.jsx
import React from 'react';
import {Card} from '../../../components/ui/card';
import {motion} from 'framer-motion';
import {
    DollarSign,
    ArrowDownRight,
    ArrowUpRight,
    Calculator,
    Wallet
} from 'lucide-react';

export const CurrentSituation = ({data}) => {
    const extractAmount = (text, pattern) => {
        if (!text) return '0';
        try {
            const match = text.toString().match(pattern);
            return match ? (match[1] || match[2] || '0') : '0';
        } catch (error) {
            console.error('Error extracting amount:', error);
            return '0';
        }
    };

    // In CurrentSituation.jsx, update the formatCurrency function:

    const formatCurrency = (amount) => {
        if (!amount) return '$0.00';
        try {
            // Handle when amount is a number
            if (typeof amount === 'number') {
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                }).format(amount);
            }

            // Handle when amount is a string
            const cleanAmount = amount.toString()
                .replace(/[^0-9.-]/g, '')  // Remove all non-numeric characters except decimal and negative
                .replace(/^0+/, '0');      // Remove leading zeros but keep single zero

            if (!cleanAmount || isNaN(cleanAmount)) return '$0.00';

            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            }).format(parseFloat(cleanAmount));
        } catch (error) {
            console.error('Error formatting currency:', error);
            return '$0.00';
        }
    };

    // Update how we extract values:
    const totalIncome = extractAmount(data?.toString() || '', /Total Income:?\s*\$\*\*([\d,]+\.\d{2})\*\*|\$([0-9,.]+)/i);
    const federalTax = extractAmount(data?.toString() || '', /Federal Tax:?\s*\$\*\*([\d,]+\.\d{2})\*\*|\$([0-9,.]+)/i);
    const stateTax = extractAmount(data?.toString() || '', /State Tax:?\s*\$\*\*([\d,]+\.\d{2})\*\*|\$([0-9,.]+)/i);
    const taxableIncome = extractAmount(data?.toString() || '', /Taxable Income:?\s*\$\*\*([\d,]+)\*\*|\$([0-9,.]+)/i);
    const federalWithholdings = extractAmount(data?.toString() || '', /Federal Withholdings:?\s*\$([0-9,.]+)/i);
    const stateWithholdings = extractAmount(data?.toString() || '', /State Withholdings:?\s*\$([0-9,.]+)/i);

    // Extract refund/due information
    const federalRefundMatch = data.toString().match(/Federal (Refund|Tax) due:?\s*\$([0-9,.]+)/i);
    const stateRefundMatch = data.toString().match(/State (Refund|Tax) due:?\s*\$([0-9,.]+)/i);

    const federalRefundInfo = federalRefundMatch ? {
        type: federalRefundMatch[1].toLowerCase(),
        amount: federalRefundMatch[2]
    } : null;

    const stateRefundInfo = stateRefundMatch ? {
        type: stateRefundMatch[1].toLowerCase(),
        amount: stateRefundMatch[2]
    } : null;

    return (
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
        >
            <Card className="p-6 bg-gradient-to-r from-blue-900/30 to-purple-900/30">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold text-white flex items-center">
                        <Calculator className="w-8 h-8 mr-2 text-blue-400"/>
                        Current Tax Situation
                    </h2>
                    <Wallet className="w-6 h-6 text-blue-400"/>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Primary Figures */}
                    <div className="space-y-4">
                        <div className="bg-blue-900/20 p-4 rounded-lg border border-blue-500/30">
                            <p className="text-gray-400 text-sm">Total Income</p>
                            <p className="text-2xl font-bold text-yellow-400">{formatCurrency(totalIncome)}</p>
                        </div>
                        <div className="bg-blue-900/20 p-4 rounded-lg border border-blue-500/30">
                            <p className="text-gray-400 text-sm">Taxable Income</p>
                            <p className="text-2xl font-bold text-green-400">{formatCurrency(taxableIncome)}</p>
                        </div>
                    </div>

                    {/* Tax Breakdown */}
                    <div className="space-y-4">
                        <div className="bg-blue-900/20 p-4 rounded-lg border border-blue-500/30">
                            <p className="text-gray-400 text-sm">Federal Tax</p>
                            <p className="text-2xl font-bold text-red-500">{formatCurrency(federalTax)}</p>
                        </div>
                        <div className="bg-blue-900/20 p-4 rounded-lg border border-blue-500/30">
                            <p className="text-gray-400 text-sm">State Tax</p>
                            <p className="text-2xl font-bold text-red-500">{formatCurrency(stateTax)}</p>
                        </div>
                    </div>
                </div>

                {/* Withholdings Section */}
                <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-gray-900/50 p-4 rounded-lg">
                        <h3 className="text-lg font-semibold text-white mb-2">Withholdings</h3>
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span className="text-gray-400">Federal:</span>
                                <span className="text-white">{formatCurrency(federalWithholdings)}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="text-gray-400">State:</span>
                                <span className="text-white">{formatCurrency(stateWithholdings)}</span>
                            </div>
                        </div>
                    </div>

                    {/* Refund/Due Summary */}
                    <div className="bg-gray-900/50 p-4 rounded-lg">
                        <h3 className="text-lg font-semibold text-white mb-2">Refund/Due Summary</h3>
                        <div className="space-y-2">
                            {federalRefundInfo && (
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-400">Federal:</span>
                                    <span
                                        className={`flex items-center ${federalRefundInfo.type === 'refund' ? 'text-green-400' : 'text-red-400'}`}>
                                        {federalRefundInfo.type === 'refund' ?
                                            <ArrowUpRight className="w-4 h-4 mr-1"/> :
                                            <ArrowDownRight className="w-4 h-4 mr-1"/>}
                                        {formatCurrency(federalRefundInfo.amount)}
                                    </span>
                                </div>
                            )}
                            {stateRefundInfo && (
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-400">State:</span>
                                    <span
                                        className={`flex items-center ${stateRefundInfo.type === 'refund' ? 'text-green-400' : 'text-red-400'}`}>
                                        {stateRefundInfo.type === 'refund' ? <ArrowUpRight className="w-4 h-4 mr-1"/> :
                                            <ArrowDownRight className="w-4 h-4 mr-1"/>}
                                        {formatCurrency(stateRefundInfo.amount)}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
        </motion.div>
    );
};
