// src/components/TaxAnalysis/sections/Recommendations.jsx
import React from 'react';
import {Card} from '../../../components/ui/card';
import {motion} from 'framer-motion';
import {
    Lightbulb,
    TrendingUp,
    ArrowRight,
    Calculator,
    CheckSquare,
    DollarSign,
    Clock,
    AlertCircle
} from 'lucide-react';

export const Recommendations = ({data}) => {
    const formatCurrency = (amount) => {
        if (!amount) return '$0.00';
        const cleanAmount = typeof amount === 'string' ?
            amount.replace(/[^0-9.]/g, '') :
            amount.toString();
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(parseFloat(cleanAmount || 0));
    };

    const parseRecommendations = (content) => {
        if (!content) return [];
        const contentStr = content.toString();

        const recommendations = [];
        const sections = contentStr.split(/(?=\s*-\s*Current situation|For each recommendation)/i);

        sections.forEach(section => {
            if (!section.trim()) return;

            // Extract components of each recommendation
            const currentSituation = section.match(/Current situation[^:]*:\s*([^.]+[.]?)/i)?.[1] || '';
            const proposedChange = section.match(/Proposed change[^:]*:\s*([^.]+[.]?)/i)?.[1] || '';
            const calculation = section.match(/Calculation[^:]*:\s*([^.]+[.]?)/i)?.[1] || '';
            const potentialSavings = section.match(/Potential savings[^:]*:\s*\$?([\d,.]+)/i)?.[1] || '0';

            // Extract implementation steps
            const stepsMatch = section.match(/Implementation steps:([\s\S]+?)(?=\n\n|\*\*|$)/i);
            const steps = stepsMatch
                ? stepsMatch[1].split(/\d+\.\s*/).filter(Boolean).map(step => step.trim())
                : [];

            // Extract timeframe if available
            const timeframe = section.match(/Timeframe[^:]*:\s*([^.]+[.]?)/i)?.[1] || '';

            if (currentSituation || proposedChange) {
                recommendations.push({
                    currentSituation,
                    proposedChange,
                    calculation,
                    potentialSavings,
                    steps,
                    timeframe
                });
            }
        });

        return recommendations;
    };

    const recommendations = parseRecommendations(data);

    return (
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
        >
            <Card className="p-6 bg-gradient-to-r from-green-900/30 to-blue-900/30">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold text-white flex items-center">
                        <Lightbulb className="w-8 h-8 mr-2 text-green-400"/>
                        Tax Planning Recommendations
                    </h2>
                    <TrendingUp className="w-6 h-6 text-green-400"/>
                </div>

                {recommendations.length > 0 ? (
                    <div className="space-y-6">
                        {recommendations.map((rec, index) => (
                            <motion.div
                                key={index}
                                initial={{x: -20, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                transition={{delay: index * 0.1}}
                                className="bg-gray-900/50 p-4 rounded-lg border border-green-500/30"
                            >
                                {/* Header with Savings */}
                                <div className="flex justify-between items-start mb-4">
                                    <h3 className="text-lg font-semibold text-white">
                                        Recommendation #{index + 1}
                                    </h3>
                                    {rec.potentialSavings && (
                                        <div className="bg-green-900/30 px-3 py-1 rounded-full">
                                            <span className="text-green-400 font-bold flex items-center">
                                                <DollarSign className="w-4 h-4 mr-1"/>
                                                {formatCurrency(rec.potentialSavings)}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                {/* Current Situation */}
                                {rec.currentSituation && (
                                    <div className="mb-3">
                                        <p className="text-gray-400 text-sm">Current Situation:</p>
                                        <p className="text-gray-300">{rec.currentSituation}</p>
                                    </div>
                                )}

                                {/* Proposed Change */}
                                {rec.proposedChange && (
                                    <div className="mb-3 bg-blue-900/20 p-3 rounded border border-blue-500/30">
                                        <p className="text-blue-400 text-sm">Proposed Change:</p>
                                        <p className="text-blue-300">{rec.proposedChange}</p>
                                    </div>
                                )}

                                {/* Calculation */}
                                {rec.calculation && (
                                    <div className="mb-3 bg-yellow-900/20 p-3 rounded border border-yellow-500/30">
                                        <p className="text-yellow-300 flex items-center">
                                            <Calculator className="w-4 h-4 mr-2"/>
                                            {rec.calculation}
                                        </p>
                                    </div>
                                )}

                                {/* Implementation Steps */}
                                {rec.steps.length > 0 && (
                                    <div className="mb-3 space-y-2">
                                        <p className="text-gray-400 text-sm">Implementation Steps:</p>
                                        {rec.steps.map((step, stepIndex) => (
                                            <div
                                                key={stepIndex}
                                                className="flex items-start"
                                            >
                                                <ArrowRight className="w-4 h-4 text-green-400 mr-2 mt-1"/>
                                                <p className="text-gray-300">{step}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Timeframe */}
                                {rec.timeframe && (
                                    <div className="flex items-center mt-3 text-gray-400">
                                        <Clock className="w-4 h-4 mr-2"/>
                                        <span className="text-sm">{rec.timeframe}</span>
                                    </div>
                                )}
                            </motion.div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center py-8">
                        <AlertCircle className="w-12 h-12 text-gray-500 mx-auto mb-3"/>
                        <p className="text-gray-400">No specific recommendations available for your current tax
                            situation.</p>
                    </div>
                )}
            </Card>
        </motion.div>
    );
};
