import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {
  BarChart,
  Calculator,
  ChevronRight,
  Mail,
  MapPin,
  MessageCircle,
  Phone,
  Shield,
  TrendingUp,
  X
} from 'lucide-react';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-hot-toast';
import {Button} from '../components/ui/button';
import {Card} from '../components/ui/card';
import VideoPlayer from '../components/VideoPlayer';
import ContactService from '../utils/services/contactService';

const GalaxyBackground = () => {
    useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';
            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;
                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);
                starsContainer.appendChild(star);
            }
            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };
        createStars();
        return () => {
            const container = document.querySelector('.stars-container');
            if (container) container.remove();
        };
    }, []);

    return (
        <>
            <div className="galaxy-background"/>
            <div className="galaxy-spiral"/>
        </>
    );
};

const HomePage = () => {
    const navigate = useNavigate();
    const [showCookies, setShowCookies] = useState(false);
    const [activeFeature, setActiveFeature] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        subject: ''
    });


    const scrollRef = useRef(0);


    // Update scroll position using ref
    useEffect(() => {
        const handleScroll = () => {
            scrollRef.current = window.pageYOffset;
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    // Floating numbers animation data
    const floatingNumbers = [
        {value: '+45%', color: 'text-green-400', delay: 0},
        {value: '$2.5M', color: 'text-blue-400', delay: 0.2},
        {value: '92%', color: 'text-purple-400', delay: 0.4},
    ];

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookies-accepted');
        if (!cookiesAccepted) setShowCookies(true);

        // Auto-rotate features
        const interval = setInterval(() => {
            setActiveFeature(prev => (prev + 1) % 3);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const handleCookiesAccept = () => {
        localStorage.setItem('cookies-accepted', 'true');
        setShowCookies(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await ContactService.sendContactForm(formData);
            toast.success('Message sent successfully!');
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            toast.error(error.message || 'Failed to send message');
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <div className="relative min-h-screen overflow-hidden">
            <GalaxyBackground/>

            {/* Redesigned Navigation */}
            <nav className="fixed w-full z-50">
                <div className="max-w-full mx-auto">
                    <div className="relative">
                        {/* Top accent line with gradient */}
                        <div
                            className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-blue-500"/>

                        {/* Main navigation content */}
                        <div className="bg-[#0a0a23]/90 backdrop-blur-md border-b border-white/10">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <div className="flex items-center justify-between h-20">


                                    {/* Logo Section */}
                                    <Link to="/" className="flex-shrink-0 flex items-center space-x-4">
                                        <div className="relative group">
                                            <div
                                                className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full blur opacity-30 group-hover:opacity-60 transition duration-500"></div>
                                            <motion.img
                                                src="/tax-logo-dark.svg"
                                                alt="TaxMe AI"
                                                className="relative h-10 w-10"
                                                animate={{
                                                    rotateY: [0, 360],
                                                }}
                                                transition={{
                                                    duration: 3,
                                                    repeat: Infinity,
                                                    ease: "linear"
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-col">
    <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
      TaxMe AI
    </span>
                                            <span className="text-xs text-gray-400">Stock Solutions</span>
                                        </div>
                                    </Link>


                                    {/* Auth Buttons with Custom Design */}
                                    <div className="flex items-right space-x-3">
                                        <motion.button
                                            whileHover={{scale: 1.02}}
                                            whileTap={{scale: 0.98}}
                                            onClick={() => navigate('/login')}
                                            className="relative px-6 py-2.5 group"
                                        >
                                            <div
                                                className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-full"></div>
                                            <div
                                                className="relative flex items-center space-x-2 text-white/90 hover:text-white">
                                                <span>Login</span>
                                                <div className="w-px h-4 bg-white/20"></div>
                                                <motion.div
                                                    animate={{x: [0, 5, 0]}}
                                                    transition={{duration: 1.5, repeat: Infinity}}
                                                >
                                                    <ChevronRight className="w-4 h-4"/>
                                                </motion.div>
                                            </div>
                                        </motion.button>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Updated Hero Section with Single Button */}
            <section className="relative min-h-[90vh] flex items-center justify-center px-4 pb-12">
                <div className="max-w-7xl mx-auto text-center z-10">
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        className="space-y-6"
                    >
                        <motion.h1
                            className="text-3xl md:text-4xl font-bold text-white mb-4 text-shadow-lg"
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.5}}
                        >
                            Smart Tax Calculations & Stock Market Recommendations<br/><br/>
                            <span
                                className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                Powered by Artificial Intelligence 
              </span>
                        </motion.h1>

                        <motion.p
                            className="text-xl text-white/90 mb-6 max-w-2xl mx-auto"
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.5, delay: 0.2}}
                        >
                            Simplify your tax preparation with our AI-driven platform.
                            Get accurate calculations and maximize your returns.
                        </motion.p>

                        <motion.div
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.5, delay: 0.4}}
                        >
                            <Button
                                onClick={() => navigate('/signup')}
                                className="inline-flex items-center bg-gradient-to-r from-blue-600 to-purple-600
               hover:from-blue-700 hover:to-purple-700 text-white px-6 py-3 rounded-full 
               text-lg font-medium transition-all duration-300 shadow-md hover:shadow-lg
               border border-blue-500/20 whitespace-nowrap w-auto"
                            >
                                Try it Free
                                <motion.div
                                    className="inline-block ml-2"
                                    animate={{x: [0, 4, 0]}}
                                    transition={{duration: 1.5, repeat: Infinity}}
                                >
                                    <ChevronRight className="w-5 h-5"/>
                                </motion.div>
                            </Button>
                        </motion.div>
                    </motion.div>
                </div>
            </section>

            {/* Video Showcase Section */}
            <section className="relative py-16 px-4">
                <div className="max-w-7xl mx-auto">
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                        viewport={{once: true}}
                        className="text-center mb-12"
                    >
                        <h2 className="text-3xl font-bold text-white mb-4">See TaxMe AI in Action</h2>
                        <p className="text-xl text-white/80">Watch how our platform simplifies tax planning and
                            analysis</p>
                    </motion.div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {[
                            {
                                title: "Tax Calculator Demo",
                                description: "See how our AI simplifies tax calculations and planning",
                                src: "/videos/tax-demo.mp4",
                                poster: "/images/tax-demo-thumbnail.png"
                            },
                            {
                                title: "Stock Analysis Demo",
                                description: "Discover our AI-powered stock market analysis tools",
                                src: "/videos/stock-demo.mp4",
                                poster: "/images/stock-demo-thumbnail.jpg"
                            }
                        ].map((video, index) => (
                            <motion.div
                                key={index}
                                initial={{opacity: 0, x: index === 0 ? -20 : 20}}
                                whileInView={{opacity: 1, x: 0}}
                                transition={{duration: 0.5}}
                                viewport={{once: true}}
                                className="relative rounded-xl overflow-hidden bg-[#1a1b4b]/30 backdrop-blur-sm border border-gray-700/50"
                            >
                                <VideoPlayer
                                    src={video.src}
                                    poster={video.poster}
                                    title={video.title}
                                />
                                <div className="p-4">
                                    <h3 className="text-xl font-semibold text-white mb-2">{video.title}</h3>
                                    <p className="text-white/80">{video.description}</p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Interactive Features Section */}
            <section className="relative py-16 px-4">
                <div className="max-w-7xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {[
                            {
                                icon: Calculator,
                                title: 'Accurate Tax Calculations & Analysis',
                                description: 'Our Tax Algorithm ensures precise tax calculations tailored to your specific situation',
                                stats: '99.9% Accuracy'
                            },
                            {
                                icon: Shield,
                                title: 'Secure & Private',
                                description: 'Bank-level security protecting your sensitive financial data',
                                stats: 'Data Security Certified'
                            },
                            {
                                icon: TrendingUp,
                                title: 'Smart Stock Analysis',
                                description: 'Get insights and optimization stock recommendation for your tax investment portfolio',
                                stats: '45% Better Returns'
                            }
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                initial={{opacity: 0, y: 20}}
                                whileInView={{opacity: 1, y: 0}}
                                transition={{duration: 0.5, delay: index * 0.1}}
                                viewport={{once: true}}
                                whileHover={{y: -10}}
                                className="relative"
                            >
                                <Card
                                    className="h-full p-6 bg-gradient-to-br from-[#1a1b4b]/50 to-[#1a1b4b]/30 backdrop-blur-sm border-gray-700/50 hover:border-blue-500/50 transition-all duration-300 overflow-hidden group">
                                    <div
                                        className="absolute top-0 right-0 w-32 h-32 bg-blue-500/10 rounded-full blur-2xl transform group-hover:scale-150 transition-transform duration-500"/>

                                    <feature.icon
                                        className="w-12 h-12 text-blue-400 mb-4 transform group-hover:scale-110 transition-transform duration-300"/>

                                    <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                                    <p className="text-white/80 mb-4">{feature.description}</p>

                                    <div className="flex items-center justify-between mt-auto">
                    <span className="text-sm text-blue-400 font-medium">
                      {feature.stats}
                    </span>
                                        <motion.div
                                            className="w-8 h-8 rounded-full bg-blue-500/20 flex items-center justify-center"
                                            whileHover={{scale: 1.2, rotate: 90}}
                                        >
                                            <ChevronRight className="w-4 h-4 text-blue-400"/>
                                        </motion.div>
                                    </div>
                                </Card>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Contact Form Section with Interactive Elements */}
            <section id="contact" className="relative py-16 px-4">
                <div className="max-w-7xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <motion.div
                            initial={{opacity: 0, x: -20}}
                            whileInView={{opacity: 1, x: 0}}
                            transition={{duration: 0.5}}
                            viewport={{once: true}}
                            className="bg-[#1a1b4b]/20 p-8 rounded-lg backdrop-blur-sm border border-gray-700/50"
                        >
                            <h2 className="text-3xl font-bold text-white mb-6">Get in Touch</h2>
                            <p className="text-white/90 mb-8">
                                Have questions about our services? Our team is here to help you navigate
                                your financial journey.
                            </p>
                            <div className="space-y-6">
                                <motion.div
                                    className="flex items-center text-white/90 group"
                                    whileHover={{x: 10}}
                                >
                                    <div
                                        className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center mr-4 group-hover:bg-blue-500/30 transition-colors duration-300">
                                        <Mail className="w-6 h-6 text-blue-400"/>
                                    </div>
                                    <div>
                                        <h4 className="font-medium text-white">Email Us</h4>
                                        <span className="text-white/80">support@taxmeai.com</span>
                                    </div>
                                </motion.div>

                                <motion.div
                                    className="flex items-center text-white/90 group"
                                    whileHover={{x: 10}}
                                >
                                    <div
                                        className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center mr-4 group-hover:bg-blue-500/30 transition-colors duration-300">
                                        <Phone className="w-6 h-6 text-blue-400"/>
                                    </div>
                                    <div>
                                        <h4 className="font-medium text-white">Call Us</h4>
                                        <span className="text-white/80">+1 (336) 365-9994</span>
                                    </div>
                                </motion.div>

                                <motion.div
                                    className="flex items-center text-white/90 group"
                                    whileHover={{x: 10}}
                                >
                                    <div
                                        className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center mr-4 group-hover:bg-blue-500/30 transition-colors duration-300">
                                        <MapPin className="w-6 h-6 text-blue-400"/>
                                    </div>
                                    <div>
                                        <h4 className="font-medium text-white">Location</h4>
                                        <span className="text-white/80">Winston-Salem, NC</span>
                                    </div>
                                </motion.div>
                            </div>
                        </motion.div>

                        <motion.form
                            initial={{opacity: 0, x: 20}}
                            whileInView={{opacity: 1, x: 0}}
                            transition={{duration: 0.5}}
                            viewport={{once: true}}
                            onSubmit={handleSubmit}
                            className="space-y-6 bg-[#1a1b4b]/20 p-8 rounded-lg backdrop-blur-sm border border-gray-700/50"
                        >
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-white mb-2 font-medium">Name</label>
                                    <motion.div whileHover={{scale: 1.02}} whileTap={{scale: 0.98}}>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-3 bg-[#0a0a23]/50 border border-gray-700 rounded-lg text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors duration-200"
                                            value={formData.name}
                                            onChange={(e) => setFormData({...formData, name: e.target.value})}
                                            disabled={isSubmitting}
                                            required
                                        />
                                    </motion.div>
                                </div>

                                <div>
                                    <label className="block text-white mb-2 font-medium">Email</label>
                                    <motion.div whileHover={{scale: 1.02}} whileTap={{scale: 0.98}}>
                                        <input
                                            type="email"
                                            className="w-full px-4 py-3 bg-[#0a0a23]/50 border border-gray-700 rounded-lg text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors duration-200"
                                            value={formData.email}
                                            onChange={(e) => setFormData({...formData, email: e.target.value})}
                                            disabled={isSubmitting}
                                            required
                                        />
                                    </motion.div>
                                </div>

                                <div>
                                    <label className="block text-white mb-2 font-medium">Subject</label>
                                    <motion.div whileHover={{scale: 1.02}} whileTap={{scale: 0.98}}>
                                        <input
                                            type="text"
                                            className="w-full px-4 py-3 bg-[#0a0a23]/50 border border-gray-700 rounded-lg text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors duration-200"
                                            value={formData.subject}
                                            onChange={(e) => setFormData({...formData, subject: e.target.value})}
                                            disabled={isSubmitting}
                                            required
                                        />
                                    </motion.div>
                                </div>

                                <div>
                                    <label className="block text-white mb-2 font-medium">Message</label>
                                    <motion.div whileHover={{scale: 1.02}} whileTap={{scale: 0.98}}>
                    <textarea
                        className="w-full px-4 py-3 bg-[#0a0a23]/50 border border-gray-700 rounded-lg text-white h-32 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors duration-200"
                        value={formData.message}
                        onChange={(e) => setFormData({...formData, message: e.target.value})}
                        disabled={isSubmitting}
                        required
                    />
                                    </motion.div>
                                </div>

                                <motion.div
                                    whileHover={{scale: 1.02}}
                                    whileTap={{scale: 0.98}}
                                >
                                    <Button
                                        type="submit"
                                        className="w-full bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-700 hover:to-blue-900 text-white py-3 rounded-lg font-medium transition-all duration-300 shadow-lg shadow-blue-500/20 hover:shadow-blue-500/40 flex items-center justify-center gap-2"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? (
                                            <>
                                                <motion.div
                                                    animate={{rotate: 360}}
                                                    transition={{duration: 1, repeat: Infinity, ease: "linear"}}
                                                >
                                                    <BarChart className="w-5 h-5"/>
                                                </motion.div>
                                                <span>Sending...</span>
                                            </>
                                        ) : (
                                            <>
                                                <MessageCircle className="w-5 h-5"/>
                                                <span>Send Message</span>
                                            </>
                                        )}
                                    </Button>
                                </motion.div>
                            </div>
                        </motion.form>
                    </div>
                </div>
            </section>

            {/* Footer with Interactive Elements */}
            <footer className="relative bg-[#0a0a23]/90 border-t border-white/10 py-12 px-4 mt-16">
                <div className="max-w-7xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                        <motion.div
                            initial={{opacity: 0, y: 20}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.5}}
                            viewport={{once: true}}
                        >
                            <h3 className="text-white font-semibold mb-4">TaxMe AI</h3>
                            <p className="text-white/80">
                                Revolutionizing tax preparation with artificial intelligence
                            </p>
                        </motion.div>

                        <motion.div
                            initial={{opacity: 0, y: 20}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.5, delay: 0.1}}
                            viewport={{once: true}}
                        >
                            <h3 className="text-white font-semibold mb-4">Company</h3>
                            <ul className="space-y-2">
                                {[
                                    {text: 'About Us', href: '/about'},
                                    {text: 'Join Us', href: '/join-us'},
                                    {text: 'Contact', href: '#contact'}
                                ].map((item, index) => (
                                    <motion.li key={index} whileHover={{x: 5}}>
                                        <a
                                            href={item.href}
                                            className="text-white/80 hover:text-white transition-colors duration-200 block"
                                        >
                                            {item.text}
                                        </a>
                                    </motion.li>
                                ))}
                            </ul>
                        </motion.div>

                        <motion.div
                            initial={{opacity: 0, y: 20}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.5, delay: 0.2}}
                            viewport={{once: true}}
                        >
                            <h3 className="text-white font-semibold mb-4">Legal</h3>
                            <ul className="space-y-2">
                                {[
                                    {text: 'Terms of Service', href: '/legal/terms'},
                                    {text: 'Privacy Policy', href: '/legal/privacy'},
                                    {text: 'California Privacy Notice', href: '/legal/privacy#california'},
                                    {text: 'Blog', href: '/admin/blog'}
                                ].map((item, index) => (
                                    <motion.li key={index} whileHover={{x: 5}}>
                                        <a
                                            href={item.href}
                                            className="text-white/80 hover:text-white transition-colors duration-200 block"
                                        >
                                            {item.text}
                                        </a>
                                    </motion.li>
                                ))}
                            </ul>
                        </motion.div>

                        <motion.div
                            initial={{opacity: 0, y: 20}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.5, delay: 0.3}}
                            viewport={{once: true}}
                        >
                            <h3 className="text-white font-semibold mb-4">Follow Us</h3>
                            <div className="space-y-4">
                                {[
                                    {text: 'Twitter', href: 'https://x.com/taxmeai'},
                                    {text: 'LinkedIn', href: 'https://linkedin.com/company/taxme-ai/'}
                                ].map((item, index) => (
                                    <motion.a
                                        key={index}
                                        href={item.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-white/80 hover:text-white transition-colors duration-200 block"
                                        whileHover={{x: 5}}
                                    >
                                        {item.text}
                                    </motion.a>
                                ))}
                            </div>
                        </motion.div>
                    </div>

                    <motion.div
                        initial={{opacity: 0}}
                        whileInView={{opacity: 1}}
                        transition={{duration: 0.5, delay: 0.4}}
                        viewport={{once: true}}
                        className="border-t border-white/10 mt-8 pt-8 text-center text-white/70"
                    >
                        <p>&copy; {new Date().getFullYear()} O2A2 LLC. All rights reserved.</p>
                    </motion.div>
                </div>
            </footer>

            {/* Cookies Dialog with Animation */}
            {/* Cookies Dialog */}
            <AnimatePresence>
                {showCookies && (
                    <motion.div
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 50}}
                        className="fixed bottom-0 inset-x-0 p-4 z-50"
                    >
                        <div className="max-w-7xl mx-auto">
                            <div
                                className="bg-[#1a1b4b]/90 backdrop-blur-md border border-white/10 rounded-lg shadow-lg px-4 py-6">
                                <div className="flex items-start justify-between">
                                    <div className="flex-1">
                                        <h3 className="text-white font-semibold">Cookie Notice</h3>
                                        <p className="mt-1 text-sm text-white/80">
                                            We use cookies to enhance your browsing experience, serve personalized
                                            content, and analyze our traffic. These cookies help us understand site
                                            usage patterns, improve our services, customize your preferences, and
                                            maintain site security. By continuing to use our site, you agree to our
                                            privacy policy and the storage of these essential and optional cookies on
                                            your device.
                                        </p>
                                    </div>
                                    <div className="ml-4 flex-shrink-0 flex items-center space-x-4">
                                        <Button
                                            onClick={handleCookiesAccept}
                                            className="bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-700 hover:to-blue-900 text-white shadow-lg shadow-blue-500/20 hover:shadow-blue-500/40"
                                        >
                                            Accept All
                                        </Button>
                                        <button
                                            onClick={() => setShowCookies(false)}
                                            className="text-white/70 hover:text-white transition-colors duration-200"
                                        >
                                            <X className="w-5 h-5"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

// Add required CSS
const styles = `
  .galaxy-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, #0a0a23, #1a1b4b);
    z-index: -2;
  }

  .galaxy-spiral {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 50% 50%, 
        rgba(37, 99, 235, 0.1) 0%, 
        transparent 50%),
      radial-gradient(circle at 80% 20%, 
        rgba(147, 51, 234, 0.1) 0%, 
        transparent 30%);
    z-index: -1;
    opacity: 0.5;
  }

  .star {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    animation: twinkle var(--duration) ease-in-out infinite;
  }

  @keyframes twinkle {
    0%, 100% { opacity: 0.2; }
    50% { opacity: 1; }
  }

  /* Custom scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #3b82f6, #1d4ed8);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #60a5fa, #2563eb);
  }

  /* Text gradient animation */
  .text-gradient {
    background: linear-gradient(
      to right,
      #60a5fa,
      #3b82f6,
      #2563eb,
      #60a5fa
    );
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 4s linear infinite;
  }

  @keyframes gradient {
    0% { background-position: 0% center; }
    100% { background-position: 200% center; }
  }

  /* Card hover effects */
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(59, 130, 246, 0.2);
  }

  /* Video card hover effect */
  .video-card {
    transition: transform 0.3s ease;
  }

  .video-card:hover {
    transform: scale(1.02);
  }

  /* Form input focus effects */
  .form-input:focus {
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }

  /* Button hover animation */
  .animated-button {
    position: relative;
    overflow: hidden;
  }

  .animated-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.6s ease, height 0.6s ease;
  }

  .animated-button:hover::after {
    width: 300%;
    height: 300%;
  }
`;

export default HomePage;
