import React from 'react';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import {FiArrowLeft, FiAward, FiDollarSign, FiShield, FiTrendingUp} from 'react-icons/fi';
import '../styles/About.css';

const GalaxyBackground = () => {
    React.useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';

            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;

                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);

                starsContainer.appendChild(star);
            }

            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background"/>
            <div className="galaxy-spiral"/>
        </>
    );
};

const About = () => {
    const navigate = useNavigate();

    return (
        <div className="about-page">
            <GalaxyBackground/>

            <motion.button
                className="back-button"
                onClick={() => navigate('/')}
                whileHover={{x: -5}}
            >
                <FiArrowLeft size={24}/>
                <span>Back</span>
            </motion.button>

            <div className="about-container">
                <div className="about-header">
                    <motion.img
                        src="/tax-logo-dark.svg"
                        alt="TaxMeAI Logo"
                        className="about-logo"
                        animate={{rotate: 360}}
                        transition={{
                            duration: 20,
                            repeat: Infinity,
                            ease: "linear"
                        }}
                    />
                    <h1>About TaxMe AI</h1>
                </div>

                <div className="about-content">
                    <section className="about-section">
                        <h2>Our Mission</h2>
                        <p>TaxMe AI is revolutionizing tax preparation and financial insights through advanced
                            artificial intelligence. Our mission is to democratize access to sophisticated tax
                            calculation tools and financial intelligence, making it accessible to everyone from
                            individual taxpayers to financial professionals.</p>
                    </section>

                    <section className="about-section">
                        <h2>Our Core Services</h2>
                        <div className="services-grid">
                            <div className="service">
                                <FiDollarSign className="service-icon"/>
                                <h3>Federal Tax Calculator</h3>
                                <p>Comprehensive federal tax calculation service powered by AI, handling all tax
                                    scenarios, deductions, and credits with precision and accuracy.</p>
                            </div>
                            <div className="service">
                                <FiDollarSign className="service-icon"/>
                                <h3>State Tax Calculator</h3>
                                <p>Complete tax calculation coverage for all 50 U.S. states, accounting for
                                    state-specific regulations, deductions, and local tax requirements.</p>
                            </div>
                            <div className="service">
                                <FiTrendingUp className="service-icon"/>
                                <h3>Coming Soon: Congress Trading Tracker</h3>
                                <p>Advanced tracking and analysis of congressional trading activities, providing
                                    insights into legislative investment patterns and market implications.</p>
                            </div>
                        </div>
                    </section>

                    <section className="about-section">
                        <h2>Our Technology</h2>
                        <p>Our platform leverages cutting-edge AI and machine learning technologies to deliver:</p>
                        <div className="tech-features">
                            <div className="tech-feature">
                                <h3>Intelligent Tax Analysis</h3>
                                <p>Real-time processing of tax regulations across federal and state levels, ensuring
                                    up-to-date compliance and optimal calculations.</p>
                            </div>
                            <div className="tech-feature">
                                <h3>Automated Calculations</h3>
                                <p>Advanced algorithms handle complex tax scenarios, deductions, and credits across all
                                    U.S. jurisdictions.</p>
                            </div>
                            <div className="tech-feature">
                                <h3>Predictive Analytics</h3>
                                <p>AI-powered insights for tax planning and financial decision-making, including future
                                    congress trading analysis.</p>
                            </div>
                        </div>
                    </section>

                    <section className="about-section">
                        <h2>Why Choose TaxMe AI</h2>
                        <div className="features-grid">
                            <div className="feature">
                                <FiAward className="feature-icon"/>
                                <h3>Comprehensive Coverage</h3>
                                <p>Complete tax calculation for all 50 states and federal returns</p>
                            </div>
                            <div className="feature">
                                <FiShield className="feature-icon"/>
                                <h3>Security</h3>
                                <p>Bank-level encryption and rigorous data protection</p>
                            </div>
                            <div className="feature">
                                <FiTrendingUp className="feature-icon"/>
                                <h3>Innovation</h3>
                                <p>Cutting-edge AI technology with continuous updates</p>
                            </div>
                            <div className="feature">
                                <FiDollarSign className="feature-icon"/>
                                <h3>Accuracy</h3>
                                <p>Precise calculations with multi-layer verification</p>
                            </div>
                        </div>
                    </section>

                    <section className="about-section">
                        <h2>Our Values</h2>
                        <ul className="values-list">
                            <li>
                                <h3>Accuracy and Reliability</h3>
                                <p>Delivering precise tax calculations and financial insights you can trust</p>
                            </li>
                            <li>
                                <h3>Innovation</h3>
                                <p>Continuously advancing our AI capabilities and feature set</p>
                            </li>
                            <li>
                                <h3>Transparency</h3>
                                <p>Clear, understandable results and methodologies</p>
                            </li>
                            <li>
                                <h3>Accessibility</h3>
                                <p>Making sophisticated tax and financial tools available to everyone</p>
                            </li>
                        </ul>
                    </section>

                    <section className="about-section">
                        <h2>Future Innovation</h2>
                        <p>We're excited to announce our upcoming Congress Trading Tracker feature, which will provide
                            unprecedented insights into legislative trading patterns and their market implications. This
                            innovative tool will help users understand and analyze the investment activities of
                            congressional members, adding another dimension to our financial intelligence
                            capabilities.</p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default About;