import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import {HelpCircle, Home, User, UserMinus, Users} from 'lucide-react';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '../../../../components/ui/tooltip';
import '../../../../styles/TaxCalculator/steps/BasicInfo.css';

import Layout from '../shared/Layout';
import {TaxCalculationService} from '../../../../utils/services/taxCalculationService';

const BasicInfo = ({formData, setFormData, error}) => {
    const navigate = useNavigate();
    const {stateCode} = useParams();

    useEffect(() => {
        if (!formData.state || !formData.year) {
            navigate('/tax-calculator', {replace: true});
        }
    }, [formData.state, formData.year, navigate]);

    const filingStatuses = [
        {
            value: TaxCalculationService.getFilingStatuses().SINGLE,
            label: 'Single',
            description: 'For unmarried individuals',
            icon: User,
            tooltip: "Choose this if you're not married and don't qualify for other filing statuses"
        },
        {
            value: TaxCalculationService.getFilingStatuses().MARRIED_JOINT,
            label: 'Married Filing Jointly',
            description: 'For married couples filing together',
            icon: Users,
            tooltip: 'Often provides the most tax advantages for married couples'
        },
        {
            value: TaxCalculationService.getFilingStatuses().MARRIED_SEPARATE,
            label: 'Married Filing Separately',
            description: 'For married individuals filing separate returns',
            icon: UserMinus,
            tooltip: 'Might be beneficial in specific financial situations'
        },
        {
            value: TaxCalculationService.getFilingStatuses().HEAD_OF_HOUSEHOLD,
            label: 'Head of Household',
            description: 'For unmarried individuals supporting dependents',
            icon: Home,
            tooltip: 'Provides tax advantages for single parents or guardians'
        }
    ];

    const handleStatusSelect = (status) => {
        setFormData(prev => ({
            ...prev,
            filing_status: status,
            deductions: {
                ...prev.deductions,
                type: 'standard'
            }
        }));
    };

    const handleNext = () => {
        if (formData.filing_status) {
            navigate(`/tax-calculator/${stateCode}/income`, {replace: true});
        }
    };

    const handleBack = () => {
        navigate('/tax-calculator', {replace: true});
    };

    return (
        <Layout formData={formData} setFormData={setFormData}>
            <div className="filing-status-container rounded-full">
                <motion.div
                    className="header-section justify-center"
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                >
                    <div className="header-content rounded-full transition-colors bg-transparent justify-center">
                        <h3>Filing Status</h3>
                        <p>Select how you'll file your taxes</p>
                    </div>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger className="help-trigger bg-transparent ">
                                <HelpCircle size={20}/>
                            </TooltipTrigger>
                            <TooltipContent side="left">
                                <div className="tooltip-content">
                                    <p>Your filing status determines:</p>
                                    <ul>
                                        <li>• Tax bracket ranges</li>
                                        <li>• Standard deduction amount</li>
                                        <li>• Credit eligibility</li>
                                    </ul>
                                </div>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </motion.div>

                <motion.div
                    className="status-grid"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0.2}}
                >
                    {filingStatuses.map(({value, label, description, icon: Icon, tooltip}) => (
                        <TooltipProvider key={value}>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <motion.button
                                        type="button"
                                        onClick={() => handleStatusSelect(value)}
                                        className={`status-card ${formData.filing_status === value ? 'selected' : ''}`}
                                        whileHover={{scale: 1.01}}
                                        whileTap={{scale: 0.99}}
                                    >
                                        <div className="status-icon">
                                            <Icon size={24}/>
                                        </div>
                                        <div className="status-content">
                                            <h4>{label}</h4>
                                            <p>{description}</p>
                                        </div>
                                    </motion.button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>{tooltip}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    ))}
                </motion.div>

                {error && (
                    <motion.div
                        className="error-message"
                        initial={{opacity: 0, y: -10}}
                        animate={{opacity: 1, y: 0}}
                    >
                        {error}
                    </motion.div>
                )}

                <motion.div
                    className="nav-buttons"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0.3}}
                >
                    <motion.button
                        onClick={handleBack}
                        className="nav-button nav-button-back"
                        whileHover={{scale: 1.05}}
                        whileTap={{scale: 0.95}}
                    >
                        Back to State
                    </motion.button>
                    <motion.button
                        onClick={handleNext}
                        disabled={!formData.filing_status}
                        className="nav-button nav-button-next"
                        whileHover={formData.filing_status ? {scale: 1.05} : {}}
                        whileTap={formData.filing_status ? {scale: 0.95} : {}}
                    >
                        Continue to Income
                    </motion.button>
                </motion.div>
            </div>
        </Layout>
    );
};

export default BasicInfo;