import React from 'react';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import {FiArrowLeft, FiLock, FiShield} from 'react-icons/fi';
import '../styles/TermsPrivacy.css';

const GalaxyBackground = () => {
    React.useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';

            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;

                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);

                starsContainer.appendChild(star);
            }

            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background"/>
            <div className="galaxy-spiral"/>
        </>
    );
};

const TermsPrivacy = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = React.useState('terms');

    return (
        <div className="terms-privacy-page">
            <GalaxyBackground/>

            <motion.button
                className="back-button"
                onClick={() => navigate('/')}
                whileHover={{x: -5}}
            >
                <FiArrowLeft size={24}/>
                <span>Back to Chat</span>
            </motion.button>

            <div className="terms-privacy-container">
                <div className="terms-privacy-header">
                    <motion.img
                        src="/tax-logo-dark.svg"
                        alt="TaxMeAI Logo"
                        className="terms-privacy-logo"
                        animate={{rotate: 360}}
                        transition={{
                            duration: 20,
                            repeat: Infinity,
                            ease: "linear"
                        }}
                    />
                    <h1>Legal Information</h1>
                </div>

                <div className="tabs">
                    <button
                        className={`tab ${activeTab === 'terms' ? 'active' : ''}`}
                        onClick={() => setActiveTab('terms')}
                    >
                        <FiShield/> Terms of Service
                    </button>
                    <button
                        className={`tab ${activeTab === 'privacy' ? 'active' : ''}`}
                        onClick={() => setActiveTab('privacy')}
                    >
                        <FiLock/> Privacy Policy
                    </button>
                </div>

                <div className="terms-privacy-content">
                    {activeTab === 'terms' ? (
                        <div className="terms-section">
                            <section className="content-section">
                                <h2>Terms of Service</h2>
                                <p>Last updated: November 26, 2024</p>

                                <h3>1. Acceptance of Terms</h3>
                                <p>By accessing and using TaxMeAI's services, you agree to be bound by these Terms of
                                    Service and all applicable laws and regulations.</p>

                                <h3>2. Description of Service</h3>
                                <p>TaxMeAI provides AI-powered tax preparation and consultation services. While our AI
                                    strives for accuracy, all final tax decisions remain the responsibility of the
                                    user.</p>

                                <h3>3. User Responsibilities</h3>
                                <ul>
                                    <li>Provide accurate and complete information</li>
                                    <li>Maintain the confidentiality of your account</li>
                                    <li>Comply with all applicable tax laws and regulations</li>
                                    <li>Review all AI-generated suggestions before implementation</li>
                                </ul>

                                <h3>4. Service Limitations</h3>
                                <p>Our AI provides suggestions based on the information provided and current tax
                                    regulations. Users should consult with qualified tax professionals for complex
                                    situations.</p>

                                <h3>5. Intellectual Property</h3>
                                <p>All content, features, and functionality of TaxMeAI are exclusive property of TaxMeAI
                                    and are protected by international copyright laws.</p>

                                <h3>6. Data Usage</h3>
                                <p>We collect and process data as outlined in our Privacy Policy. By using our service,
                                    you consent to such processing.</p>

                                <h3>7. Liability Limitations</h3>
                                <p>TaxMeAI is not liable for any damages arising from the use or inability to use our
                                    services.</p>

                                <h3>8. Account Termination</h3>
                                <p>We reserve the right to terminate accounts that violate these terms or engage in
                                    fraudulent activities.</p>
                            </section>
                        </div>
                    ) : (
                        <div className="privacy-section">
                            <section className="content-section">
                                <h2>Privacy Policy</h2>
                                <p>Last updated: November 26, 2024</p>

                                <h3>1. Data Collection</h3>
                                <p>We collect the following types of information:</p>
                                <ul>
                                    <li>Personal identification information</li>
                                    <li>Tax-related documents and data</li>
                                    <li>Usage data and analytics</li>
                                    <li>Communication preferences</li>
                                </ul>

                                <h3>2. Data Usage</h3>
                                <p>Your data is used to:</p>
                                <ul>
                                    <li>Provide tax preparation services</li>
                                    <li>Improve our AI algorithms</li>
                                    <li>Ensure service accuracy</li>
                                    <li>Comply with legal requirements</li>
                                </ul>

                                <h3>3. Data Protection</h3>
                                <p>We implement robust security measures including:</p>
                                <ul>
                                    <li>End-to-end encryption</li>
                                    <li>Regular security audits</li>
                                    <li>Access controls and monitoring</li>
                                    <li>Secure data centers</li>
                                </ul>

                                <h3>4. Data Sharing</h3>
                                <p>We never sell your personal data. Sharing occurs only:</p>
                                <ul>
                                    <li>With your explicit consent</li>
                                    <li>To comply with legal obligations</li>
                                    <li>With trusted service providers</li>
                                </ul>

                                <h3>5. Your Rights</h3>
                                <p>You have the right to:</p>
                                <ul>
                                    <li>Access your personal data</li>
                                    <li>Request data correction</li>
                                    <li>Request data deletion</li>
                                    <li>Opt-out of data processing</li>
                                </ul>

                                <h3>6. Cookies and Tracking</h3>
                                <p>We use cookies to enhance user experience and analyze service usage. You can control
                                    cookie preferences through your browser settings.</p>

                                <h3>7. Updates to Policy</h3>
                                <p>We will notify users of any material changes to this privacy policy via email or
                                    service notifications.</p>

                                <h3>8. Contact Information</h3>
                                <p>For privacy-related inquiries, contact our Data Protection Officer at
                                    support@taxmeai.com</p>
                            </section>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TermsPrivacy;
