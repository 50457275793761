// src/pages/Blog/BlogList.jsx
// src/pages/Blog/BlogList.jsx
import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {blogService} from '../../services/blogService';
import {Card} from '../../components/ui/card';
import {Button} from '../../components/ui/button';
import {Input} from '../../components/ui/input';
import {
    ArrowRight,
    Calendar,
    ChevronLeft,
    ChevronLeftIcon,
    ChevronRight,
    Facebook,
    Linkedin,
    Search,
    Share2,
    Star,
    Twitter
} from 'lucide-react';
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue,} from '../../components/ui/select';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';

const ITEMS_PER_PAGE = 9;

const sortOptions = [
    {value: 'newest', label: 'Newest First'},
    {value: 'oldest', label: 'Oldest First'},
    {value: 'popular', label: 'Most Popular'},
];

const categoryDescriptions = {
    'all': 'Explore our comprehensive collection of articles covering taxes, investments, and financial technology.',
    'federal-tax': 'Stay updated with the latest federal tax regulations, deductions, and filing strategies.',
    'state-tax': 'Navigate state-specific tax requirements and maximize your state tax benefits.',
    'stock-market': 'Discover insights on market trends, investment strategies, and stock analysis.',
    'ai-technology': 'Learn how AI is transforming financial services and tax preparation.',
    'tax-planning': 'Expert advice on tax planning strategies and long-term financial optimization.'
};

const PublicBlogList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [blogs, setBlogs] = useState([]);
    const [featuredPosts, setFeaturedPosts] = useState([]);
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [email, setEmail] = useState('');
    const [subscribeStatus, setSubscribeStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('newest');
    const navigate = useNavigate();

    const fetchRelatedPosts = async (category) => {
        try {
            const data = await blogService.getRelatedPosts(category);
            setRelatedPosts(data);
        } catch (error) {
            console.error('Error fetching related posts:', error);
        }
    };

    // Add reading time estimation function
    const calculateReadingTime = (content) => {
        const wordsPerMinute = 200;
        const wordCount = content?.split(/\s+/).length || 0;
        const minutes = Math.ceil(wordCount / wordsPerMinute);
        return `${minutes} min read`;
    };

    useEffect(() => {
        const page = parseInt(searchParams.get('page')) || 1;
        const category = searchParams.get('category') || 'all';
        const search = searchParams.get('search') || '';
        const sort = searchParams.get('sort') || 'newest';

        setCurrentPage(page);
        setSelectedCategory(category);
        setSearchTerm(search);
        setSortBy(sort);

        fetchBlogs(page, category, search, sort);
        fetchFeaturedPosts();
        if (category !== 'all') {
            fetchRelatedPosts(category);
        }
    }, [searchParams]);

    const fetchBlogs = async (page, category) => {
        try {
            setLoading(true);
            setError(null);
            const data = await blogService.getPublishedBlogs({
                category: category === 'all' ? undefined : category,
                page,
                limit: ITEMS_PER_PAGE
            });
            setBlogs(data.posts || []);
            setTotalPages(Math.ceil(data.total / ITEMS_PER_PAGE));
        } catch (error) {
            console.error('Error fetching blogs:', error);
            setError(error.message || 'Failed to fetch blog posts');
        } finally {
            setLoading(false);
        }
    };

    const fetchFeaturedPosts = async () => {
        try {
            const data = await blogService.getFeaturedPosts();
            setFeaturedPosts(data || []);
        } catch (error) {
            console.error('Error fetching featured posts:', error);
        }
    };

    const handlePageChange = (newPage) => {
        setSearchParams({page: newPage, category: selectedCategory});
    };

    const categories = [
        {id: 'all', name: 'All Posts'},
        {id: 'federal-tax', name: 'Federal Tax'},
        {id: 'state-tax', name: 'State Tax'},
        {id: 'stock-market', name: 'Stock Market'},
        {id: 'ai-technology', name: 'AI Technology'},
        {id: 'tax-planning', name: 'Tax Planning'}
    ];

    const handleCategoryChange = (category) => {
        setSearchParams({page: 1, category});
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'No date available';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();

        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setSubscribeStatus('error');
            setError('Please enter a valid email address');
            return;
        }

        try {
            setSubscribeStatus('loading');
            await blogService.subscribeNewsletter(email);
            setSubscribeStatus('success');
            setEmail('');
            setTimeout(() => setSubscribeStatus(''), 3000);
        } catch (error) {
            console.error('Subscription error:', error);
            setSubscribeStatus('error');
            setError(error.message);
            setTimeout(() => setSubscribeStatus(''), 3000);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchParams({
            page: 1,
            category: selectedCategory,
            search: searchTerm,
            sort: sortBy
        });
    };

    const handleSort = (value) => {
        setSortBy(value);
        setSearchParams({
            page: currentPage,
            category: selectedCategory,
            search: searchTerm,
            sort: value
        });
    };

    const handleShare = (platform, url, title) => {
        const shareUrls = {
            twitter: `https://x.com/intent/post?url=${url}&text=${title}`,
            facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
            linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`
        };
        window.open(shareUrls[platform], '_blank');
    };


    if (loading) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black">
                <div className="max-w-7xl mx-auto px-4 py-8">
                    <div className="animate-pulse space-y-8">
                        {[...Array(6)].map((_, i) => (
                            <div key={i} className="h-48 bg-gray-800/50 rounded-lg"></div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black">
            {/* Navigation Bar */}
            <div className="border-b border-gray-800">
                <div className="max-w-7xl mx-auto px-4 py-4">
                    <Button
                        onClick={() => navigate('/dashboard')}
                        variant="outline"
                        className="flex items-center gap-2"
                    >
                        <ChevronLeft className="w-4 h-4"/>
                        Back
                    </Button>
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 py-8">
                {/* Header */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold text-white mb-4">TaxMe AI Blog</h1>
                    <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                        Stay informed with the latest insights on taxes, stock market analysis, and AI technology.
                    </p>
                </div>

                {/* Search and Sort Bar */}
                <div className="mb-8 flex flex-col sm:flex-row gap-4 items-center">
                    <form onSubmit={handleSearch} className="flex-1">
                        <div className="relative">
                            <Input
                                type="search"
                                placeholder="Search articles..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10"
                            />
                            <Search
                                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4"/>
                        </div>
                    </form>
                    <Select value={sortBy} onValueChange={handleSort}>
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Sort by"/>
                        </SelectTrigger>
                        <SelectContent>
                            {sortOptions.map(option => (
                                <SelectItem key={option.value} value={option.value}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                {/* Category Description */}
                <div className="mb-8 text-center">
                    <p className="text-gray-400 text-lg">
                        {categoryDescriptions[selectedCategory]}
                    </p>
                </div>

                {/* Featured Posts Section */}
                {/* Featured Posts Section */}
                {Array.isArray(featuredPosts) && featuredPosts.length > 0 && (
                    <div className="mb-16">
                        <h2 className="text-2xl font-bold text-white mb-6 flex items-center">
                            <Star className="w-5 h-5 text-yellow-500 mr-2"/>
                            Featured Articles
                        </h2>
                        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                            {featuredPosts.map((post) => (
                                <Card key={post.id || post.slug}
                                      className="bg-gradient-to-br from-blue-900/30 to-purple-900/30 hover:from-blue-900/40 hover:to-purple-900/40 transition-all duration-300">
                                    <Link to={`/blog/${post.slug}`} className="p-6 block">
                                        <div className="flex justify-between items-center mb-4">
                            <span className="px-3 py-1 bg-yellow-900/50 text-yellow-400 rounded-full text-xs">
                                Featured
                            </span>
                                            <span className="text-gray-400 text-xs">
                                {calculateReadingTime(post.content)}
                            </span>
                                        </div>
                                        <h3 className="text-xl font-bold text-white mb-3">{post.title}</h3>
                                        <p className="text-gray-400 text-sm line-clamp-2 mb-4">{post.meta_description}</p>
                                        <span className="text-blue-400 inline-flex items-center group">
                            Read More
                            <ArrowRight className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1"/>
                        </span>
                                    </Link>
                                </Card>
                            ))}
                        </div>
                    </div>
                )}


                {/* Share Buttons */}
                <div className="fixed right-4 top-1/2 transform -translate-y-1/2 space-y-2">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="icon" className="rounded-full">
                                <Share2 className="h-4 w-4"/>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="center" side="right">
                            <DropdownMenuItem
                                onClick={() => handleShare('twitter', window.location.href, 'Check out this blog')}>
                                <Twitter className="h-4 w-4 mr-2"/>
                                Twitter
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={() => handleShare('facebook', window.location.href, 'Check out this blog')}>
                                <Facebook className="h-4 w-4 mr-2"/>
                                Facebook
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={() => handleShare('linkedin', window.location.href, 'Check out this blog')}>
                                <Linkedin className="h-4 w-4 mr-2"/>
                                LinkedIn
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>


                {/* Category Filter */}
                <div className="mb-12">
                    <div className="flex flex-wrap justify-center gap-3">
                        {categories.map((category) => (
                            <Button
                                key={category.id}
                                onClick={() => setSelectedCategory(category.id)}
                                variant={selectedCategory === category.id ? "default" : "outline"}
                                className={`
                                    ${selectedCategory === category.id
                                    ? "bg-blue-600 hover:bg-blue-700"
                                    : "border-gray-700"}
                                    rounded-full px-6
                                `}
                            >
                                {category.name}
                            </Button>
                        ))}
                    </div>
                </div>

                {/* Newsletter Subscription */}
                <Card className="mb-16 p-8 bg-gradient-to-r from-blue-900/30 to-purple-900/30">
                    <div className="max-w-2xl mx-auto text-center">
                        <h3 className="text-2xl font-bold text-white mb-4">Subscribe to Our Newsletter</h3>
                        <p className="text-gray-400 mb-6">Get the latest tax insights and financial updates delivered to
                            your inbox.</p>
                        <form onSubmit={handleSubscribe} className="flex gap-4 max-w-md mx-auto">
                            <Input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="flex-grow"
                            />
                            <Button
                                type="submit"
                                className="bg-blue-600 hover:bg-blue-700"
                                disabled={subscribeStatus === 'loading'}
                            >
                                {subscribeStatus === 'loading' ? 'Subscribing...' : 'Subscribe'}
                            </Button>
                        </form>
                        {subscribeStatus === 'success' && (
                            <p className="text-green-400 mt-2">Successfully subscribed!</p>
                        )}
                        {subscribeStatus === 'error' && (
                            <p className="text-red-400 mt-2">Failed to subscribe. Please try again.</p>
                        )}
                    </div>
                </Card>

                {/* Error Message */}
                {error && (
                    <Card className="p-6 bg-red-900/30 border-red-500/50 mb-8">
                        <p className="text-red-400 text-center">{error}</p>
                        <Button
                            onClick={fetchBlogs}
                            className="mt-4 mx-auto block bg-red-600 hover:bg-red-700"
                        >
                            Try Again
                        </Button>
                    </Card>
                )}

                {/* Blog Posts Grid */}
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {blogs.map((blog) => (
                        <div key={blog.id} className="flex flex-col">
                            <Card
                                className="flex flex-col h-full bg-gray-900/50 hover:bg-gray-800/50 transition-colors duration-200">
                                <Link
                                    to={`/blog/${blog.slug}`}
                                    className="p-6 flex flex-col h-full"
                                >
                                    {/* Category Badge */}
                                    <div className="mb-4 flex justify-between items-center">
                    <span className="px-3 py-1 bg-blue-900/50 text-blue-400 rounded-full text-xs">
                        {categories.find(c => c.id === blog.category)?.name || blog.category}
                    </span>
                                        <span className="text-gray-400 text-xs">
                        {calculateReadingTime(blog.content)}
                    </span>
                                    </div>

                                    {/* Title */}
                                    <h3 className="text-xl font-bold text-white mb-3 flex-grow">
                                        {blog.title}
                                    </h3>

                                    {/* Description */}
                                    <p className="text-gray-400 text-sm mb-4 line-clamp-2">
                                        {blog.meta_description}
                                    </p>

                                    {/* Date and Read More */}
                                    <div
                                        className="flex items-center justify-between text-sm mt-auto pt-4 border-t border-gray-800">
                                        <div className="flex items-center text-gray-500">
                                            <Calendar className="w-4 h-4 mr-2"/>
                                            {formatDate(blog.published_date)}
                                        </div>
                                        <span className="text-blue-400 inline-flex items-center group">
                                            Read More
                                            <ArrowRight
                                                className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1"/>
                                        </span>
                                    </div>
                                </Link>
                            </Card>
                        </div>
                    ))}
                </div>

                {/* Related Posts Section */}
                {relatedPosts.length > 0 && (
                    <div className="mt-16">
                        <h2 className="text-2xl font-bold text-white mb-6">Related Posts</h2>
                        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                            {relatedPosts.map(post => (
                                <Card key={post.id}
                                      className="bg-gray-900/50 hover:bg-gray-800/50 transition-colors duration-200">
                                    <Link to={`/blog/${post.slug}`} className="p-6 block">
                                        <h3 className="text-lg font-bold text-white mb-2">{post.title}</h3>
                                        <p className="text-gray-400 text-sm line-clamp-2">{post.meta_description}</p>
                                    </Link>
                                </Card>
                            ))}
                        </div>
                    </div>
                )}

                {/* Pagination */}
                {totalPages > 1 && (
                    <div className="mt-12 flex justify-center gap-2">
                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            variant="outline"
                            className="flex items-center gap-2"
                        >
                            <ChevronLeftIcon className="w-4 h-4"/>
                            Previous
                        </Button>
                        {[...Array(totalPages)].map((_, i) => (
                            <Button
                                key={i + 1}
                                onClick={() => handlePageChange(i + 1)}
                                variant={currentPage === i + 1 ? 'default' : 'outline'}
                                className={currentPage === i + 1 ? 'bg-blue-600' : ''}
                            >
                                {i + 1}
                            </Button>
                        ))}
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            variant="outline"
                            className="flex items-center gap-2"
                        >
                            Next
                            <ChevronRight className="w-4 h-4"/>
                        </Button>
                    </div>
                )}

                {/* Empty State */}
                {blogs.length === 0 && !error && (
                    <Card className="p-8 text-center bg-gray-900/50">
                        <p className="text-gray-400 mb-4">No blog posts found in this category.</p>
                        <Button
                            onClick={() => setSelectedCategory('all')}
                            className="bg-blue-600 hover:bg-blue-700"
                        >
                            View All Posts
                        </Button>
                    </Card>
                )}
            </div>
        </div>
    );
};

export default PublicBlogList;