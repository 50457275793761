// src/components/VideoPlayer.jsx
import React, {useState, useRef} from 'react';
import {Play, Pause, Maximize2, Volume2, VolumeX} from 'lucide-react';

const VideoPlayer = ({src, poster, title}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const videoRef = useRef(null);

    const handlePlayPause = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleMute = () => {
        videoRef.current.muted = !videoRef.current.muted;
        setIsMuted(!isMuted);
    };

    const handleFullscreen = () => {
        if (!document.fullscreenElement) {
            videoRef.current.requestFullscreen();
            setIsFullscreen(true);
        } else {
            document.exitFullscreen();
            setIsFullscreen(false);
        }
    };

    return (
        <div className="relative group">
            <video
                ref={videoRef}
                className="w-full h-full rounded-lg"
                poster={poster}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onEnded={() => setIsPlaying(false)}
            >
                <source src={src} type="video/mp4"/>
            </video>

            {/* Custom Controls */}
            <div
                className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/70 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                        <button
                            onClick={handlePlayPause}
                            className="text-white hover:text-blue-400 transition-colors"
                        >
                            {isPlaying ? <Pause size={24}/> : <Play size={24}/>}
                        </button>
                        <button
                            onClick={handleMute}
                            className="text-white hover:text-blue-400 transition-colors"
                        >
                            {isMuted ? <VolumeX size={24}/> : <Volume2 size={24}/>}
                        </button>
                    </div>
                    <button
                        onClick={handleFullscreen}
                        className="text-white hover:text-blue-400 transition-colors"
                    >
                        <Maximize2 size={24}/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;