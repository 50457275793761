// src/pages/Blog/BlogPost.jsx
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {blogService} from '../../services/blogService';
import {Card} from '../../components/ui/card';
import {Button} from '../../components/ui/button';
import {Calendar, ChevronLeft, Clock, Eye, User} from 'lucide-react';

const BlogPost = () => {
    const {slug} = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const data = await blogService.getBlogBySlug(slug);
                setPost(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [slug]);

    if (loading) {
        return (
            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="animate-pulse space-y-4">
                    <div className="h-8 bg-gray-700/50 rounded w-3/4"></div>
                    <div className="h-4 bg-gray-700/50 rounded w-1/4"></div>
                    <div className="space-y-3">
                        <div className="h-4 bg-gray-700/50 rounded"></div>
                        <div className="h-4 bg-gray-700/50 rounded"></div>
                        <div className="h-4 bg-gray-700/50 rounded w-5/6"></div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="max-w-7xl mx-auto px-4 py-8">
                <Card className="p-6 bg-red-900/30 border-red-500/50">
                    <p className="text-center text-red-400">{error}</p>
                    <Button
                        onClick={() => navigate('/blog')}
                        className="mt-4 mx-auto block bg-red-600 hover:bg-red-700"
                    >
                        Back to Blog List
                    </Button>
                </Card>
            </div>
        );
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'No date available';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const readingTime = () => {
        const wordsPerMinute = 200;
        const words = post?.content.split(/\s+/).length || 0;
        const minutes = Math.ceil(words / wordsPerMinute);
        return `${minutes} min read`;
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black">
            {/* Navigation Bar */}
            <div className="border-b border-gray-800">
                <div className="max-w-7xl mx-auto px-4 py-4">
                    <Button
                        onClick={() => navigate(-1)}
                        variant="outline"
                        className="flex items-center gap-2"
                    >
                        <ChevronLeft className="w-4 h-4"/>
                        Back
                    </Button>
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 py-8">
                <article className="max-w-4xl mx-auto">
                    {/* Category Badge */}
                    <div className="mb-6">
                        <span className="px-3 py-1 bg-blue-900/50 text-blue-400 rounded-full text-sm">
                            {post.category}
                        </span>
                    </div>

                    {/* Title */}
                    <h1 className="text-4xl font-bold text-white mb-6">
                        {post.title}
                    </h1>

                    {/* Meta Information */}
                    <div className="flex flex-wrap gap-4 text-sm text-gray-400 mb-8 pb-8 border-b border-gray-800">
                        <div className="flex items-center gap-2">
                            <User className="w-4 h-4"/>
                            <span>{post.author_name || 'Anonymous'}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <Calendar className="w-4 h-4"/>
                            <span>{formatDate(post.published_date)}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <Eye className="w-4 h-4"/>
                            <span>{post.views || 0} views</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <Clock className="w-4 h-4"/>
                            <span>{readingTime()}</span>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="prose prose-invert max-w-none">
                        <div dangerouslySetInnerHTML={{__html: post.content}}/>
                    </div>

                    {/* Tags/Keywords */}
                    {post.keywords && post.keywords.length > 0 && (
                        <div className="mt-8 pt-8 border-t border-gray-800">
                            <h2 className="text-lg font-semibold text-white mb-4">Related Topics</h2>
                            <div className="flex flex-wrap gap-2">
                                {post.keywords.map((keyword, index) => (
                                    <span
                                        key={index}
                                        className="px-3 py-1 bg-gray-800 text-gray-300 rounded-full text-sm"
                                    >
                                        {keyword}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Navigation Links */}
                    <div className="mt-8 pt-8 border-t border-gray-800 flex justify-between">
                        <Button
                            onClick={() => navigate(-1)}
                            variant="outline"
                            className="flex items-center gap-2"
                        >
                            <ChevronLeft className="w-4 h-4"/>
                            Back to Blog List
                        </Button>
                    </div>
                </article>
            </div>
        </div>
    );
};

export default BlogPost;