import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AnimatePresence, motion} from 'framer-motion';
import {Calculator, ChevronRight, LogOut, Menu, MessageSquare, Settings, TrendingUp, User, X} from 'lucide-react';
import {Card} from '../../components/ui/card';
import '../../styles/StockAI/Dashboard/Dashboard.css';
import {FaBlog} from "react-icons/fa6";

const products = [
    {
        id: 'stock-ai',
        name: 'Stock Market AI',
        description: 'Intelligent stock analysis and investment recommendations powered by AI',
        icon: TrendingUp,
        color: 'blue',
        route: '/stock-ai',
        features: [
            'Industry-specific analysis',
            'Risk-adjusted recommendations',
            'AI-powered insights',
            'Real-time market data'
        ]
    },
    {
        id: 'tax-calculator',
        name: 'Free Tax Calculator',
        description: 'Comprehensive tax calculation and planning tools for multiple states',
        icon: Calculator,
        color: 'green',
        route: '/tax-calculator',
        features: [
            'Multi-state support',
            'Accurate calculations',
            'Deduction optimization',
            'Maximum Refund Analysis'
        ]
    },
    {
        id: 'chat',
        name: 'Free AI Chat Assistant',
        description: 'Your personal AI assistant for tax and investment queries',
        icon: MessageSquare,
        color: 'yellow',
        route: '/chat',
        features: [
            'Tax advice',
            'Investment guidance',
            'Tax Document analysis',
            '24/7 availability'
        ]
    }
];

const Dashboard = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        navigate('/');
    };

    const containerAnimation = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemAnimation = {
        hidden: {opacity: 0, y: 20},
        show: {opacity: 1, y: 0}
    };

    return (
        <div className="dashboard-container min-h-screen bg-gradient-to-b from-gray-900 to-purple text-white">
            {/* Navigation Bar */}
            <nav className="fixed top-4 left-4 z-50">
                <div className="max-w-7xl mx-auto flex items-center">
                    <div></div>
                    {/* Empty div for spacing */}

                    {/* Menu Button positioned at right */}
                    <button
                        className="ml-auto p-2 rounded-full transition-colors bg-transparent hover:bg-white/5"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        {isMenuOpen ? <X size={24} className="text-white"/> : <Menu size={24} className="text-white"/>}
                    </button>
                    <div className="fixed top-4 right-4 z-50">
                        <button
                            onClick={() => navigate('/profile')}
                            className="flex items-center justify-center p-2 rounded-full transition-colors bg-transparent hover:bg-white/10"
                        >
                            <User size={24} className="text-white"/>
                        </button>
                    </div>
                </div>
            </nav>

            {/* Slide-out Menu */}
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        ref={menuRef}
                        className="fixed top-0 left-0 w-70 h-full bg-gray/50 backdrop-blur-md z-50 border-r border-gray-800/30"
                        initial={{x: '-100%'}}
                        animate={{x: 0}}
                        exit={{x: '-100%'}}
                        transition={{type: "spring", stiffness: 300, damping: 30}}
                    >
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-8">
                                <h2 className="text-xl font-bold w-20 h-8 text-white">Menu</h2>
                                <button
                                    onClick={() => setIsMenuOpen(false)}
                                    className="flex items-center justify-center w-20 h-8 rounded-full bg-transparent hover:bg-white/10 transition-colors"
                                >
                                    <X size={20} className="text-white"/>
                                </button>
                            </div>

                            <div className="space-y-2">
                                <button
                                    onClick={() => navigate('/settings')}
                                    className="w-full flex items-center gap-3 p-3 rounded-full bg-transparent hover:bg-white/5 transition-colors text-white"
                                >
                                    <Settings size={20}/>
                                    <span>Settings</span>
                                    <ChevronRight size={16} className="ml-auto"/>
                                </button>
                                <button
                                    onClick={() => navigate('/blog')}
                                    className="w-full flex items-center gap-3 p-3 rounded-full bg-transparent hover:bg-white/5 transition-colors text-white"
                                >
                                    <FaBlog size={20}/>
                                    <span>Blogs</span>
                                    <ChevronRight size={16} className="ml-auto"/>
                                </button>
                                <button
                                    onClick={handleLogout}
                                    className="w-full flex items-center gap-3 p-3 rounded-full bg-transparent hover:bg-white/5 transition-colors text-red-400"
                                >
                                    <LogOut size={20}/>
                                    <span>Logout</span>
                                    <ChevronRight size={16} className="ml-auto"/>
                                </button>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Main Content */}
            <main className="max-w-7xl mx-auto px-4 py-12">
                {/* Welcome Section */}
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    className="text-center mb-12"
                >
                    <h1 className="text-4xl font-bold mb-4">Welcome to TaxMe AI</h1>
                    <p className="text-xl text-gray-400">Choose a product below to get started</p>
                </motion.div>

                {/* Products Grid */}
                <motion.div
                    variants={containerAnimation}
                    initial="hidden"
                    animate="show"
                    className="grid md:grid-cols-3 gap-5"
                >
                    {products.map((product) => (
                        <motion.div
                            key={product.id}
                            variants={itemAnimation}
                            onClick={() => navigate(product.route)}
                            className="cursor-pointer group"
                        >
                            <Card className={`h-full p-6 bg-gray-900/50 border-gray-800/50 
                hover:border-${product.color}-500/50 transition-all duration-300
                transform hover:-translate-y-2`}
                            >
                                {/* Icon */}
                                <div className={`w-12 h-12 rounded-full bg-${product.color}-500/20 
                  flex items-center justify-center mb-4 group-hover:scale-100 transition-transform`}
                                >
                                    <product.icon className={`text-${product.color}-400`} size={22}/>
                                </div>

                                {/* Content */}
                                <h2 className="text-2xl font-bold mb-2">{product.name}</h2>
                                <p className="text-gray-400 mb-6">{product.description}</p>

                                {/* Features */}
                                <ul className="space-y-2">
                                    {product.features.map((feature, index) => (
                                        <li key={index} className="flex items-center text-gray-300">
                                            <div className={`w-1.5 h-1.5 rounded-full bg-${product.color}-400 mr-2`}/>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>

                                {/* Action Button */}
                                <button className={`mt-6 w-full py-2 px-4 rounded-full 
                  bg-${product.color}-500/20 text-${product.color}-400
                  hover:bg-${product.color}-500/30 transition-colors`}
                                >
                                    Launch {product.name}
                                </button>
                            </Card>
                        </motion.div>
                    ))}
                </motion.div>
            </main>
        </div>
    );
};

export default Dashboard;