import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {Toaster} from 'react-hot-toast';
import {GoogleOAuthProvider} from '@react-oauth/google';
import Login from './pages/Login';
import Home from './pages/Home';
import Chat from './pages/Chat';
import Settings from './pages/Settings';
import History from './pages/History';
import Profile from './pages/Profile';
import About from './pages/About';
import TermsPrivacy from './pages/TermsPrivacy';
import JoinUs from './pages/JoinUs';
import Verification from './pages/Verification';
import Signup from './pages/Signup';
import TaxCalculator from './pages/TaxCalculator';
import StockAI from './pages/StockAI';
import Terms from './pages/Legal/Terms';
import Privacy from './pages/Legal/Privacy';
import ResetPassword from './pages/Auth/ResetPassword';
import FloatingWidgets from './components/FloatingWidgets';
import TaxAnalysis from './pages/TaxCalculator/components/TaxAnalysis';
import PaymentSuccess from './pages/Payment/PaymentSuccess';
import Dashboard from './pages/Dashboard';
import BlogPost from './pages/Blog/BlogPost';
import NewBlogPost from './pages/Blog/NewBlogPost';
import PublicBlogList from './pages/Blog/BlogList';
import BlogList from './pages/Blog/BlogList';
import EditBlogPost from './pages/Blog/EditBlogPost';
import AdminBlogList from './pages/Blog/AdminBlogList';

function TaxCalculatorLayout({children}) {
    return (
        <div>
            {children}
            <FloatingWidgets/>
        </div>
    );
}

// Protected Route Component
const ProtectedRoute = ({children}) => {
    const token = localStorage.getItem('access_token');
    if (!token) {
        return <Navigate to="/login" replace/>;
    }
    return children;
};

const App = () => {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <Router>
                <Toaster
                    position="top-center"
                    toastOptions={{
                        style: {
                            background: '#1a1b4b',
                            color: '#ffffff',
                            border: '1px solid rgba(255, 255, 255, 0.1)',
                        },
                        success: {
                            iconTheme: {
                                primary: '#7B2FFF',
                                secondary: '#ffffff',
                            },
                        },
                        error: {
                            iconTheme: {
                                primary: '#ff4444',
                                secondary: '#ffffff',
                            },
                        },
                    }}
                />
                <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<Home/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/verify" element={<Verification/>}/>
                    <Route path="/legal/terms" element={<Terms/>}/>
                    <Route path="/legal/privacy" element={<Privacy/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/join-us" element={<JoinUs/>}/>
                    <Route path="/blog" element={<PublicBlogList/>}/>
                    <Route path="/blog/:slug" element={<BlogPost/>}/>

                    {/* Protected Routes */}
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <Dashboard/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/chat"
                        element={
                            <ProtectedRoute>
                                <Chat/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/settings"
                        element={
                            <ProtectedRoute>
                                <Settings/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/history"
                        element={
                            <ProtectedRoute>
                                <History/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <ProtectedRoute>
                                <Profile/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/terms-privacy"
                        element={
                            <ProtectedRoute>
                                <TermsPrivacy/>
                            </ProtectedRoute>
                        }
                    />

                    {/* Tax Calculator Routes */}
                    <Route
                        path="/tax-calculator/*"
                        element={
                            <ProtectedRoute>
                                <TaxCalculator/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/tax-calculator/analysis"
                        element={
                            <ProtectedRoute>
                                <TaxAnalysis/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/payment/success"
                        element={
                            <ProtectedRoute>
                                <PaymentSuccess/>
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/admin/blog" element={<BlogList/>}/>
                    <Route path="/admin/blog/new" element={<NewBlogPost/>}/>
                    <Route path="/admin/blog/edit/:id" element={<EditBlogPost/>}/>
                    <Route path="/super/admin/blog" element={<AdminBlogList/>}/>
                    {/* Stock AI Routes */}
                    <Route path="/stock-ai/*" element={<StockAI/>}/>

                    {/* Catch all route - redirect to home */}
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
            </Router>
        </GoogleOAuthProvider>
    );
};

export default App;