import React, {useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {motion, AnimatePresence, useDragControls} from 'framer-motion';
import {FiMinimize2, FiX} from 'react-icons/fi';
import {SiSat1} from "react-icons/si";
import Chat from '../pages/Chat';

const FloatingTaxButton = () => {
    const navigate = useNavigate();

    return (
        <motion.div
            className="fixed bottom-8 right-8 z-50"
            initial={{y: 100, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{type: "spring", stiffness: 300, damping: 30}}
        >
        </motion.div>
    );
};

const FloatingWidgets = ({persistentChatHistory, onChatHistoryUpdate}) => {
    return (
        <>
            <FloatingTaxButton/>
            <ChatWidget
                persistentChatHistory={persistentChatHistory}
                onChatHistoryUpdate={onChatHistoryUpdate}
            />
        </>
    );
};

const ChatWidget = ({persistentChatHistory, onChatHistoryUpdate}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [position, setPosition] = useState({x: 0, y: 0});
    const dragControls = useDragControls();
    const constraintsRef = useRef(null);

    // Add this to handle dragging only when not open
    const handleDrag = (event, info) => {
        if (!isOpen) {
            setPosition({
                x: position.x + info.delta.x,
                y: position.y + info.delta.y
            });
        }
    };

    // Add this to reset position when opening the widget
    const handleOpen = () => {
        if (isMinimized) {
            setIsMinimized(false);
        } else {
            setIsOpen(true);
            setPosition({x: 0, y: 0}); // Reset position when opening
        }
    };

    return (
        <>
            <div ref={constraintsRef} className="fixed inset-0 pointer-events-none z-40"/>

            <motion.div
                className="fixed bottom-8 left-8 z-50"
                drag={!isOpen} // Only allow drag when not open
                dragControls={dragControls}
                dragMomentum={false}
                dragConstraints={constraintsRef}
                dragElastic={0}
                onDrag={handleDrag}
                animate={{
                    x: isOpen ? 0 : position.x,
                    y: isOpen ? 0 : position.y
                }}
            >
                <AnimatePresence>
                    {isOpen && !isMinimized && (
                        <motion.div
                            initial={{opacity: 0, y: 20, scale: 0.95}}
                            animate={{opacity: 1, y: 0, scale: 1}}
                            exit={{opacity: 0, y: 20, scale: 0.95}}
                            className="fixed inset-0 bg-slate-900/95 z-50"
                            style={{
                                width: '100vw',
                                height: '100vh',
                                maxWidth: '100%',
                                overflow: 'hidden'
                            }}
                        >
                            <div
                                className="flex items-center justify-between p-4 border-b border-slate-800 cursor-move"
                                onPointerDown={(e) => dragControls.start(e)}
                            >
                                <div className="flex items-center gap-2">
                                    <SiSat1 size={20} className="text-blue-500"/>
                                    <h3 className="text-lg font-medium text-white">Ask Tax AI</h3>
                                </div>
                                <div className="flex gap-2">
                                    <button
                                        onClick={() => setIsMinimized(true)}
                                        className="p-2 hover:bg-slate-800 rounded-lg text-slate-400 hover:text-white"
                                    >
                                        <FiMinimize2 size={18}/>
                                    </button>
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        className="p-2 hover:bg-slate-800 rounded-lg text-slate-400 hover:text-white"
                                    >
                                        <FiX size={18}/>
                                    </button>
                                </div>
                            </div>
                            <div className="h-[calc(100%-64px)] overflow-hidden flex flex-col">
                                <Chat
                                    isWidget={true}
                                    persistentChatHistory={persistentChatHistory}
                                    onChatHistoryUpdate={onChatHistoryUpdate}
                                />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <motion.button
                    onClick={handleOpen}
                    className="bg-slate-900/90 hover:bg-slate-800/90 text-white p-4 rounded-full shadow-lg flex flex-col items-center gap-1 border border-slate-700"
                    whileHover={{scale: 1.05}}
                    whileTap={{scale: 0.95}}
                >
                    <span className="text-sm font-bold whitespace-nowrap">Ask Tax AI</span>
                    <SiSat1 size={24} className="text-white"/>
                </motion.button>
            </motion.div>
        </>
    );
};

export default FloatingWidgets;
