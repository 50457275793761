import React, {useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import {FiArrowRight, FiCalendar, FiHelpCircle, FiMapPin, FiSearch} from 'react-icons/fi';

import Layout from './shared/Layout';
import TaxYearSelector from './TaxYearSelector';
import '../../../styles/TaxCalculator/StateSelection.css';

const states = [
    {
        code: 'AL',
        name: 'Alabama',
        description: 'Progressive tax system with standard deductions',
        features: [
            'Three progressive tax brackets',
            'Standard deduction based on income',
            'Federal income tax deduction',
            'Retirement income partially exempt'
        ],
        available: true
    },
    {
        code: 'AK',
        name: 'Alaska',
        description: 'No state income tax',
        features: [
            'No state income tax',
            'Permanent Fund Dividend for residents',
            'No state deductions needed',
            'No state credits required'
        ],
        available: true
    },
    {
        code: 'AZ',
        name: 'Arizona',
        description: 'Flat tax rate system',
        features: [
            'Flat tax rate of 2.5%',
            'Standard deduction follows federal',
            'Tax credits for families and education',
            'Retirement income partially exempt'
        ],
        available: true
    },
    {
        code: 'AR',
        name: 'Arkansas',
        description: 'Progressive tax system with six brackets',
        features: [
            'Six progressive tax brackets',
            'Standard deduction follows federal',
            'Retirement income exemptions',
            'Property tax relief programs'
        ],
        available: true
    },
    {
        code: 'CA',
        name: 'California',
        description: 'Progressive tax system with nine brackets',
        features: [
            'Nine progressive tax brackets',
            'State-specific credits available',
            'Social security benefits exempt',
            'Home ownership deductions'
        ],
        available: true
    },
    {
        code: 'CO',
        name: 'Colorado',
        description: 'Flat tax rate system with unique deductions',
        features: [
            'Single flat tax rate of 4.4%',
            'Follows federal deductions',
            'State earned income tax credit',
            'Child care contribution credit'
        ],
        available: true
    },
    {
        code: 'CT',
        name: 'Connecticut',
        description: 'Progressive tax system with multiple brackets',
        features: [
            'Seven progressive tax brackets',
            'Credits for low-income taxpayers',
            'Retirement income exemptions',
            'Property tax credit'
        ],
        available: true
    },
    {
        code: 'DE',
        name: 'Delaware',
        description: 'Progressive tax system with six brackets',
        features: [
            'Six progressive tax brackets',
            'Low sales tax rate',
            'Retirement income exclusions',
            'Standard deduction follows federal'
        ],
        available: true
    },
    {
        code: 'DC',
        name: 'District of Columbia',
        description: 'Progressive tax system tailored for the capital region',
        features: [
            'Six progressive tax brackets',
            'High standard deduction and exemptions',
            'Credits for low-income residents',
            'No sales tax on groceries or prescription drugs'
        ],
        available: true
    },
    {
        code: 'FL',
        name: 'Florida',
        description: 'No state income tax',
        features: [
            'Zero Income Tax',
            'State-specific savings'
        ],
        available: true
    },
    {
        code: 'GA',
        name: 'Georgia',
        description: 'Progressive tax system with six brackets',
        features: [
            'Six progressive tax brackets',
            'Retirement income exclusions',
            'Multiple tax credits available',
            'Standard deduction allowed'
        ],
        available: true
    },
    {
        code: 'HI',
        name: 'Hawaii',
        description: 'Progressive tax system with high rates',
        features: [
            'Twelve progressive tax brackets',
            'Generous personal exemptions',
            'Credits for low-income taxpayers',
            'Retirement income partially exempt'
        ],
        available: true
    },
    {
        code: 'ID',
        name: 'Idaho',
        description: 'Progressive tax system with nine brackets',
        features: [
            'Nine progressive tax brackets',
            'Standard deduction matches federal',
            'Retirement benefits partially exempt',
            'Credits for low-income households'
        ],
        available: true
    },
    {
        code: 'IL',
        name: 'Illinois',
        description: 'Flat tax rate system',
        features: [
            'Flat tax rate of 4.95%',
            'No standard deduction',
            'Tax credits for education',
            'Retirement income exempt'
        ],
        available: true
    },
    {
        code: 'IN',
        name: 'Indiana',
        description: 'Flat tax rate system with county taxes',
        features: [
            'Flat tax rate of 3.23%',
            'Additional county-level income tax',
            'Credits for low-income residents',
            'Retirement income partially exempt'
        ],
        available: true
    },
    {
        code: 'IA',
        name: 'Iowa',
        description: 'Progressive tax system with nine brackets',
        features: [
            'Tax brackets range from 0.33% to 8.53%',
            'Deduction for federal income tax payments',
            'Credits for earned income and child care expenses',
            'Retirement income exclusion for seniors'
        ],
        available: true
    },
    {
        code: 'KS',
        name: 'Kansas',
        description: 'Progressive tax system with three brackets',
        features: [
            'Tax brackets range from 3.10% to 5.70%',
            'Standard deduction available',
            'No local income tax',
            'Exemptions for Social Security benefits'
        ],
        available: true
    },
    {
        code: 'KY',
        name: 'Kentucky',
        description: 'Progressive tax system with tax credits',
        features: [
            'Six progressive tax brackets',
            'Personal and dependent credits',
            'Retirement income exclusion',
            'Standard deduction available'
        ],
        available: true
    },
    {
        code: 'LA',
        name: 'Louisiana',
        description: 'Progressive tax system with federal income deduction',
        features: [
            'Three progressive tax brackets',
            'Deduction for federal income tax',
            'Earned income tax credit available',
            'Standard deduction varies by status'
        ],
        available: true
    },
    {
        code: 'ME',
        name: 'Maine',
        description: 'Progressive tax system with tax credits',
        features: [
            'Three progressive tax brackets',
            'Standard deduction follows federal',
            'Credits for property tax fairness',
            'Child care tax credit available'
        ],
        available: true
    },
    {
        code: 'MA',
        name: 'Massachusetts',
        description: 'Flat tax rate system',
        features: [
            'Single flat tax rate of 5%',
            'Standard deduction available',
            'Limited tax credits',
            'No progressive brackets'
        ],
        available: true
    },
    {
        code: 'MI',
        name: 'Michigan',
        description: 'Flat tax rate system',
        features: [
            'Single flat tax rate of 4.25%',
            'Personal exemption available',
            'Credits for low-income households',
            'Retirement income exclusions'
        ],
        available: true
    },
    {
        code: 'MN',
        name: 'Minnesota',
        description: 'Progressive tax system with multiple credits',
        features: [
            'Four progressive tax brackets',
            'Credits for property tax refunds',
            'Child care expense credit',
            'Deduction for education expenses'
        ],
        available: true
    },
    {
        code: 'MS',
        name: 'Mississippi',
        description: 'Progressive tax system with exemptions',
        features: [
            'Three progressive tax brackets',
            'Exemptions for retirement income',
            'No inheritance tax',
            'Standard deduction available'
        ],
        available: true
    },
    {
        code: 'MO',
        name: 'Missouri',
        description: 'Progressive tax system with deductions',
        features: [
            'Ten progressive tax brackets',
            'Federal income tax deduction',
            'Credits for property tax',
            'Standard deduction follows federal'
        ],
        available: true
    },
    {
        code: 'MT',
        name: 'Montana',
        description: 'Progressive tax system with low rates',
        features: [
            'Seven progressive tax brackets',
            'Deductions for federal taxes',
            'No sales tax in the state',
            'Credits for energy conservation'
        ],
        available: true
    },
    {
        code: 'NE',
        name: 'Nebraska',
        description: 'Progressive tax system with multiple brackets',
        features: [
            'Four progressive tax brackets',
            'Credits for child and dependent care',
            'Property tax relief program',
            'Standard deduction available'
        ],
        available: true
    },
    {
        code: 'NC',
        name: 'North Carolina',
        description: 'Flat tax rate',
        features: [
            'Deductions for federal taxes',
            'Flat tax rate for income',
            'Follows federal standard deduction',
            'State credits available'
        ],
        available: true
    },
    {
        code: 'NV',
        name: 'Nevada',
        description: 'No state income tax',
        features: [
            'No state income tax',
            'Federal tax calculation only',
            'No state deductions needed',
            'No state credits required'
        ],
        available: true
    },
    {
        code: 'NH',
        name: 'New Hampshire',
        description: 'No tax on earned income, tax on interest and dividends',
        features: [
            'No tax on wages or salaries',
            'Tax on interest and dividend income',
            'Credits for low-income filers',
            'No state sales tax'
        ],
        available: true
    },
    {
        code: 'NJ',
        name: 'New Jersey',
        description: 'Progressive tax system with property tax credits',
        features: [
            'Six progressive tax brackets',
            'Property tax deduction',
            'Earned income tax credit',
            'Retirement income exclusions'
        ],
        available: true
    },
    {
        code: 'NM',
        name: 'New Mexico',
        description: 'Progressive tax system with exemptions',
        features: [
            'Four progressive tax brackets',
            'Exemptions for Social Security income',
            'Low-income tax rebate',
            'Standard deduction available'
        ],
        available: true
    },
    {
        code: 'ND',
        name: 'North Dakota',
        description: 'Progressive tax system with flat-like rates',
        features: [
            'Five progressive tax brackets',
            'Lower tax rates than most states',
            'Credits for property tax relief',
            'Follows federal standard deduction'
        ],
        available: true
    },
    {
        code: 'NY',
        name: 'New York',
        description: 'Progressive tax system with nine brackets',
        features: [
            'Nine progressive tax brackets',
            'Standard deduction varies by filing status',
            'Credits for child and dependent care',
            'Exemptions for Social Security income'
        ],
        available: true
    },
    {
        code: 'OH',
        name: 'Ohio',
        description: 'Progressive tax system with local taxes',
        features: [
            'Nine progressive tax brackets',
            'Local income taxes vary by city',
            'Credits for retirement income',
            'Earned income tax credit available'
        ],
        available: true
    },
    {
        code: 'OK',
        name: 'Oklahoma',
        description: 'Progressive tax system with rebates',
        features: [
            'Six progressive tax brackets',
            'Sales tax relief credit',
            'Property tax credit for seniors',
            'Standard deduction follows federal'
        ],
        available: true
    },
    {
        code: 'OR',
        name: 'Oregon',
        description: 'Progressive tax system with high rates',
        features: [
            'Four progressive tax brackets',
            'Credits for retirement income',
            'Earned income tax credit',
            'No sales tax in the state'
        ],
        available: true
    },
    {
        code: 'PA',
        name: 'Pennsylvania',
        description: 'Pennsylvania uses a flat tax rate of 3.07% with tax forgiveness program.',
        features: [
            'Single flat tax rate of 3.07%',
            'No standard deduction',
            'Tax forgiveness program',
            'Retirement income exclusion'
        ],
        available: true
    },
    {
        code: 'RI',
        name: 'Rhode Island',
        description: 'Progressive tax system with flat-like structure',
        features: [
            'Three progressive tax brackets',
            'State-specific tax credits',
            'Credits for child and dependent care',
            'Standard deduction follows federal'
        ],
        available: true
    },
    {
        code: 'SC',
        name: 'South Carolina',
        description: 'Progressive tax system with retirement exclusions',
        features: [
            'Six progressive tax brackets',
            'Exclusions for Social Security benefits',
            'Property tax relief for seniors',
            'Standard deduction available'
        ],
        available: true
    },

    {
        code: 'SD',
        name: 'South Dakota',
        description: 'No state income tax',
        features: [
            'No state income tax',
            'Federal tax calculation only',
            'No state deductions needed',
            'No state credits required'
        ],
        available: true
    },
    {
        code: 'TN',
        name: 'Tennessee',
        description: 'No tax on earned income, tax on interest and dividends',
        features: [
            'No tax on wages or salaries',
            'Tax on interest and dividend income (phased out in 2021)',
            'Retirement income exemptions',
            'No state property tax'
        ],
        available: true
    },
    {
        code: 'TX',
        name: 'Texas',
        description: 'No state income tax',
        features: [
            'No state income tax',
            'Federal tax calculation only',
            'No state deductions needed',
            'No state credits required'
        ],
        available: true
    },
    {
        code: 'UT',
        name: 'Utah',
        description: 'Flat tax rate system with unique deductions',
        features: [
            'Single flat tax rate of 4.85%',
            'State earned income tax credit',
            'Retirement tax credit',
            'Follows federal standard deduction'
        ],
        available: true
    },
    {
        code: 'VT',
        name: 'Vermont',
        description: 'Progressive tax system with high rates',
        features: [
            'Four progressive tax brackets',
            'Credits for child and dependent care',
            'Social Security income exclusions',
            'Standard deduction follows federal'
        ],
        available: true
    },
    {
        code: 'VA',
        name: 'Virginia',
        description: 'Progressive tax system with limited brackets',
        features: [
            'Four progressive tax brackets',
            'Credits for low-income taxpayers',
            'Property tax relief for seniors',
            'Standard deduction allowed'
        ],
        available: true
    },
    {
        code: 'WA',
        name: 'Washington',
        description: 'No state income tax',
        features: [
            'No state income tax',
            'Federal tax calculation only',
            'No state deductions needed',
            'No state credits required'
        ],
        available: true
    },
    {
        code: 'WV',
        name: 'West Virginia',
        description: 'Progressive tax system with five brackets',
        features: [
            'Five progressive tax brackets',
            'Credits for low-income households',
            'Property tax relief programs',
            'Standard deduction follows federal'
        ],
        available: true
    },
    {
        code: 'WI',
        name: 'Wisconsin',
        description: 'Progressive tax system with four brackets',
        features: [
            'Four progressive tax brackets',
            'Property tax credits available',
            'Earned income tax credit',
            'Standard deduction based on income'
        ],
        available: true
    },
    {
        code: 'WY',
        name: 'Wyoming',
        description: 'No state income tax',
        features: [
            'No state income tax',
            'Federal tax calculation only',
            'No state deductions needed',
            'No state credits required'
        ],
        available: true
    }

];

const StateSelector = ({selectedState, onChange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const filteredStates = states.filter(state =>
        state.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        state.code.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const selectedStateName = selectedState ?
        states.find(s => s.code === selectedState)?.name :
        'Select State';

    return (
        <div className="w-full">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full flex items-center justify-between p-4 bg-slate-800/50 hover:bg-slate-700/50 rounded-full"
            >
                <div>
                    <span className="text-sm text-gray-400">State</span>
                    <div className="text-lg font-medium text-white">{selectedStateName}</div>
                </div>
                <FiArrowRight className={`text-gray-400 transform transition-transform 
                               ${isOpen ? 'rotate-90' : ''}`}/>
            </button>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        className="fixed inset-0 z-50 bg-gray-900/95 backdrop-blur-sm"
                    >
                        <div className="h-full flex flex-col">
                            <div className="p-4 bg-slate-800/50">
                                <div className="relative max-w-3xl mx-auto">
                                    <FiSearch
                                        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"/>
                                    <input
                                        type="text"
                                        placeholder="Search states..."
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="w-full pl-12 pr-4 py-3 bg-slate-700/50 rounded-lg text-white"
                                    />
                                </div>
                            </div>

                            <div className="flex-1 overflow-y-auto p-4">
                                <div className="max-w-3xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {filteredStates.map((state) => (
                                        <motion.button
                                            key={state.code}
                                            onClick={() => {
                                                onChange(state.code);
                                                setIsOpen(false);
                                            }}
                                            className={`w-full text-left p-4 rounded-full transition-colors
                               ${state.code === selectedState
                                                ? 'bg-blue-500/20 text-blue-100'
                                                : 'bg-slate-800/50 hover:bg-slate-700/50 text-white'}`}
                                            whileHover={{scale: 1.02}}
                                        >
                                            <div className="font-medium">{state.name}</div>
                                            <div className="text-sm text-gray-400 mt-1">{state.description}</div>
                                        </motion.button>
                                    ))}
                                </div>
                            </div>

                            <div className="p-4 bg-slate-800/50">
                                <div className="max-w-3xl mx-auto">
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        className="w-full p-3 bg-slate-700/50 hover:bg-slate-600/50 rounded-full text-gray-300"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const StateFeatures = ({stateInfo}) => {
    if (!stateInfo) return null;

    return (
        <div className="w-full bg-slate-800/50 rounded-lg p-4">
            <h4 className="text-lg font-medium text-white mb-4">
                {stateInfo.name} Features
            </h4>
            <div className="grid gap-3">
                {stateInfo.features.map((feature, index) => (
                    <div key={index} className="flex items-center gap-3 text-gray-300">
                        <div className="w-2 h-2 rounded-full bg-blue-500"/>
                        <span>{feature}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const StateSelection = ({formData, setFormData}) => {
    const navigate = useNavigate();

    const handleStateSelect = (stateCode) => {
        setFormData(prev => ({...prev, state: stateCode}));
    };

    const handleContinue = () => {
        if (formData.state && formData.year) {
            navigate(`${formData.state.toLowerCase()}/basic-info`, {replace: true});
        }
    };

    const selectedStateInfo = formData.state ? states.find(s => s.code === formData.state) : null;
    const isValid = formData.state && formData.year;

    return (
        <Layout>
            <div className="min-h-screen bg-transparent-to-b from-gray-900 via-gray-900/95 to-gray-900/90">
                {/* Header Section - Full Width */}
                <div className="w-full bg-transparent backdrop-blur-0px py-8">
                    <div className="max-w-7xl mx-auto px-4">
                        <h2 className="text-3xl font-bold text-white items-center">Calculate Your Taxes</h2>
                        <p className="text-gray-400 mt-2">Select tax year and state to begin</p>
                    </div>
                </div>

                {/* Main Content */}
                <div className="max-w-7xl mx-auto px-4 py-8">
                    <div className="grid md:grid-cols-2 gap-8">
                        {/* Left Column - Year and State Selection */}
                        <div className="space-y-8">
                            {/* Year Selection */}
                            <div>
                                <div className="flex items-center gap-3 mb-4">
                                    <FiCalendar className="text-blue-400" size={24}/>
                                    <h3 className="text-xl font-medium text-white">Tax Year</h3>
                                </div>
                                <TaxYearSelector
                                    selectedYear={formData.year}
                                    onChange={(year) => setFormData(prev => ({...prev, year}))}
                                />
                            </div>

                            {/* State Selection */}
                            <div>
                                <div className="flex items-center gap-3 mb-4">
                                    <FiMapPin className="text-blue-400" size={24}/>
                                    <h3 className="text-xl font-medium text-white">Select State</h3>
                                </div>
                                <StateSelector
                                    selectedState={formData.state}
                                    onChange={handleStateSelect}
                                />
                            </div>
                        </div>

                        {/* Right Column - Features and Help */}
                        <div className="space-y-8">
                            {selectedStateInfo && <StateFeatures stateInfo={selectedStateInfo}/>}

                            {/* Help Section */}
                            <div className="bg-gray-600/50 rounded-lg p-8">
                                <div className="flex gap-3">
                                    <FiHelpCircle className="text-blue-400 mt-1" size={24}/>
                                    <div>
                                        <h4 className="text-lg font-medium text-blue-300">Need Help?</h4>
                                        <p className="text-blue-200/80 mt-2">
                                            Select your tax year and state to begin. The calculator will guide you
                                            through income, deductions, and credits to estimate your total tax
                                            liability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bottom Action Bar */}
                <div className="sticky bottom-6 mt-8 inset-x-0 bg-transparent backdrop-blur-0px rounded-full">
                    <div className="max-w-7xl mx-auto px-4 py-4">
                        <motion.button
                            onClick={handleContinue}
                            disabled={!isValid}
                            className={`w-full md:w-auto px-8 py-4 rounded-full text-center font-medium
                         ${isValid
                                ? 'bg-blue-600 hover:bg-blue-700 text-white'
                                : 'bg-slate-800/50 text-gray-500 cursor-not-allowed'}`}
                            whileHover={isValid ? {scale: 1.02} : {}}
                            whileTap={isValid ? {scale: 0.98} : {}}
                        >
                            Continue to Tax Calculator
                        </motion.button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default StateSelection;
