import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {FiArrowLeft, FiCheck, FiEdit, FiMail, FiPhone, FiSave, FiShield, FiUser, FiX} from 'react-icons/fi';
import '../styles/Profile.css';
import API_URL, {ENDPOINTS} from '../utils/config/api';

const GalaxyBackground = () => {
    useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';

            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;

                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);

                starsContainer.appendChild(star);
            }

            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background"/>
            <div className="galaxy-spiral"/>
        </>
    );
};

const Profile = () => {
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [message, setMessage] = useState({text: '', type: ''});
    const [showPasswordChange, setShowPasswordChange] = useState(false);

    const [profileData, setProfileData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        username: ''
    });

    const [passwordData, setPasswordData] = useState({
        current_password: '',
        new_password: '',
        confirm_password: ''
    });

    useEffect(() => {
        fetchProfileData();
    }, []);

    const fetchProfileData = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${API_URL}${ENDPOINTS.PROFILE}`, {
                headers: {Authorization: `Bearer ${token}`}
            });
            setProfileData(response.data);
        } catch (error) {
            setMessage({
                text: 'Failed to load profile data',
                type: 'error'
            });
        }
    };

    const handleProfileUpdate = async () => {
        try {
            const token = localStorage.getItem('access_token');
            await axios.put(`${API_URL}${ENDPOINTS.PROFILE}`, profileData, {
                headers: {Authorization: `Bearer ${token}`}
            });
            setMessage({
                text: 'Profile updated successfully',
                type: 'success'
            });
            setIsEditing(false);
        } catch (error) {
            setMessage({
                text: error.response?.data?.detail || 'Failed to update profile',
                type: 'error'
            });
        }
    };

    const handlePasswordUpdate = async () => {
        if (passwordData.new_password !== passwordData.confirm_password) {
            setMessage({
                text: 'New passwords do not match',
                type: 'error'
            });
            return;
        }

        try {
            const token = localStorage.getItem('access_token');
            const formData = new FormData();
            formData.append('current_password', passwordData.current_password);
            formData.append('new_password', passwordData.new_password);

            await axios.put(
                `${API_URL}${ENDPOINTS.PROFILE}/security`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            setMessage({
                text: 'Password updated successfully',
                type: 'success'
            });
            setPasswordData({
                current_password: '',
                new_password: '',
                confirm_password: ''
            });
            setShowPasswordChange(false);
        } catch (error) {
            setMessage({
                text: error.response?.data?.detail || 'Failed to update password',
                type: 'error'
            });
        }
    };

    return (
        <div className="w-screen min-h-screen relative bg-gradient-to-b from-gray-900 to-black">
            <GalaxyBackground/>

            <motion.button
                className="back-button"
                onClick={() => navigate('/dashboard')}
                whileHover={{x: -5}}
            >
                <FiArrowLeft size={20}/>
                <span>Dashboard</span>
            </motion.button>

            <div className="w-[90%] min-h-[90vh] mx-auto pt-16 pb-8">
                <div className="text-center mb-8">
                    <h1 className="text-3xl font-bold text-white">Profile Settings</h1>
                </div>

                <div className="w-full space-y-8">
                    {/* Personal Information Card */}
                    <div
                        className="w-full bg-gray-900/70 backdrop-blur-md rounded-xl p-8 shadow-xl border border-gray-800/50">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-xl font-semibold text-white flex items-center gap-2">
                                <FiUser/>
                                Personal Information
                            </h2>
                            <button
                                className="px-4 py-2 flex items-center gap-2 rounded-full bg-transparent hover:transparent transition-colors"
                                onClick={() => setIsEditing(!isEditing)}
                            >
                                {isEditing ? (
                                    <><FiX/> Cancel</>
                                ) : (
                                    <><FiEdit/> Edit</>
                                )}
                            </button>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="space-y-2">
                                <label className="text-gray-400">First Name</label>
                                {isEditing ? (
                                    <input
                                        type="text"
                                        value={profileData.first_name || ''}
                                        onChange={e => setProfileData({
                                            ...profileData,
                                            first_name: e.target.value
                                        })}
                                        className="w-full px-4 py-2 bg-gray-800/50 rounded-lg border border-gray-700 text-white"
                                    />
                                ) : (
                                    <p className="text-white">{profileData.first_name || 'Not set'}</p>
                                )}
                            </div>

                            <div className="space-y-2">
                                <label className="text-gray-400">Last Name</label>
                                {isEditing ? (
                                    <input
                                        type="text"
                                        value={profileData.last_name || ''}
                                        onChange={e => setProfileData({
                                            ...profileData,
                                            last_name: e.target.value
                                        })}
                                        className="w-full px-4 py-2 bg-gray-800/50 rounded-lg border border-gray-700 text-white"
                                    />
                                ) : (
                                    <p className="text-white">{profileData.last_name || 'Not set'}</p>
                                )}
                            </div>

                            <div className="space-y-2">
                                <label className="text-gray-400 flex items-center gap-2">
                                    <FiMail/> Email
                                </label>
                                <p className="text-white">{profileData.email}</p>
                            </div>

                            <div className="space-y-2">
                                <label className="text-gray-400 flex items-center gap-2">
                                    <FiPhone/> Phone
                                </label>
                                {isEditing ? (
                                    <input
                                        type="tel"
                                        value={profileData.phone_number || ''}
                                        onChange={e => setProfileData({
                                            ...profileData,
                                            phone_number: e.target.value
                                        })}
                                        className="w-full px-4 py-2 bg-gray-800/50 rounded-lg border border-gray-700 text-white"
                                    />
                                ) : (
                                    <p className="text-white">{profileData.phone_number || 'Not set'}</p>
                                )}
                            </div>
                        </div>

                        {isEditing && (
                            <div className="flex justify-end mt-6">
                                <button
                                    className="px-6 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg flex items-center gap-2 transition-colors"
                                    onClick={handleProfileUpdate}
                                >
                                    <FiSave/> Save Changes
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Security Settings Card */}
                    <div
                        className="w-full bg-gray-900/70 backdrop-blur-md rounded-xl p-8 shadow-xl border border-gray-800/50">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-xl font-semibold text-white flex items-center gap-2">
                                <FiShield/>
                                Security Settings
                            </h2>
                        </div>

                        {!showPasswordChange ? (
                            <button
                                className="px-6 py-2 bg-white/10 hover:bg-white/20 rounded-lg transition-colors"
                                onClick={() => setShowPasswordChange(true)}
                            >
                                Change Password
                            </button>
                        ) : (
                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <label className="text-gray-400">Current Password</label>
                                    <input
                                        type="password"
                                        value={passwordData.current_password}
                                        onChange={e => setPasswordData({
                                            ...passwordData,
                                            current_password: e.target.value
                                        })}
                                        className="w-full px-4 py-2 bg-gray-800/50 rounded-lg border border-gray-700 text-white"
                                    />
                                </div>

                                <div className="space-y-2">
                                    <label className="text-gray-400">New Password</label>
                                    <input
                                        type="password"
                                        value={passwordData.new_password}
                                        onChange={e => setPasswordData({
                                            ...passwordData,
                                            new_password: e.target.value
                                        })}
                                        className="w-full px-4 py-2 bg-gray-800/50 rounded-lg border border-gray-700 text-white"
                                    />
                                </div>

                                <div className="space-y-2">
                                    <label className="text-gray-400">Confirm New Password</label>
                                    <input
                                        type="password"
                                        value={passwordData.confirm_password}
                                        onChange={e => setPasswordData({
                                            ...passwordData,
                                            confirm_password: e.target.value
                                        })}
                                        className="w-full px-4 py-2 bg-gray-800/50 rounded-lg border border-gray-700 text-white"
                                    />
                                </div>

                                <div className="flex justify-end gap-4">
                                    <button
                                        className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg flex items-center gap-2 transition-colors"
                                        onClick={() => {
                                            setShowPasswordChange(false);
                                            setPasswordData({
                                                current_password: '',
                                                new_password: '',
                                                confirm_password: ''
                                            });
                                        }}
                                    >
                                        <FiX/> Cancel
                                    </button>
                                    <button
                                        className="px-6 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg flex items-center gap-2 transition-colors"
                                        onClick={handlePasswordUpdate}
                                    >
                                        <FiCheck/> Update Password
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {message.text && (
                    <motion.div
                        className={`fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-3 rounded-lg ${
                            message.type === 'success' ? 'bg-green-900/80' : 'bg-red-900/80'
                        } backdrop-blur-md text-white z-50`}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 50}}
                    >
                        {message.text}
                    </motion.div>
                )}
            </div>
        </div>
    );
};

export default Profile;