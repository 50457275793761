// src/components/TaxAnalysis/sections/CreditReview.jsx
import React from 'react';
import {Card} from '../../../components/ui/card';
import {motion} from 'framer-motion';
import {
    CreditCard,
    AlertCircle,
    CheckCircle,
    Calculator,
    DollarSign,
    ArrowRight,
    Clock,
    HelpCircle,
    ShieldCheck
} from 'lucide-react';

export const CreditReview = ({data}) => {
    const formatCurrency = (amount) => {
        if (!amount) return '$0.00';
        const cleanAmount = typeof amount === 'string' ?
            amount.replace(/[^0-9.]/g, '') :
            amount.toString();
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(parseFloat(cleanAmount || 0));
    };

    const parseCredits = (content) => {
        if (!content) return [];
        const contentStr = content.toString();

        const creditTypes = [
            'Child tax credit',
            'Education credits',
            'Retirement contributions',
            'HSA contributions',
            'Energy credits'
        ];

        const credits = [];

        creditTypes.forEach(creditType => {
            const creditSection = contentStr.match(
                new RegExp(`${creditType}[\\s\\S]*?(?=\\n\\n|\\*\\*|$)`, 'i')
            )?.[0];

            if (creditSection) {
                const eligibility = creditSection.match(/Eligibility[^:]*:\s*([^.]+[.]?)/i)?.[1] || '';
                const calculation = creditSection.match(/Calculate[^:]*:\s*([^.]+[.]?)/i)?.[1] || '';
                const potentialCredit = creditSection.match(/Potential (credit|benefit|savings)[^:]*:\s*\$?([\d,.]+)/i)?.[2] || '0';
                const requirements = creditSection.match(/Requirements?[^:]*:\s*([\s\S]+?)(?=\n\n|\*\*|$)/i)?.[1] || '';
                const recommendation = creditSection.match(/Recommendation[^:]*:\s*([^.]+[.]?)/i)?.[1] || '';
                const scenario = creditSection.match(/scenario[^:]*:\s*([^.]+[.]?)/i)?.[1] || '';

                credits.push({
                    type: creditType,
                    eligibility,
                    calculation,
                    potentialCredit,
                    requirements: requirements.split('-').filter(Boolean).map(req => req.trim()),
                    recommendation,
                    scenario
                });
            }
        });

        return credits;
    };

    const credits = parseCredits(data);
    const totalPotentialCredits = credits.reduce((sum, credit) =>
        sum + parseFloat(credit.potentialCredit.replace(/,/g, '') || 0), 0
    );

    return (
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
        >
            <Card className="p-6 bg-gradient-to-r from-purple-900/30 to-blue-900/30">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold text-white flex items-center">
                        <CreditCard className="w-8 h-8 mr-2 text-purple-400"/>
                        Additional Tax Credits Review
                    </h2>
                    <ShieldCheck className="w-6 h-6 text-purple-400"/>
                </div>

                {/* Total Potential Credits Summary */}
                {totalPotentialCredits > 0 && (
                    <motion.div
                        initial={{y: -20, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        className="bg-green-900/20 p-4 rounded-lg border border-green-500/30 mb-6"
                    >
                        <div className="flex justify-between items-center">
                            <span className="text-green-400">Total Potential Credits:</span>
                            <span className="text-2xl font-bold text-green-400">
                                {formatCurrency(totalPotentialCredits)}
                            </span>
                        </div>
                    </motion.div>
                )}

                <div className="space-y-6">
                    {credits.map((credit, index) => (
                        <motion.div
                            key={index}
                            initial={{x: -20, opacity: 0}}
                            animate={{x: 0, opacity: 1}}
                            transition={{delay: index * 0.1}}
                            className="bg-gray-900/50 p-4 rounded-lg border border-purple-500/30"
                        >
                            {/* Credit Header */}
                            <div className="flex justify-between items-start mb-4">
                                <h3 className="text-lg font-semibold text-white flex items-center">
                                    {credit.type}
                                </h3>
                                {parseFloat(credit.potentialCredit) > 0 && (
                                    <div className="bg-green-900/30 px-3 py-1 rounded-full">
                                        <span className="text-green-400 font-bold flex items-center">
                                            <DollarSign className="w-4 h-4 mr-1"/>
                                            {formatCurrency(credit.potentialCredit)}
                                        </span>
                                    </div>
                                )}
                            </div>

                            {/* Eligibility */}
                            <div className="mb-3 flex items-start">
                                {credit.eligibility.toLowerCase().includes('not') ? (
                                    <AlertCircle className="w-5 h-5 text-yellow-500 mr-2 mt-1"/>
                                ) : (
                                    <CheckCircle className="w-5 h-5 text-green-500 mr-2 mt-1"/>
                                )}
                                <div>
                                    <p className="text-sm text-gray-400">Eligibility:</p>
                                    <p className="text-gray-300">{credit.eligibility}</p>
                                </div>
                            </div>

                            {/* Calculation */}
                            {credit.calculation && (
                                <div className="mb-3 bg-yellow-900/20 p-3 rounded border border-yellow-500/30">
                                    <p className="text-yellow-300 flex items-center">
                                        <Calculator className="w-4 h-4 mr-2"/>
                                        {credit.calculation}
                                    </p>
                                </div>
                            )}

                            {/* Requirements */}
                            {credit.requirements.length > 0 && (
                                <div className="mb-3 bg-blue-900/20 p-3 rounded border border-blue-500/30">
                                    <p className="text-blue-400 text-sm mb-2">Requirements:</p>
                                    <div className="space-y-2">
                                        {credit.requirements.map((req, reqIndex) => (
                                            <div
                                                key={reqIndex}
                                                className="flex items-start"
                                            >
                                                <ArrowRight className="w-4 h-4 text-blue-400 mr-2 mt-1"/>
                                                <p className="text-gray-300">{req}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Recommendation */}
                            {credit.recommendation && (
                                <div className="mb-3 bg-green-900/20 p-3 rounded border border-green-500/30">
                                    <p className="text-green-400 text-sm">Recommendation:</p>
                                    <p className="text-green-300">{credit.recommendation}</p>
                                </div>
                            )}

                            {/* Example Scenario */}
                            {credit.scenario && (
                                <div className="mt-3 bg-gray-900/50 p-3 rounded border border-gray-700">
                                    <p className="text-gray-400 flex items-center text-sm">
                                        <HelpCircle className="w-4 h-4 mr-2"/>
                                        Example: {credit.scenario}
                                    </p>
                                </div>
                            )}
                        </motion.div>
                    ))}

                    {credits.length === 0 && (
                        <div className="text-center py-8">
                            <AlertCircle className="w-12 h-12 text-gray-500 mx-auto mb-3"/>
                            <p className="text-gray-400">No additional tax credits identified for your current
                                situation.</p>
                        </div>
                    )}
                </div>
            </Card>
        </motion.div>
    );
};