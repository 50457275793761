import React, {useCallback, useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {
    Activity,
    AlertTriangle,
    ArrowDownRight,
    ArrowRight,
    ArrowUpRight,
    BarChart3,
    Building,
    CheckCircle,
    ChevronDown,
    ChevronUp,
    DollarSign,
    Download,
    Globe,
    Info,
    LockKeyhole,
    Target,
    TrendingDown,
    TrendingUp,
    Users
} from 'lucide-react'; // Add these to your existing lucide-react imports
import {Card} from '../../../../components/ui/card';
import {Button} from '../../../../components/ui/button';
import {TooltipProvider,} from '../../../../components/ui/tooltip';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {stockAIService} from '../../../../utils/services/stockAIService';
import '../../../../styles/StockAI/StockResults.css';
import {useNavigate} from 'react-router-dom';
import {SiSat1} from "react-icons/si";

const containerVariants = {
    hidden: {opacity: 0},
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
            duration: 0.5
        }
    }
};

const itemVariants = {
    hidden: {opacity: 0, y: 20},
    visible: {
        opacity: 1,
        y: 0,
        transition: {duration: 0.5, ease: "easeOut"}
    }
};

// Inside your StockResults component, add these states
const StockResults = ({industry, riskLevel, setLoading}) => {
    const [analysisData, setAnalysisData] = useState(null);
    const [error, setError] = useState(null);
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [showSubscriptionPrompt, setShowSubscriptionPrompt] = useState(false);  // Add this
    const [showBackWarning, setShowBackWarning] = useState(false);
    const navigate = useNavigate();

    // Add the handleBackClick function
    const handleBackClick = () => {
        setShowBackWarning(true);
    };


    const handleSubscribe = async (analysisId) => {
        try {
            // Store current analysis state with the exact format needed after payment
            sessionStorage.setItem('stockAnalysisReturn', JSON.stringify({
                industry: industry,
                riskLevel: riskLevel,
                analysisId: analysisId,
                fromPayment: true  // Add this flag to handle post-payment state
            }));

            setIsSubscribing(true);
            const response = await stockAIService.createStockSubscription(analysisId);
            window.location.href = response.payment_url;
        } catch (err) {
            setError('Failed to initiate subscription: ' + err.message);
            setIsSubscribing(false);
        }
    };

    const fetchAnalysis = useCallback(async () => {
        if (!industry || !riskLevel) return;

        try {
            setLoading(true);

            // First, initialize the analysis
            const initResult = await stockAIService.initializeAnalysis({
                industry: industry,
                riskLevel: riskLevel
            });

            // Then get basic stock analysis with subscription check
            const analysisData = await stockAIService.analyzeStocks({
                industry: industry,
                riskLevel: riskLevel,
                analysis_id: initResult.analysis_id
            });

            // Check if subscription is required
            if (analysisData.requires_subscription || analysisData.subscription_expired) {
                // Store the current state for post-payment return
                sessionStorage.setItem('stockAnalysisReturn', JSON.stringify({
                    industry,
                    riskLevel,
                    analysisId: analysisData.analysis_id
                }));
                setAnalysisData(analysisData);
                return;
            }

            // If subscription is valid, get market analysis
            const marketData = await stockAIService.getMarketAnalysis({
                industry: industry,
                riskLevel: riskLevel,
                stockData: analysisData  // Pass the stock analysis data
            });

            console.log('Market Analysis Data:', marketData);
            setAnalysisData({
                ...marketData,
                subscription_status: analysisData.subscription_status // Keep subscription status
            });
        } catch (err) {
            console.error('Analysis Error:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [industry, riskLevel, setLoading]);

    useEffect(() => {
        fetchAnalysis();
    }, [fetchAnalysis]);

    if (error) return <ErrorDisplay error={error}/>;
    if (!analysisData) return <LoadingIndicator/>;
    if (analysisData.requires_subscription || analysisData.subscription_expired) {
        return (
            <SubscriptionCard
                onSubscribe={handleSubscribe}
                loading={isSubscribing}
                analysisId={analysisData.analysis_id}
            />
        );
    }

    return (
        <TooltipProvider>
            <motion.div
                id="stock-results-content"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                className="stock-results-container max-w-7xl mx-auto px-4 py-8"
            >
                {/* Add Subscription Status here, before Market Overview */}
                <Card className="p-4 bg-blue-900/30 border-blue-500/50 mb-6">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="text-gray-300 text-sm">
                                Analyses Token remaining: {' '}
                                <span
                                    className={analysisData?.subscription_status?.uses_remaining <= 0 ? "text-red-500 font-bold" : "text-gray-300"}>
                                {analysisData?.subscription_status?.uses_remaining}
                            </span>
                                {' '}| Days remaining: {analysisData?.subscription_status?.days_remaining}
                            </p>
                        </div>
                        <CheckCircle className="text-green-400 h-5 w-5"/>
                    </div>
                </Card>
                {/* Fidelity CTA */}
                <Card className="mb-6 bg-gradient-to-r from-green-600/20 to-blue-600/20 p-6">
                    <div className="flex flex-col md:flex-row items-center justify-between">
                        <div className="text-center md:text-left mb-4 md:mb-0">
                            <h2 className="text-xl font-bold text-white">Ready to Invest?</h2>
                            <p className="text-gray-300">
                                Start trading stocks with...(Send Us an Email: support@taxmeai.com for CTA placement)
                            </p>
                        </div>
                        <div className="flex gap-4 items-center">
                            <Button
                                onClick={() => window.open('https://www.google.com', '_blank')}
                                className="bg-green-600 hover:bg-green-700 text-white px-6"
                            >
                                Open Account
                                <ArrowRight className="ml-2 h-4 w-4"/>
                            </Button>
                            <a
                                href="https://www.google.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sm text-gray-400 hover:text-white transition-colors"
                            >
                                Learn More
                            </a>
                        </div>
                    </div>
                </Card>
                {/* Market Overview */}
                <MarketOverview data={analysisData.market_overview}/>

                {/* Stock Cards */}
                <motion.div
                    variants={itemVariants}
                    className="mt-8 space-y-6"
                >
                    <div className="flex items-center justify-between">
                        <h2 className="text-2xl font-bold text-white">Top Recommendations</h2>
                        <div className="text-sm text-gray-400">
                            {analysisData.recommendations.length} stocks analyzed
                        </div>
                    </div>

                    <div className="grid gap-6">
                        {analysisData.recommendations.map((stock) => (
                            <StockCard
                                key={stock.symbol}
                                stock={stock}
                                technicalAnalysis={analysisData.technical_analysis}
                                aiInsights={[
                                    ...analysisData.ai_insights.opportunities,
                                    ...analysisData.ai_insights.risks
                                ]}
                            />
                        ))}
                    </div>
                </motion.div>

                {/* Market Insights */}
                <MarketInsights
                    insights={analysisData.ai_insights}
                    sentiment={analysisData.market_sentiment}
                />

                {/* Action Buttons */}
                <div className="flex gap-4">
                    <Button
                        onClick={handleBackClick}
                        variant="outline"
                        className="border-gray-700 text-gray-300 rounded-full"
                        disabled={showSubscriptionPrompt}
                    >
                        Analyze Again
                    </Button>
                    <Button
                        onClick={handleExportPDF}
                        className="bg-blue-600 hover:bg-blue-700 rounded-full"
                        disabled={showSubscriptionPrompt}
                    >
                        <Download className="mr-2"/>
                        Download Analysis
                    </Button>
                </div>
                {/* Back Warning Modal */}
                {showBackWarning && (
                    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                        <Card className="p-6 max-w-md bg-gray-900/90">
                            <h3 className="text-xl font-bold text-white mb-4">Before You Leave</h3>
                            <p className="text-gray-300 mb-6">
                                If you leave now, you'll need to use another token to view this analysis again.
                                All current results will be lost.
                            </p>
                            <div className="flex justify-end gap-4">
                                <Button
                                    variant="outline"
                                    onClick={() => setShowBackWarning(false)}
                                >
                                    Stay Here
                                </Button>
                                <Button
                                    className="bg-blue-600"
                                    onClick={() => (window.location.href = '/stock-ai')}
                                >
                                    Leave Anyway
                                </Button>
                            </div>

                        </Card>
                    </div>
                )}
            </motion.div>
        </TooltipProvider>
    );
};
const FeatureCard = ({icon, title, description}) => (
    <div className="p-4 bg-gray-800/30 rounded-lg">
        <div className="flex items-center gap-2 mb-2">
            {icon}
            <h3 className="text-sm font-medium text-white">{title}</h3>
        </div>
        <p className="text-xs text-gray-400">{description}</p>
    </div>
);

const SubscriptionCard = ({onSubscribe, loading, analysisId}) => (
    <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-md mx-auto p-6"  // Changed from max-w-4xl
    >
        <Card className="p-6 bg-gradient-to-r from-blue-900/30 to-purple-900/30"> {/* Reduced padding */}
            <div className="text-center">
                <LockKeyhole className="w-12 h-12 mx-auto text-gray-400 mb-3"/> {/* Smaller icon */}
                <h2 className="text-xl font-bold text-white mb-2"> {/* Smaller text */}
                    Unleash the Stock AI
                </h2>
                <p className="text-sm text-gray-300 mb-4"> {/* Smaller text and margin */}
                    For just $9.99, get 10 tokens and unlock our comprehensive stock analysis recommendations system.
                    Which can be use upto 10 times within 30 days.
                </p>
                <motion.button
                    whileHover={{scale: 1.02}}
                    whileTap={{scale: 0.98}}
                    onClick={() => onSubscribe(analysisId)}
                    disabled={loading}
                    className="w-full bg-gradient-to-r from-green-500 to-blue-500
                   hover:from-green-600 hover:to-blue-600 px-4 py-2
                   rounded-lg text-white font-medium disabled:opacity-50"
                >
                    {loading ? (
                        <span className="flex items-center justify-center gap-2">
              <SiSat1 className="w-4 h-4 animate-spin"/>
              Processing...
            </span>
                    ) : (
                        "Get Tokens Now - $9.99"
                    )}
                </motion.button>

                <div className="mt-4 grid grid-cols-2 gap-2 text-xs"> {/* Smaller grid */}
                    <div className="p-2 bg-gray-800/30 rounded">
                        <Target className="w-4 h-4 text-blue-400 mx-auto mb-1"/>
                        <p className="font-medium">10 Analyses</p>
                    </div>
                    <div className="p-2 bg-gray-800/30 rounded">
                        <Activity className="w-4 h-4 text-green-400 mx-auto mb-1"/>
                        <p className="font-medium">7 Stocks Recommendation</p>
                    </div>
                </div>
            </div>
        </Card>
    </motion.div>
);

const MarketOverview = ({data}) => {
    if (!data?.market_health || !data?.technical_summary) return null;

    return (
        <motion.div variants={itemVariants}>
            <Card className="p-6 bg-gray-900/30 backdrop-blur border-gray-800/50">
                <div className="flex items-center gap-2 mb-6">
                    <Activity className="text-blue-400"/>
                    <h2 className="text-2xl font-bold text-white">Market Overview</h2>
                </div>

                <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                        <h3 className="text-lg font-medium text-white flex items-center gap-2">
                            <Target className="text-green-400" size={18}/>
                            Health Assessment
                        </h3>
                        {data.market_health.map((insight, index) => (
                            <div key={index} className="p-4 bg-gray-800/30 rounded-lg">
                                <p className="text-gray-300">{insight}</p>
                            </div>
                        ))}
                    </div>

                    <div className="space-y-4">
                        <h3 className="text-lg font-medium text-white flex items-center gap-2">
                            <BarChart3 className="text-purple-400" size={18}/>
                            Technical Summary
                        </h3>
                        {data.technical_summary.map((insight, index) => (
                            <div key={index} className="p-4 bg-gray-800/30 rounded-lg">
                                <p className="text-gray-300">{insight}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </Card>
        </motion.div>
    );
};

// Add this function in your StockResults component
const handleExportPDF = async () => {
    try {
        const content = document.getElementById('stock-results-content');

        // Create temporary element for PDF version
        const pdfContent = content.cloneNode(true);
        pdfContent.classList.add('pdf-export-mode');
        document.body.appendChild(pdfContent);

        // Add TaxMeAI branding
        const brandingSection = document.createElement('div');
        brandingSection.innerHTML = `
          <div class="pdf-branding mb-8">
              <img src="/tax-logo-dark.svg" alt="TaxMeAI" style="height: 60px;" />
              <h2 class="text-2xl font-bold text-white">Stock Analysis by TaxMe AI</h2>
              <p class="text-gray-300">
                  This stock analysis was computed using https://taxmeai.com<br/>
                  Get accurate tax estimates and Stock Market insights with TaxMe AI.
              </p>
          </div>
      `;
        pdfContent.insertBefore(brandingSection, pdfContent.firstChild);

        // Add watermark
        const watermark = document.createElement('div');
        watermark.className = 'watermark';
        watermark.style.background = `url('/tax-logo-dark.svg') center/1500px no-repeat`;
        watermark.style.transform = 'rotate(-30deg)';
        pdfContent.insertBefore(watermark, pdfContent.firstChild);

        // Create canvas with better resolution
        const canvas = await html2canvas(pdfContent, {
            scale: 2,
            useCORS: true,
            logging: false,
            backgroundColor: '#111827',
            windowWidth: 1100,
        });

        // Remove temporary element
        document.body.removeChild(pdfContent);

        const imgData = canvas.toDataURL('image/png');

        // Initialize PDF
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });

        // Calculate dimensions
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const aspectRatio = canvas.height / canvas.width;
        const contentHeight = pdfWidth * aspectRatio;
        const pageCount = Math.ceil(contentHeight / pdfHeight);

        // Split content across pages
        for (let i = 0; i < pageCount; i++) {
            if (i < 0) {
                pdf.addPage();
            }

            // Calculate section of image for this page
            const sourceY = i * canvas.height / pageCount;
            const sourceHeight = canvas.height / pageCount;

            // Add content
            pdf.addImage(
                imgData,
                'PNG',
                0,
                20,
                pdfWidth,
                pdfHeight - 0,
                '',
                'FAST',
                0,
                sourceY,
                canvas.width,
                sourceHeight
            );
        }

        pdf.save(`Stock-Analysis-${new Date().toISOString().split('T')[0]}.pdf`);

    } catch (error) {
        console.error('PDF export error:', error);
    }
};

const StockCard = ({stock, technicalAnalysis, aiInsights}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const stockInsights = aiInsights?.filter(insight =>
        insight.description.toLowerCase().includes(stock.symbol.toLowerCase())
    );

    return (
        <motion.div variants={itemVariants}>
            <Card
                className="p-6 bg-gray-900/30 backdrop-blur border-gray-800/50 hover:border-gray-700/50 transition-all duration-300">
                {/* Header Section */}
                <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-6">
                    <div className="flex items-center gap-3">
                        <div className="p-2.5 rounded-lg bg-gray-800/50">
                            <Building className="w-6 h-6 text-blue-400"/>
                        </div>
                        <div>
                            <h3 className="text-xl font-bold text-white flex items-center gap-2">
                                {stock.symbol}
                                <span className={`text-sm px-2 py-0.5 rounded-full ${
                                    technicalAnalysis[stock.symbol].overall_signal === 'STRONG_BUY'
                                        ? 'bg-green-500/20 text-green-400'
                                        : technicalAnalysis[stock.symbol].overall_signal === 'BUY'
                                            ? 'bg-emerald-500/20 text-emerald-400'
                                            : 'bg-yellow-500/20 text-yellow-400'
                                }`}>
                    {technicalAnalysis[stock.symbol].overall_signal.replace('_', ' ')}
                  </span>
                            </h3>
                            <p className="text-sm text-gray-400">{stock.name}</p>
                        </div>
                    </div>

                    <div className="flex items-center gap-6">
                        <div className="flex items-center gap-2">
                            <DollarSign className="text-gray-400" size={16}/>
                            <span className="text-white font-medium">${stock.price.toFixed(2)}</span>
                        </div>
                        <div className={`flex items-center gap-1 ${
                            stock.change >= 0 ? 'text-green-400' : 'text-red-400'
                        }`}>
                            {stock.change >= 0 ?
                                <ArrowUpRight size={16}/> :
                                <ArrowDownRight size={16}/>
                            }
                            <span className="font-medium">{Math.abs(stock.change)}%</span>
                        </div>
                    </div>
                </div>

                {/* Metrics Grid */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                    <div className="p-3 rounded-lg bg-gray-800/30">
                        <div className="flex items-center gap-2 text-gray-400 text-sm mb-1">
                            <Users className="w-4 h-4"/>
                            <span>Volume</span>
                        </div>
                        <div className="text-white font-medium">
                            {(stock.volume / 1000000).toFixed(1)}M
                        </div>
                    </div>

                    <div className="p-3 rounded-lg bg-gray-800/30">
                        <div className="flex items-center gap-2 text-gray-400 text-sm mb-1">
                            <Globe className="w-4 h-4"/>
                            <span>Market Cap</span>
                        </div>
                        <div className="text-white font-medium">
                            ${(stock.marketCap / 1000000000).toFixed(1)}B
                        </div>
                    </div>

                    <div className="p-3 rounded-lg bg-gray-800/30">
                        <div className="flex items-center gap-2 text-gray-400 text-sm mb-1">
                            <Activity className="w-4 h-4"/>
                            <span>Score</span>
                        </div>
                        <div className="text-white font-medium">
                            {(technicalAnalysis[stock.symbol].score * 100).toFixed(0)}%
                        </div>
                    </div>

                    <div className="p-3 rounded-lg bg-gray-800/30">
                        <div className="flex items-center gap-2 text-gray-400 text-sm mb-1">
                            <Target className="w-4 h-4"/>
                            <span>Signals</span>
                        </div>
                        <div className="flex gap-2">
                            {Object.entries(technicalAnalysis[stock.symbol].signals).map(([key, value]) => (
                                <div key={key} className={`w-2 h-2 rounded-full ${
                                    value === 'positive' ? 'bg-green-400' :
                                        value === 'negative' ? 'bg-red-400' :
                                            'bg-yellow-400'
                                }`}/>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Stock Insights */}
                {stockInsights && stockInsights.length > 0 && (
                    <div
                        className={`overflow-hidden transition-all duration-300 ${
                            isExpanded ? 'max-h-96' : 'max-h-0'
                        }`}
                    >
                        <div className="p-4 rounded-lg bg-gray-800/20 mb-4">
                            <div className="flex items-center gap-2 mb-3">
                                <Info className="w-4 h-4 text-blue-400"/>
                                <h4 className="text-sm font-medium text-gray-300">Analysis Insights</h4>
                            </div>
                            <div className="space-y-3">
                                {stockInsights.map((insight, index) => (
                                    <p key={index} className="text-sm text-gray-400">
                                        {insight.description}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {/* Expand Button */}
                {stockInsights && stockInsights.length > 0 && (
                    <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="w-full flex items-center justify-center gap-2 text-sm text-gray-400 hover:text-gray-300 transition-colors mt-2"
                    >
                        {isExpanded ? (
                            <>
                                <span>Show Less</span>
                                <ChevronUp size={16}/>
                            </>
                        ) : (
                            <>
                                <span>Show More</span>
                                <ChevronDown size={16}/>
                            </>
                        )}
                    </button>
                )}
            </Card>
        </motion.div>
    );
};

const MarketInsights = ({insights, sentiment}) => {
    return (
        <motion.div variants={itemVariants} className="mt-8">
            <div className="grid md:grid-cols-2 gap-6 mb-6">
                {/* Risks Section */}
                <Card className="p-6 bg-gray-900/30 backdrop-blur border-gray-800/50">
                    <div className="flex items-center gap-2 mb-6">
                        <AlertTriangle className="text-red-400"/>
                        <h2 className="text-xl font-bold text-white">Risk Factors</h2>
                    </div>
                    <div className="space-y-4">
                        {insights.risks.map((risk, index) => (
                            <div key={index} className="p-4 bg-gray-800/30 rounded-lg">
                                <p className="text-gray-300">{risk.description}</p>
                            </div>
                        ))}
                    </div>
                </Card>

                {/* Opportunities Section */}
                <Card className="p-6 bg-gray-900/30 backdrop-blur border-gray-800/50">
                    <div className="flex items-center gap-2 mb-6">
                        <TrendingUp className="text-green-400"/>
                        <h2 className="text-xl font-bold text-white">Opportunities</h2>
                    </div>
                    <div className="space-y-4">
                        {insights.opportunities.map((opportunity, index) => (
                            <div key={index} className="p-4 bg-gray-800/30 rounded-lg">
                                <p className="text-gray-300">{opportunity.description}</p>
                            </div>
                        ))}
                    </div>
                </Card>
            </div>

            {/* Market Sentiment */}
            <div className="grid md:grid-cols-2 gap-6">
                <div className="p-4 bg-gray-800/30 rounded-lg">
                    <div className="flex items-center gap-2 mb-4">
                        <TrendingUp className="text-green-400" size={18}/>
                        <h3 className="text-lg font-medium text-white">
                            {sentiment.short_term.title}
                        </h3>
                    </div>
                    <p className="text-gray-400">
                        {sentiment.short_term.description}
                    </p>
                </div>
                <div className="p-4 bg-gray-800/30 rounded-lg">
                    <div className="flex items-center gap-2 mb-4">
                        <TrendingDown className="text-yellow-400" size={18}/>
                        <h3 className="text-lg font-medium text-white">
                            {sentiment.long_term.title}
                        </h3>
                    </div>
                    <p className="text-gray-400">
                        {sentiment.long_term.description}
                    </p>
                </div>
            </div>

        </motion.div>
    );
};

const LoadingIndicator = () => (
    <div className="flex flex-col items-center justify-center min-h-[400px]">
        <motion.div
            animate={{rotate: 360}}
            transition={{duration: 2, repeat: Infinity, ease: "linear"}}
            className="mb-4"
        >
            <SiSat1 size={45} className="text-blue-400"/>
        </motion.div>
        <h3 className="text-xl font-bold text-white mb-2">Analyzing Stock Market Data</h3>
        <p className="text-gray-400 text-center max-w-md">
            Please seat tight while we process market conditions and generate recommendations...
        </p>
        <div className="mt-6 flex flex-col items-center gap-4">
            <div className="flex gap-3">
                <motion.div
                    animate={{scale: [1, 1.2, 1]}}
                    transition={{duration: 1, repeat: Infinity, delay: 0}}
                    className="w-3 h-3 bg-blue-400 rounded-full"
                />
                <motion.div
                    animate={{scale: [1, 1.2, 1]}}
                    transition={{duration: 1, repeat: Infinity, delay: 0.2}}
                    className="w-3 h-3 bg-blue-400 rounded-full"
                />
                <motion.div
                    animate={{scale: [1, 1.2, 1]}}
                    transition={{duration: 1, repeat: Infinity, delay: 0.4}}
                    className="w-3 h-3 bg-blue-400 rounded-full"
                />
            </div>
        </div>
    </div>
);

const ErrorDisplay = ({error}) => (
    <div className="flex flex-col items-center justify-center min-h-[300px]">
        <AlertTriangle size={48} className="text-red-400 mb-4"/>
        <h3 className="text-xl font-bold text-white mb-2">Analysis Failed</h3>
        <p className="text-gray-400 text-center max-w-md">
            {error || 'An unexpected error occurred while analyzing stocks. Please try again.'}
        </p>
        <div className="mt-6">
            <button
                onClick={() => window.location.reload()}
                className="px-4 py-2 bg-gray-800 hover:bg-gray-500 text-white rounded-full transition-colors"
            >
                Try Again
            </button>
        </div>
    </div>
);

export default React.memo(StockResults);