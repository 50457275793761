// src/pages/Payment/PaymentSuccess.jsx
import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {AlertCircle, CheckCircle} from 'lucide-react';
import axios from 'axios';
import API_URL, {ENDPOINTS} from '../../utils/config/api';
import {Card} from '../../components/ui/card';
import {Button} from '../../components/ui/button';

const PaymentSuccess = () => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const verifyPayment = async () => {
            try {
                const sessionId = searchParams.get('session_id');
                const analysisId = searchParams.get('analysis_id');

                console.log('Session ID:', sessionId); // Debug log
                console.log('Analysis ID:', analysisId); // Debug log

                if (!sessionId || !analysisId) {
                    throw new Error('Missing required parameters');
                }

                const token = localStorage.getItem('access_token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                // Verify the payment with backend
                const response = await axios.get(
                    `${API_URL}${ENDPOINTS.SUBSCRIPTION_VERIFY}/${sessionId}`,
                    {
                        params: {analysis_id: analysisId},
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                console.log('Verification response:', response.data); // Debug log

                if (response.data.success) {
                    // Get stored analysis data
                    const storedData = sessionStorage.getItem('pendingAnalysis');
                    if (storedData) {
                        const parsedData = JSON.parse(storedData);
                        // Clear stored data
                        sessionStorage.removeItem('pendingAnalysis');
                        // Navigate back to analysis
                        navigate('/tax-calculator/analysis', {
                            state: {
                                ...parsedData,
                                subscriptionStatus: response.data.subscription_status
                            },
                            replace: true
                        });
                    } else {
                        navigate('/tax-calculator/analysis');
                    }
                } else {
                    throw new Error('Payment verification failed');
                }

            } catch (err) {
                console.error('Verification error:', err);
                setError(err.message || 'Payment verification failed');
            } finally {
                setIsLoading(false);
            }
        };

        verifyPayment();
    }, [navigate, searchParams]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-900">
                <Card className="p-8 text-center bg-gray-800 border border-gray-700">
                    <div className="animate-spin mb-4">
                        <div className="w-16 h-16 border-4 border-blue-500 rounded-full border-t-transparent"></div>
                    </div>
                    <p className="text-gray-300">Verifying your payment...</p>
                </Card>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-900">
                <Card className="p-8 text-center bg-gray-800 border border-gray-700">
                    <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4"/>
                    <h2 className="text-xl font-bold mb-4 text-white">Payment Verification Failed</h2>
                    <p className="text-gray-300 mb-6">{error}</p>
                    <div className="space-y-3">
                        <Button
                            onClick={() => navigate('/tax-calculator/analysis')}
                            className="w-full bg-blue-600 hover:bg-blue-700"
                        >
                            Return to Analysis
                        </Button>
                        <Button
                            onClick={() => navigate('/tax-calculator')}
                            variant="outline"
                            className="w-full"
                        >
                            Back to Calculator
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900">
            <Card className="p-8 text-center bg-gray-800 border border-gray-700">
                <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4"/>
                <h2 className="text-xl font-bold mb-4 text-white">Payment Successful!</h2>
                <p className="text-gray-300 mb-6">
                    Redirecting you back to your analysis...
                </p>
            </Card>
        </div>
    );
};

export default PaymentSuccess;