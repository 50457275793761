import React, {useState} from 'react';
import {motion} from 'framer-motion';
import {Search} from 'lucide-react';
import {Card} from '../../../../components/ui/card';
import '../../../../styles/StockAI/IndustrySelector.css';

const industries = [
    {
        id: 'technology',
        name: 'Technology',
        description: 'Software, Hardware, and IT services',
        icon: '💻',
        marketSize: '$2.8T',
        growthRate: '12.5%'
    },
    {
        id: 'finance',
        name: 'Financial Services',
        description: 'Banking, Insurance, and Investment services',
        icon: '💰',
        marketSize: '$1.9T',
        growthRate: '8.2%'
    },
    {
        id: 'healthcare',
        name: 'Healthcare',
        description: 'Medical devices, Pharmaceuticals, and Healthcare services',
        icon: '🏥',
        marketSize: '$2.1T',
        growthRate: '9.7%'
    },
    {
        id: 'energy',
        name: 'Energy',
        description: 'Oil & Gas, Renewable Energy, and Utilities',
        icon: '⚡',
        marketSize: '$1.7T',
        growthRate: '7.5%'
    },
    {
        id: 'real_estate',
        name: 'Real Estate',
        description: 'REITs, Property Development, and Management',
        icon: '🏢',
        marketSize: '$1.2T',
        growthRate: '5.9%'
    }
];

const IndustrySelector = ({onSelect}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [hoveredIndustry, setHoveredIndustry] = useState(null);

    const filteredIndustries = industries.filter(industry =>
        industry.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        industry.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const containerAnimation = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemAnimation = {
        hidden: {opacity: 0, y: 20},
        show: {opacity: 1, y: 0}
    };

    return (
        <motion.div
            variants={containerAnimation}
            initial="hidden"
            animate="show"
            className="max-w-6xl mx-auto px-4"
        >
            {/* Header */}
            <motion.div variants={itemAnimation} className="text-center mb-6">
                <h1 className="text-3xl font-bold text-white mb-2">Choose Your Industry</h1>
                <p className="text-lg text-gray-400">
                    Select an industry to start your investment journey
                </p>
            </motion.div>

            {/* Search */}
            <motion.div variants={itemAnimation} className="mb-6">
                <Card className="p-3 bg-gray-900/50 border-gray-700">
                    <div className="relative">
                        <Search className="absolute left-2.5 top-2.5 text-gray-400" size={16}/>
                        <input
                            type="text"
                            placeholder="Search industries..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full pl-8 pr-3 py-1.5 bg-gray-800/50 border border-gray-700
                       rounded-lg text-sm text-white placeholder-gray-400 focus:border-blue-500
                       focus:outline-none focus:ring-1 focus:ring-blue-500/50"
                        />
                    </div>
                </Card>
            </motion.div>

            {/* Industry Grid */}
            <motion.div
                variants={itemAnimation}
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
            >
                {filteredIndustries.map((industry) => (
                    <motion.div
                        key={industry.id}
                        variants={itemAnimation}
                        onHoverStart={() => setHoveredIndustry(industry.id)}
                        onHoverEnd={() => setHoveredIndustry(null)}
                        onClick={() => onSelect(industry)}
                        whileHover={{scale: 1.02}}
                        whileTap={{scale: 0.98}}
                    >
                        <Card className={`
              p-4 cursor-pointer transition-all duration-300 h-full
              ${hoveredIndustry === industry.id ? 'bg-gray-800/70' : 'bg-gray-900/50'} 
              border-gray-700 hover:border-blue-500/50
            `}>
                            {/* Header */}
                            <div className="flex items-start gap-3 mb-3">
                                <span className="text-2xl">{industry.icon}</span>
                                <div>
                                    <h3 className="text-base font-semibold text-white">
                                        {industry.name}
                                    </h3>
                                    <p className="text-xs text-gray-400">{industry.description}</p>
                                </div>
                            </div>

                            {/* Metrics */}
                            <div className="grid grid-cols-2 gap-3 text-sm">
                                <div>
                                    <div className="text-xs text-gray-400">Market Size</div>
                                    <div className="font-medium text-white">
                                        {industry.marketSize}
                                    </div>
                                </div>
                                <div>
                                    <div className="text-xs text-gray-400">Growth</div>
                                    <div className="font-medium text-green-400">
                                        {industry.growthRate}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </motion.div>
                ))}
            </motion.div>

            {/* No Results */}
            {filteredIndustries.length === 0 && (
                <motion.div
                    variants={itemAnimation}
                    className="text-center py-8"
                >
                    <p className="text-gray-400 text-sm">
                        No industries found matching "{searchTerm}"
                    </p>
                </motion.div>
            )}
        </motion.div>
    );
};

export default IndustrySelector;