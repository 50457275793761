import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import {Baby, DollarSign, GraduationCap, Heart, Home, PiggyBank, Users} from 'lucide-react';
import '../../../../styles/TaxCalculator/steps/Credits.css';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,} from '../../../../components/ui/tooltip';
import {Card} from '../../../../components/ui/card';
import Layout from '../shared/Layout';

// Helper component to render individual credit sections
const RenderCredit = ({credit, formData, onChange}) => {
    const {id, title, description, tooltip, icon: Icon, fields} = credit;

    return (
        <Card className="p-6 bg-transparent border-gray-700">
            <div className="flex items-center gap-2 mb-4">
                <Icon className="text-blue-400" size={24}/>
                <div>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <h4 className="text-lg font-semibold text-white">{title}</h4>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p className="max-w-xs">{tooltip}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <p className="text-sm text-gray-400">{description}</p>
                </div>
            </div>

            <div className="space-y-4">
                {fields.map(field => {
                    if (field.showIf && !field.showIf(formData.credits[id])) {
                        return null;
                    }

                    switch (field.type) {
                        case 'checkbox':
                            return (
                                <label key={field.name} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        checked={formData.credits[id]?.[field.name] || false}
                                        onChange={(e) => onChange(id, field.name, e.target.checked)}
                                        className="rounded border-gray-700 bg-gray-900"
                                    />
                                    <span className="text-sm text-gray-300">{field.label}</span>
                                </label>
                            );
                        case 'select':
                            return (
                                <div key={field.name} className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-300">
                                        {field.label}
                                    </label>
                                    <select
                                        value={formData.credits[id]?.[field.name] || ''}
                                        onChange={(e) => onChange(id, field.name, e.target.value)}
                                        className="w-full rounded-full border border-gray-700 bg-transparent
                                                 py-2 px-3 text-white"
                                    >
                                        {field.options.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            );
                        case 'currency':
                            return (
                                <div key={field.name} className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-300">
                                        {field.label}
                                    </label>
                                    <div className="relative">
                                        <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 
                                                             text-gray-400" size={16}/>
                                        <input
                                            type="number"
                                            value={formData.credits[id]?.[field.name] || ''}
                                            onChange={(e) => onChange(id, field.name, e.target.value)}
                                            className="w-full rounded-full border border-gray-700 bg-transparent 
                                                     py-2 pl-8 pr-3 text-white"
                                            min="0"
                                            step="0.01"
                                            placeholder="0.00"
                                        />
                                    </div>
                                </div>
                            );
                        default: // number
                            return (
                                <div key={field.name} className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-300">
                                        {field.label}
                                    </label>
                                    <input
                                        type="number"
                                        value={formData.credits[id]?.[field.name] || ''}
                                        onChange={(e) => onChange(id, field.name,
                                            parseFloat(e.target.value) || 0)}
                                        className="w-full rounded-full border border-gray-700 bg-transparent 
                                                 py-2 px-3 text-white"
                                        min="0"
                                        step="1"
                                    />
                                </div>
                            );
                    }
                })}
            </div>
        </Card>
    );
};

const Credits = ({formData, setFormData, onSubmit, loading, error}) => {
    const navigate = useNavigate();
    const {stateCode} = useParams();

    const handleCreditChange = (category, field, value) => {
        setFormData(prev => ({
            ...prev,
            credits: {
                ...prev.credits,
                [category]: {
                    ...prev.credits[category],
                    [field]: value
                }
            }
        }));
    };

    // Federal credits available for all states
    const federalCredits = [
        {
            id: 'child_tax_credit',
            title: 'Child Tax Credit',
            description: 'Credit for qualifying children under 17',
            tooltip: 'Up to $2,000 per qualifying child',
            icon: Users,
            fields: [
                {
                    name: 'num_children',
                    type: 'number',
                    label: 'Number of Qualifying Children'
                }
            ]
        },
        {
            id: 'child_care',
            title: 'Child and Dependent Care',
            description: 'Credit for child care expenses',
            tooltip: 'Credit for expenses paid for care of qualifying children under 13',
            icon: Heart,
            fields: [
                {
                    name: 'expenses',
                    type: 'currency',
                    label: 'Care Expenses'
                }
            ]
        },
        {
            id: 'education',
            title: 'Education Credits',
            description: 'American Opportunity and Lifetime Learning credits',
            tooltip: 'Credits for qualified education expenses',
            icon: GraduationCap,
            fields: [
                {
                    name: 'type',
                    type: 'select',
                    label: 'Credit Type',
                    options: [
                        {value: 'none', label: 'None'},
                        {value: 'american_opportunity', label: 'American Opportunity'},
                        {value: 'lifetime_learning', label: 'Lifetime Learning'}
                    ]
                },
                {
                    name: 'expenses',
                    type: 'currency',
                    label: 'Qualified Expenses',
                    showIf: (values) => values?.type !== 'none'
                }
            ]
        },
        {
            id: 'retirement_savings',
            title: 'Retirement Savings',
            description: 'Credit for retirement account contributions',
            tooltip: 'Credit for contributions to qualified retirement accounts',
            icon: PiggyBank,
            fields: [
                {
                    name: 'has_retirement_plan',
                    type: 'checkbox',
                    label: 'Made Retirement Contributions'
                },
                {
                    name: 'contributions',
                    type: 'currency',
                    label: 'Total Contributions',
                    showIf: (values) => values?.has_retirement_plan
                }
            ]
        }
    ];

    // State-specific credits configurations
    const getStateCredits = () => {
        switch (stateCode?.toUpperCase()) {
            case 'CA':
                return [
                    {
                        id: 'renter',
                        title: 'Renter\'s Credit',
                        description: 'Credit for qualified renters',
                        tooltip: 'Available for California residents who rent their primary residence',
                        icon: Home,
                        fields: [
                            {
                                name: 'eligible',
                                type: 'checkbox',
                                label: 'Eligible for Renter\'s Credit'
                            }
                        ]
                    },
                    {
                        id: 'dependent_care',
                        title: 'Young Child Tax Credit',
                        description: 'Credit for children under 6',
                        tooltip: 'California-specific credit for young dependents',
                        icon: Baby,
                        fields: [
                            {
                                name: 'num_children',
                                type: 'number',
                                label: 'Number of Children Under 6'
                            }
                        ]
                    }
                ];
            case 'NC':
                return [
                    {
                        id: 'child_deduction',
                        title: 'Child Deduction',
                        description: 'Deduction for qualifying children',
                        tooltip: 'North Carolina specific deduction based on filing status and income',
                        icon: Users,
                        fields: [
                            {
                                name: 'num_children',
                                type: 'number',
                                label: 'Number of Qualifying Children'
                            }
                        ]
                    }
                ];
            default:
                return [];
        }
    };

    const handleNext = () => {
        if (onSubmit) {
            onSubmit(formData);
        }
    };

    const handleBack = () => {
        navigate(`/tax-calculator/${stateCode}/deductions`, {replace: true});
    };

    return (
        <Layout formData={formData} setFormData={setFormData}>
            <motion.div
                className="space-y-6"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.5}}
            >
                <div className="flex items-center justify-between mb-6">
                    <div className="space-y-1">
                        <h3 className="text-2xl font-bold text-white">Tax Credits</h3>
                        <p className="text-gray-300">Enter your qualifying tax credits</p>
                    </div>

                </div>

                {/* Federal Credits Section */}
                <div className="space-y-6">
                    {federalCredits.map(credit => (
                        <RenderCredit
                            key={credit.id}
                            credit={credit}
                            formData={formData}
                            onChange={handleCreditChange}
                        />
                    ))}
                </div>

                {/* State-Specific Credits */}
                {getStateCredits().length > 0 && (
                    <div className="space-y-6">
                        <div className="border-t border-gray-800 pt-6">
                            <h4 className="text-lg font-semibold text-white mb-4">
                                {stateCode.toUpperCase()} State Credits
                            </h4>
                            {getStateCredits().map(credit => (
                                <RenderCredit
                                    key={credit.id}
                                    credit={credit}
                                    formData={formData}
                                    onChange={handleCreditChange}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {error && (
                    <div className="mt-4 p-4 bg-red-900/50 border border-red-500 rounded-full text-red-300">
                        {error}
                    </div>
                )}

                {/* Navigation */}
                <motion.div
                    className="flex justify-between mt-8"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0.3}}
                >
                    <motion.button
                        onClick={handleBack}
                        className="px-6 py-3 rounded-full border border-red-700 text-gray-300 
                                 hover:bg-gray-800"
                        whileHover={{scale: 1.05}}
                        whileTap={{scale: 0.95}}
                    >
                        Back to Deductions
                    </motion.button>
                    <motion.button
                        onClick={handleNext}
                        disabled={loading}
                        className="px-6 py-3 rounded-full bg-green-600 text-white hover:bg-blue-700 
                                 disabled:opacity-50 disabled:cursor-not-allowed"
                        whileHover={{scale: 1.05}}
                        whileTap={{scale: 0.95}}
                    >
                        {loading ? 'Calculating...' : 'Calculate Taxes'}
                    </motion.button>
                </motion.div>
            </motion.div>
        </Layout>
    );
};

export default Credits;
