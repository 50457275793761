import React from 'react';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import {FiArrowLeft, FiAward, FiBriefcase, FiCoffee, FiHeadphones, FiLifeBuoy, FiUsers} from 'react-icons/fi';
import '../styles/JoinUs.css';

const GalaxyBackground = () => {
    React.useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';

            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;

                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);

                starsContainer.appendChild(star);
            }

            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background"/>
            <div className="galaxy-spiral"/>
        </>
    );
};

const BenefitsSection = () => (
    <div className="benefits-section">
        <h2><FiAward/> Benefits</h2>
        <div className="benefits-grid">
            <div className="benefit-card">
                <FiCoffee className="benefit-icon"/>
                <h3>Work from Home - Remote</h3>
                <p>Flexible hours</p>
            </div>
            <div className="benefit-card">
                <FiHeadphones className="benefit-icon"/>
                <h3>Learning & Development</h3>
                <p>Annual learning stipend and mentorship programs</p>
            </div>
            <div className="benefit-card">
                <FiLifeBuoy className="benefit-icon"/>
                <h3>Health & Wellness</h3>
                <p>Comprehensive health coverage and wellness programs</p>
            </div>
            <div className="benefit-card">
                <FiAward className="benefit-icon"/>
                <h3>Career Growth</h3>
                <p>Clear career progression and regular reviews</p>
            </div>
        </div>
    </div>
);

const JobPostings = () => (
    <div className="job-postings">
        <motion.div className="job-card" whileHover={{scale: 1.02}}>
            <h3>Software Engineer - Entry Level</h3>
            <p className="positions-available">3 Positions Available</p>
            <div className="job-details">
                <h4>Requirements:</h4>
                <ul>
                    <li>Bachelor's degree in Computer Science or related field</li>
                    <li>Strong understanding of algorithms and data structures</li>
                    <li>Experience with Python, JavaScript, and React</li>
                    <li>Knowledge of version control systems (Git)</li>
                </ul>
                <h4>Responsibilities:</h4>
                <ul>
                    <li>Develop and maintain ongoing web applications</li>
                    <li>Write clean, maintainable, and efficient code</li>
                    <li>Collaborate with cross-functional teams</li>
                    <li>Participate in code reviews and technical discussions</li>
                </ul>
            </div>
            <a href="https://www.linkedin.com/company/taxme-ai/jobs" target="_blank" rel="noopener noreferrer"
               className="apply-button">Apply on LinkedIn</a>
        </motion.div>

        <motion.div className="job-card" whileHover={{scale: 1.02}}>
            <h3>FastAPI Software Engineer - Entry Level</h3>
            <p className="positions-available">1 Position Available</p>
            <div className="job-details">
                <h4>Requirements:</h4>
                <ul>
                    <li>Bachelor's degree in Computer Science or related field</li>
                    <li>Experience with FastAPI and Python</li>
                    <li>Understanding of RESTful APIs</li>
                    <li>Knowledge of SQL and database design</li>
                </ul>
                <h4>Responsibilities:</h4>
                <ul>
                    <li>Design and implement API endpoints</li>
                    <li>Optimize API performance and reliability</li>
                    <li>Write comprehensive API documentation</li>
                    <li>Implement security best practices</li>
                </ul>
            </div>
            <a href="https://www.linkedin.com/company/taxme-ai/jobs" target="_blank" rel="noopener noreferrer"
               className="apply-button">Apply on LinkedIn</a>
        </motion.div>

        <motion.div className="job-card" whileHover={{scale: 1.02}}>
            <h3>Data Engineer - Entry Level</h3>
            <p className="positions-available">3 Positions Available</p>
            <div className="job-details">
                <h4>Requirements:</h4>
                <ul>
                    <li>Bachelor's degree in Computer Science, Data Science, or related field</li>
                    <li>Experience with SQL and data warehousing</li>
                    <li>Knowledge of Python and data processing tools</li>
                    <li>Understanding of ETL processes</li>
                </ul>
                <h4>Responsibilities:</h4>
                <ul>
                    <li>Design and implement data pipelines</li>
                    <li>Optimize data storage and retrieval</li>
                    <li>Ensure data quality and reliability</li>
                    <li>Collaborate with data science team</li>
                </ul>
            </div>
            <a href="https://www.linkedin.com/company/taxme-ai/jobs" target="_blank" rel="noopener noreferrer"
               className="apply-button">Apply on LinkedIn</a>
        </motion.div>

        <motion.div className="job-card" whileHover={{scale: 1.02}}>
            <h3>Marketing Analyst</h3>
            <p className="positions-available">4 Positions Available</p>
            <div className="job-details">
                <h4>Requirements:</h4>
                <ul>
                    <li>3 years of marketing analytics experience</li>
                    <li>Strong background in digital marketing</li>
                    <li>Experience with marketing analytics tools</li>
                    <li>Data visualization and reporting skills</li>
                </ul>
                <h4>Responsibilities:</h4>
                <ul>
                    <li>Analyze marketing campaign performance</li>
                    <li>Develop marketing strategies</li>
                    <li>Create comprehensive performance reports</li>
                    <li>Optimize marketing ROI</li>
                </ul>
            </div>
            <a href="https://www.linkedin.com/company/taxme-ai/jobs" target="_blank" rel="noopener noreferrer"
               className="apply-button">Apply on LinkedIn</a>
        </motion.div>

        <motion.div className="job-card" whileHover={{scale: 1.02}}>
            <h3>Data Analyst</h3>
            <p className="positions-available">2 Positions Available</p>
            <div className="job-details">
                <h4>Requirements:</h4>
                <ul>
                    <li>1-2 years of data analysis experience</li>
                    <li>Strong SQL and Python skills</li>
                    <li>Experience with data visualization tools</li>
                    <li>Statistical analysis background</li>
                </ul>
                <h4>Responsibilities:</h4>
                <ul>
                    <li>Analyze complex datasets</li>
                    <li>Create actionable insights</li>
                    <li>Develop automated reporting solutions</li>
                    <li>Support data-driven decision making</li>
                </ul>
            </div>
            <a href="https://www.linkedin.com/company/taxme-ai/jobs" target="_blank" rel="noopener noreferrer"
               className="apply-button">Apply on LinkedIn</a>
        </motion.div>
    </div>
);

const JoinUs = () => {
    const navigate = useNavigate();

    return (
        <div className="join-us-page">
            <GalaxyBackground/>

            <motion.button
                className="back-button"
                onClick={() => navigate('/')}
                whileHover={{x: -5}}
            >
                <FiArrowLeft size={24}/>
                <span>Back</span>
            </motion.button>

            <div className="join-us-container">
                <div className="join-us-header">
                    <motion.img
                        src="/tax-logo-dark.svg"
                        alt="TaxMe AI Logo"
                        className="join-us-logo"
                        animate={{rotate: 360}}
                        transition={{
                            duration: 20,
                            repeat: Infinity,
                            ease: "linear"
                        }}
                    />
                    <h1>Join Our Team</h1>
                </div>

                <div className="join-us-content">
                    <section className="culture-section">
                        <h2><FiUsers/> Our Culture</h2>
                        <p>At TaxMe AI, we're revolutionizing tax services and financial intelligence through innovative
                            AI solutions. Join our dynamic team of engineers, data scientists, and financial experts as
                            we build the future of tax and investment analysis.</p>

                        <div className="values-grid">
                            <div className="value-card">
                                <h3>Innovation First</h3>
                                <p>Pioneer new solutions in AI and financial technology</p>
                            </div>
                            <div className="value-card">
                                <h3>Collaborative Spirit</h3>
                                <p>Work alongside experts in AI, tax, and technology</p>
                            </div>
                            <div className="value-card">
                                <h3>Growth Mindset</h3>
                                <p>Continuous learning and professional development</p>
                            </div>
                            <div className="value-card">
                                <h3>Work-Life Balance</h3>
                                <p>Flexible schedules and hybrid work options</p>
                            </div>
                        </div>
                    </section>

                    <BenefitsSection/>

                    <section className="openings-section">
                        <h2><FiBriefcase/> Open Positions</h2>
                        <JobPostings/>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default JoinUs;