import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import {toast} from 'react-hot-toast';
import axios from 'axios';
import API_URL, {ENDPOINTS} from '../../utils/config/api';
import '../../styles/Auth/ResetPassword.css';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const token = searchParams.get('token');

    useEffect(() => {
        if (!token) {
            toast.error('Invalid reset link');
            navigate('/');
        }
    }, [token, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        if (password.length < 8) {
            toast.error('Password must be at least 8 characters');
            return;
        }

        setLoading(true);

        try {
            await axios.post(`${API_URL}${ENDPOINTS.RESET_PASSWORD}`, {
                token,
                new_password: password,
                confirm_password: confirmPassword
            });

            toast.success('Password reset successfully please login with your new credentials');
            navigate('/');
        } catch (error) {
            console.error('Password reset error:', error);
            toast.error(error.response?.data?.detail || 'Failed to reset password');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 px-4">
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                className="w-full max-w-md p-6 bg-gray-800 rounded-lg shadow-xl"
            >
                <h2 className="text-2xl font-bold text-white mb-6">Reset Your Password</h2>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-300 mb-1">
                            New Password
                        </label>
                        <input
                            type="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                                     text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter new password"
                            minLength={8}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-300 mb-1">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                                     text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500"
                            placeholder="Confirm new password"
                            minLength={8}
                        />
                    </div>

                    <motion.button
                        type="submit"
                        disabled={loading}
                        className="w-full px-4 py-2 bg-blue-600 rounded-lg text-white
                                 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                        whileHover={{scale: 1.02}}
                        whileTap={{scale: 0.98}}
                    >
                        {loading ? 'Resetting...' : 'Reset Password'}
                    </motion.button>
                </form>
            </motion.div>
        </div>
    );
};

export default ResetPassword;
