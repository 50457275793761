// File: frontend/src/pages/TaxCalculator/components/TaxAnalysis.jsx
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import {Card} from '../../../components/ui/card';
import {Button} from '../../../components/ui/button';
import {AlertCircle, CheckCircle, Download, LockKeyhole} from 'lucide-react';
import axios from 'axios';
import API_URL, {ENDPOINTS} from '../../../utils/config/api';
import '../../../styles/TaxCalculator/TaxAnalysis.css';
import {SiSat1} from "react-icons/si";

// Import our new components
import {
    CreditReview,
    CurrentSituation,
    DeductionsAnalysis,
    ItemizedAnalysis,
    Recommendations,
    SavingsSummary,
    SelfEmploymentAnalysis
} from '../../../components/TaxAnalysis/sections';

const TaxAnalysis = () => {
    const [analysis, setAnalysis] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    // Add this with other state declarations
    const [showSubscriptionPrompt, setShowSubscriptionPrompt] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [showBackWarning, setShowBackWarning] = useState(false);
    const [hasTriedFree, setHasTriedFree] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const {federalResults, stateResults, summary} = location.state || {};

    useEffect(() => {
        // Check if user has used their free trial
        const hasUsedFreeAnalysis = localStorage.getItem('hasUsedFreeAnalysis');
        setHasTriedFree(!!hasUsedFreeAnalysis);

        // Function to get analysis data either from location state or stored data
        const getAnalysisData = () => {
            const storedData = sessionStorage.getItem('pendingAnalysis');

            if (!federalResults || !stateResults) {
                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    return {
                        federalResults: parsedData.federalResults,
                        stateResults: parsedData.stateResults,
                        summary: parsedData.summary,
                        analysis_id: parsedData.analysis_id
                    };
                } else {
                    navigate('/tax-calculator');
                    return null;
                }
            }

            return {
                federalResults,
                stateResults,
                summary,
                analysis_id: location.state?.analysis_id
            };
        };

        const analysisData = getAnalysisData();
        if (!analysisData) return;

        // In the useEffect
        const fetchAnalysis = async () => {
            try {
                setIsLoading(true);
                const token = localStorage.getItem('access_token');

                const response = await axios.post(
                    `${API_URL}${ENDPOINTS.TAX_ANALYSIS}`,
                    {
                        federal_tax: analysisData.federalResults,
                        state_tax: analysisData.stateResults,
                        summary: analysisData.summary,
                        analysis_id: analysisData.analysis_id
                    },
                    {
                        headers: {Authorization: `Bearer ${token}`}
                    }
                );

                console.log('Analysis response:', response.data);

                // Always set the analysis data and ID
                setAnalysis({
                    ...response.data,
                    analysis_id: location.state.analysis_id  // Ensure analysis_id is set
                });

                // Handle subscription status
                if (response.data.requires_subscription || response.data.subscription_expired) {
                    setSubscriptionStatus({is_active: false});
                    setShowSubscriptionPrompt(true);
                } else {
                    setSubscriptionStatus(response.data.subscription_status);
                    if (!hasUsedFreeAnalysis) {
                        localStorage.setItem('hasUsedFreeAnalysis', 'true');
                    }
                    // Clear stored data only on successful full analysis
                    sessionStorage.removeItem('pendingAnalysis');
                }
            } catch (err) {
                console.error('Analysis error:', err.response?.data || err);
                setError(err.response?.data?.detail || 'Failed to fetch analysis');
            } finally {
                setIsLoading(false);
            }
        };
        fetchAnalysis();
    }, [federalResults, stateResults, summary, navigate, location.state]);

    const handleBackClick = () => {
        setShowBackWarning(true);
    };

    const handleSubscribe = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const currentAnalysisId = analysis?.analysis_id || location.state?.analysis_id;

            if (!currentAnalysisId) {
                setError('No analysis ID available');
                return;
            }

            // Store current analysis state
            sessionStorage.setItem('pendingAnalysis', JSON.stringify({
                federalResults,
                stateResults,
                summary,
                analysis_id: currentAnalysisId
            }));

            const response = await axios.post(
                `${API_URL}${ENDPOINTS.SUBSCRIPTION_CREATE}`,
                {analysis_id: currentAnalysisId},
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );

            if (response.data?.payment_url) {
                window.location.href = response.data.payment_url;
            } else {
                setError('Payment URL not received');
            }

        } catch (err) {
            console.error('Subscription error:', err);
            setError('Failed to initiate subscription');
        }
    };


    // Add the new renderFormattedContent function here
    const renderFormattedContent = (text) => {
        if (!text) return null;

        return text.split('\n').map((line, index) => {
            // Handle section headers (now with larger spacing and background)
            if (line.includes('**')) {
                const headerContent = line.match(/\*\*(.*?)\*\*/);
                if (headerContent) {
                    // Check if it's a main section header (numbered)
                    if (line.match(/^\*\*\d+\./)) {
                        return (
                            <div key={index} className="my-8 bg-gray-800/30 p-4 rounded-full">
                                <h2 className="text-2xl font-bold text-white">
                                    {headerContent[1]}
                                </h2>
                            </div>
                        );
                    } else {
                        // Subsection headers
                        return (
                            <h3 key={index} className="text-lg font-bold my-4 text-gray-200">
                                {headerContent[1]}
                            </h3>
                        );
                    }
                }
            }

            // Handle colored amounts with improved formatting
            line = line.replace(/\$[\d,]+(\.\d{2})?/g, match => {
                if (line.toLowerCase().includes('federal tax:') ||
                    line.toLowerCase().includes('state tax:')) {
                    return `<span class="text-red-500 font-bold">${match}</span>`;
                } else if (line.toLowerCase().includes('total income:')) {
                    return `<span class="text-yellow-400 font-bold">${match}</span>`;
                } else if (line.toLowerCase().includes('savings:') ||
                    line.toLowerCase().includes('benefit:') ||
                    line.toLowerCase().includes('potential:') ||
                    line.toLowerCase().includes('credit:') ||
                    line.toLowerCase().includes('taxable income:')) {
                    return `<span class="text-green-400 font-bold">${match}</span>`;
                }
                return match;
            });

            // Handle calculations with improved styling
            if (line.toLowerCase().includes('calculation:')) {
                return (
                    <div key={index} className="bg-gray-800/50 p-4 rounded-full my-4 border-l-4 border-yellow-400">
                        <span className="text-yellow-300 font-mono">{line}</span>
                    </div>
                );
            }

            // Handle bullet points and lists
            if (line.trim().startsWith('-') || line.trim().startsWith('*')) {
                return (
                    <div key={index} className="ml-4 mb-2 flex items-start">
                        <span className="mr-2 text-gray-400">•</span>
                        <span dangerouslySetInnerHTML={{__html: line.replace(/^[-*]\s/, '')}}/>
                    </div>
                );
            }

            // Handle recommendation sections with special styling
            if (line.toLowerCase().includes('recommendation:')) {
                return (
                    <div key={index} className="bg-blue-900/30 p-4 rounded-lg my-4 border-l-4 border-blue-400">
                        <span className="text-blue-300 font-medium"
                              dangerouslySetInnerHTML={{__html: line}}/>
                    </div>
                );
            }

            // Regular text
            return (
                <div
                    key={index}
                    className="mb-4"
                    dangerouslySetInnerHTML={{__html: line}}
                />
            );
        });
    };

    // Loading state
    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <motion.div
                    className="mb-4" // Added margin for spacing
                    animate={{rotate: 360}}
                    transition={{duration: 1, repeat: Infinity, ease: "linear"}}
                >
                    <SiSat1 size={45} alt="Loading"/> {/* Added size 45 */}
                </motion.div>
                <p className="text-gray-600 text-center">
                    Please seat tight while we look for loopholes in your taxes
                    <br/>
                    and additional tax benefits you might qualify for.
                </p>
            </div>
        );
    }

    // Error state
    if (error) {
        return (
            <motion.div
                className="max-w-4xl mx-auto p-6"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
            >
                <Card className="p-6 bg-red-900/30 border-red-500/50">
                    <div className="text-center">
                        <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4"/>
                        <h2 className="text-xl font-bold text-white mb-4">Error</h2>
                        <p className="text-gray-300 mb-6">{error}</p>
                        <Button
                            onClick={() => navigate('/tax-calculator')}
                            className="bg-blue-600 hover:bg-blue-700"
                        >
                            Return to Calculator
                        </Button>
                    </div>
                </Card>
            </motion.div>
        );
    }

    return (
        <motion.div
            className="max-w-4xl mx-auto p-6 space-y-8"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
        >
            {/* Back Navigation Warning */}
            {showBackWarning && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <Card className="p-6 max-w-md">
                        <h3 className="text-xl font-bold mb-4">Before You Leave</h3>
                        <p className="mb-6">
                            Make sure to download your analysis before leaving.
                            You won't be able to view this analysis again.
                        </p>
                        <div className="flex justify-end gap-4">
                            <Button
                                variant="outline"
                                onClick={() => setShowBackWarning(false)}
                            >
                                Stay Here
                            </Button>
                            <Button
                                className="bg-blue-600"
                                onClick={() => navigate('/tax-calculator')}
                            >
                                Leave Anyway
                            </Button>
                        </div>
                    </Card>
                </div>
            )}

            {/* Subscription Status */}
            <Card className="p-4 bg-blue-900/30 border-blue-500/50">
                <div className="flex items-center justify-between">
                    <div>
                        <p className="text-gray-300 text-sm">
                            Analyses Token remaining: {' '}
                            <span
                                className={subscriptionStatus?.uses_remaining <= 0 ? "text-red-500 font-bold" : "text-gray-300"}>
                    {subscriptionStatus?.uses_remaining}
                </span>
                            {' '}| Days remaining: {subscriptionStatus?.days_remaining}
                        </p>
                    </div>
                    <CheckCircle className="text-green-400 h-5 w-5"/>
                </div>
            </Card>

            {/* Quick Stats */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <Card className="p-4 bg-blue-900/30">
                    <h3 className="text-sm font-medium text-gray-400"> Current Tax Liability</h3>
                    <p className="text-2xl font-bold text-red-500">
                        ${(parseFloat(federalResults.net_tax) + parseFloat(stateResults.net_tax)).toFixed(2)}
                    </p>
                </Card>

                <Card className="p-4 bg-green-900/30">
                    <h3 className="text-sm font-medium text-white-400">Potential Savings Found from Your Taxes</h3>
                    <p className="text-2xl font-bold text-green-400">
                        ${((parseFloat(federalResults.net_tax) + parseFloat(stateResults.net_tax)) * 0.15).toFixed(2)}
                    </p>
                </Card>

                <Card className="p-4 bg-blue-900/30">
                    <h3 className="text-sm font-medium text-white-400">Total Annual Income</h3>
                    <p className="text-2xl font-bold text-yellow-400">
                        ${parseFloat(summary.total_income).toFixed(2)}
                    </p>
                </Card>
            </div>

            {/* Main Analysis Content */}
            <div className="relative">
                {/* Regular content */}
                <div className="space-y-8">
                    {/* Analysis Sections */}
                    {analysis?.analysis && (
                        <>
                            <SavingsSummary data={analysis.analysis}/>
                            <CurrentSituation data={analysis.analysis}/>
                            <DeductionsAnalysis data={analysis.analysis}/>
                            <SelfEmploymentAnalysis data={analysis.analysis}/>
                            <ItemizedAnalysis data={analysis.analysis}/>
                            <Recommendations data={analysis.analysis}/>
                            <CreditReview data={analysis.analysis}/>
                        </>
                    )}

                    {/* IRS Information */}
                    {analysis?.web_results && (
                        <Card className="p-6 bg-blue-900/30">
                            <h3 className="text-xl font-bold text-white mb-4">
                                Relevant IRS Information
                            </h3>
                            <div className="prose prose-invert max-w-none">
                                {analysis.web_results.content.split('\n').map((line, index) => (
                                    <p key={index} className="mb-2">{line}</p>
                                ))}
                            </div>
                            {analysis.web_results.sources && (
                                <div className="mt-4 pt-4 border-t border-gray-700">
                                    <h4 className="text-sm font-medium text-gray-400 mb-2">Sources:</h4>
                                    <ul className="space-y-1">
                                        {analysis.web_results.sources.map((source, index) => (
                                            <li key={index}>
                                                <a
                                                    href={source.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-400 hover:text-blue-300 text-sm"
                                                >
                                                    {source.title}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </Card>
                    )}

                    {/* Action Buttons */}
                    <div className="flex gap-4">
                        <Button
                            onClick={handleBackClick}
                            variant="outline"
                            className="border-gray-700 text-gray-300 rounded-full"
                            disabled={showSubscriptionPrompt}
                        >
                            Back to Results
                        </Button>
                        <Button
                            onClick={() => window.print()}
                            className="bg-blue-600 hover:bg-blue-700 rounded-full"
                            disabled={showSubscriptionPrompt}
                        >
                            <Download className="mr-2"/>
                            Download Analysis
                        </Button>
                    </div>
                </div>

                {/* Subscription Overlay */}
                {showSubscriptionPrompt && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 p-4 bg-black/50">
                        <Card className="w-full max-w-md text-center bg-gray-600/70 border border-gray-700 shadow-xl">
                            <div className="p-4 sm:p-6">
                                <LockKeyhole className="w-12 h-12 mx-auto text-yellow-400 mb-3"/>
                                <h3 className="text-lg font-bold mb-3">
                                    {subscriptionStatus?.uses_remaining <= 0
                                        ? "Oops! No More Analysis Tokens"
                                        : "Unlock Your Savings Potential Now!"}
                                </h3>
                                <p className="mb-4 text-sm text-white-450">
                                    {subscriptionStatus?.uses_remaining <= 0
                                        ? "You've used all your analysis tokens. Purchase more to continue accessing detailed tax analysis."
                                        : (
                                            <>
                                                Discover how to claim
                                                <span className="text-green-400 font-bold">
                                {' '}+${Intl.NumberFormat().format(((parseFloat(federalResults.net_tax) + parseFloat(stateResults.net_tax)) * 0.15).toFixed(2))}
                            </span>
                                                {' '}in tax savings!
                                                <br/>
                                                <span className="text-sm opacity-90">
                                For just $9.99, get 5 analysis tokens to unlock detailed breakdowns 
                                of your biggest savings opportunities. Which can be used upto 5 times.
                            </span>
                                            </>
                                        )}
                                </p>
                                <Button
                                    onClick={handleSubscribe}
                                    className="w-full bg-gradient-to-r from-green-500 to-blue-500
                             hover:from-green-600 hover:to-blue-600 py-2"
                                >
                                    Get Tokens Now - $9.99
                                </Button>
                            </div>
                        </Card>
                    </div>
                )}
            </div>

        </motion.div>
    );
};

export default TaxAnalysis; 