// src/components/TaxAnalysis/sections/DeductionsAnalysis.jsx
import React from 'react';
import {Card} from '../../../components/ui/card';
import {motion} from 'framer-motion';
import {
    Calculator,
    CheckCircle,
    AlertCircle,
    ArrowRight,
    DollarSign,
    List
} from 'lucide-react';

export const DeductionsAnalysis = ({data}) => {
    const formatCurrency = (amount) => {
        if (!amount) return '$0.00';
        const cleanAmount = amount.toString().replace(/[^0-9.]/g, '');
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(parseFloat(cleanAmount));
    };

    // Parse deductions data
    const parseDeductions = (content) => {
        if (!content) return [];

        const deductions = [];
        const sections = content.toString().split(/(?=\s*-\s*\*\*[^*]+\*\*)/);

        sections.forEach(section => {
            const nameMatch = section.match(/\*\*([^*]+)\*\*/);
            if (!nameMatch) return;

            const name = nameMatch[1];
            const eligibilityMatch = section.match(/Eligibility[^:]*:\s*([^.]+)/i);
            const calculationMatch = section.match(/Calculation:\s*([^.]+)/i);
            const savingsMatch = section.match(/Potential Savings[^:]*:\s*\$?([\d,.]+)/i);
            const stepsMatch = section.match(/Steps[^:]*:\s*([^.]+[.]?)/i);

            deductions.push({
                name,
                eligibility: eligibilityMatch ? eligibilityMatch[1].trim() : '',
                calculation: calculationMatch ? calculationMatch[1].trim() : '',
                potentialSavings: savingsMatch ? savingsMatch[1] : '0',
                steps: stepsMatch ? stepsMatch[1].trim() : ''
            });
        });

        return deductions;
    };

    const deductions = parseDeductions(data);

    return (
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
        >
            <Card className="p-6 bg-gradient-to-r from-purple-900/30 to-blue-900/30">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold text-white flex items-center">
                        <List className="w-8 h-8 mr-2 text-purple-400"/>
                        Unclaimed Deductions Analysis
                    </h2>
                    <Calculator className="w-6 h-6 text-purple-400"/>
                </div>

                <div className="space-y-6">
                    {deductions.map((deduction, index) => (
                        <motion.div
                            key={index}
                            initial={{x: -20, opacity: 0}}
                            animate={{x: 0, opacity: 1}}
                            transition={{delay: index * 0.1}}
                            className="bg-gray-900/50 p-4 rounded-lg border border-purple-500/30"
                        >
                            {/* Deduction Header */}
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="text-lg font-semibold text-white flex items-center">
                                    {deduction.name}
                                </h3>
                                <span className="text-green-400 font-bold flex items-center">
                                    <DollarSign className="w-4 h-4 mr-1"/>
                                    {formatCurrency(deduction.potentialSavings)}
                                </span>
                            </div>

                            {/* Deduction Details */}
                            <div className="space-y-3">
                                {deduction.eligibility && (
                                    <div className="flex items-start">
                                        <div className="mt-1">
                                            {deduction.eligibility.toLowerCase().includes('not') ? (
                                                <AlertCircle className="w-5 h-5 text-yellow-500 mr-2"/>
                                            ) : (
                                                <CheckCircle className="w-5 h-5 text-green-500 mr-2"/>
                                            )}
                                        </div>
                                        <div>
                                            <p className="text-sm text-gray-300">
                                                <span className="text-gray-400">Eligibility: </span>
                                                {deduction.eligibility}
                                            </p>
                                        </div>
                                    </div>
                                )}

                                {deduction.calculation && (
                                    <div className="bg-yellow-900/20 p-3 rounded border border-yellow-500/30">
                                        <p className="text-sm text-yellow-300">
                                            <span className="text-yellow-400">Calculation: </span>
                                            {deduction.calculation}
                                        </p>
                                    </div>
                                )}

                                {deduction.steps && (
                                    <div className="bg-blue-900/20 p-3 rounded border border-blue-500/30">
                                        <h4 className="text-sm font-medium text-blue-400 mb-2">Implementation
                                            Steps:</h4>
                                        <div className="flex items-start">
                                            <ArrowRight className="w-4 h-4 text-blue-400 mr-2 mt-0.5"/>
                                            <p className="text-sm text-gray-300">{deduction.steps}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </motion.div>
                    ))}

                    {deductions.length === 0 && (
                        <div className="text-center py-8">
                            <AlertCircle className="w-12 h-12 text-gray-500 mx-auto mb-3"/>
                            <p className="text-gray-400">No unclaimed deductions identified for your current tax
                                situation.</p>
                        </div>
                    )}
                </div>
            </Card>
        </motion.div>
    );
};
