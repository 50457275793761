// src/components/TaxAnalysis/sections/SavingsSummary.jsx
import React from 'react';
import {Card} from '../../../components/ui/card';
import {motion} from 'framer-motion';
import {DollarSign, TrendingUp} from 'lucide-react';

export const SavingsSummary = ({data}) => {
    // Handle different potential formats of savings data
    const extractSavings = (content) => {
        // Try different patterns that might appear in the text
        const patterns = [
            /\$\*\*([\d,]+\.\d{2})\*\*/,                   // $**1,234.56**
            /Total potential savings: \$([0-9,.]+)/i,       // Total potential savings: $1,234.56
            /POTENTIAL SAVINGS:?\s*\$([0-9,.]+)/i,         // POTENTIAL SAVINGS: $1,234.56
            /savings of \$([0-9,.]+)/i,                    // savings of $1,234.56
            /\$([0-9,.]+) in potential savings/i           // $1,234.56 in potential savings
        ];

        for (const pattern of patterns) {
            const match = content.match(pattern);
            if (match) return match[1];
        }

        return '0.00';
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(parseFloat(amount.replace(/,/g, '')));
    };

    const savingsAmount = extractSavings(data || '');
    const displayAmount = formatCurrency(savingsAmount);

    return (
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
        >
            <Card className="p-6 bg-gradient-to-r from-green-900/30 to-blue-900/30 border-green-500/50">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-2xl font-bold text-white flex items-center">
                        <DollarSign className="w-8 h-8 mr-2 text-green-400"/>
                        Total Potential Savings
                    </h2>
                    <TrendingUp className="w-6 h-6 text-green-400"/>
                </div>

                <div className="flex flex-col items-center justify-center py-6">
                    <motion.span
                        className="text-5xl font-bold text-green-400"
                        initial={{scale: 0.5}}
                        animate={{scale: 1}}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        {displayAmount}
                    </motion.span>
                    <p className="text-gray-400 mt-2">Identified Tax Savings Opportunities</p>
                </div>

                {parseFloat(savingsAmount) > 0 && (
                    <div className="mt-4 bg-green-900/20 p-4 rounded-lg border border-green-500/30">
                        <p className="text-sm text-green-300">
                            💡 These savings opportunities are based on our analysis of your tax situation.
                            See detailed breakdowns below.
                        </p>
                    </div>
                )}
            </Card>
        </motion.div>
    );
};
