// // src/utils/config/api.js

// Base API URL - no trailing slash
const API_URL = 'https://taxmeai.com';

// Service-specific endpoints
export const ENDPOINTS = {
    // Auth endpoints
    LOGIN: '/taxmeai-login',
    SIGNUP: '/taxmeai-signup',
    VERIFY: '/taxmeai-verify',
    RESEND_VERIFICATION: '/taxmeai-resend-verification',
    PROFILE: '/taxmeai-profile',
    GOOGLE_AUTH: '/auth/google',
    REQUEST_RESET: '/auth/request-reset',
    RESET_PASSWORD: '/auth/reset-password',

    // Update these endpoints to match your backend router prefix
    STOCK_ANALYZE: '/api/taxmeai-stock-ai/analyze',
    STOCK_MARKET_ANALYSIS: '/api/taxmeai-stock-ai/market-analysis',
    STOCK_ANALYSIS_INIT: '/api/taxmeai-stock-ai/analysis/init',  // Updated
    STOCK_SUBSCRIPTION_CREATE: '/api/taxmeai-stock-ai/subscription/create',
    STOCK_SUBSCRIPTION_VERIFY: '/api/taxmeai-stock-ai/subscription/verify',
    STOCK_SUBSCRIPTION_STATUS: '/api/taxmeai-stock-ai/subscription/status',
    STOCK_STRIPE_WEBHOOK: '/api/webhook/stripe',

    // Tax calculator endpoints
    BASE: '/api/tax',
    CALCULATE_CA: '/api/tax/calculate/california',
    CALCULATE_WY: '/api/tax/calculate/wyoming',
    CALCULATE_TN: '/api/tax/calculate/tennessee',
    CALCULATE_SD: '/api/tax/calculate/south-dakota',
    CALCULATE_NH: '/api/tax/calculate/new-hampshire',
    CALCULATE_NV: '/api/tax/calculate/nevada',
    CALCULATE_WI: '/api/tax/calculate/wisconsin',
    CALCULATE_WV: '/api/tax/calculate/west-virginia',
    CALCULATE_VA: '/api/tax/calculate/virginia',
    CALCULATE_VT: '/api/tax/calculate/vermont',
    CALCULATE_UT: '/api/tax/calculate/utah',
    CALCULATE_SC: '/api/tax/calculate/south-carolina',
    CALCULATE_RI: '/api/tax/calculate/rhode-island',
    CALCULATE_OR: '/api/tax/calculate/oregon',
    CALCULATE_OK: '/api/tax/calculate/oklahoma',
    CALCULATE_NE: '/api/tax/calculate/nebraska',
    CALCULATE_ND: '/api/tax/calculate/north-dakota',
    CALCULATE_NM: '/api/tax/calculate/new-mexico',
    CALCULATE_MT: '/api/tax/calculate/montana',
    CALCULATE_MO: '/api/tax/calculate/missouri',
    CALCULATE_OH: '/api/tax/calculate/ohio',
    CALCULATE_MS: '/api/tax/calculate/mississippi',
    CALCULATE_MN: '/api/tax/calculate/minnesota',
    CALCULATE_MI: '/api/tax/calculate/michigan',
    CALCULATE_MA: '/api/tax/calculate/massachusetts',
    CALCULATE_ME: '/api/tax/calculate/maine',
    CALCULATE_NC: '/api/tax/calculate/north-carolina',
    CALCULATE_CT: '/api/tax/calculate/connecticut',
    CALCULATE_DE: '/api/tax/calculate/delaware',
    CALCULATE_GA: '/api/tax/calculate/georgia',
    CALCULATE_KS: '/api/tax/calculate/kansas',
    CALCULATE_IA: '/api/tax/calculate/iowa',
    CALCULATE_IL: '/api/tax/calculate/illinois',
    CALCULATE_ID: '/api/tax/calculate/idaho',
    CALCULATE_IN: '/api/tax/calculate/indiana',
    CALCULATE_LA: '/api/tax/calculate/louisiana',
    CALCULATE_KY: '/api/tax/calculate/kentucky',
    CALCULATE_HI: '/api/tax/calculate/hawaii',
    CALCULATE_FL: '/api/tax/calculate/florida',
    CALCULATE_NY: '/api/tax/calculate/new-york',
    CALCULATE_WA: '/api/tax/calculate/washington',
    CALCULATE_TX: '/api/tax/calculate/texas',
    CALCULATE_CO: '/api/tax/calculate/colorado',
    CALCULATE_PA: '/api/tax/calculate/pennsylvania',
    CALCULATE_MD: '/api/tax/calculate/maryland',
    CALCULATE_AK: '/api/tax/calculate/alaska',
    CALCULATE_DC: '/api/tax/calculate/dc',
    CALCULATE_NJ: '/api/tax/calculate/new-jersey',
    CALCULATE_AR: '/api/tax/calculate/arkansas',
    CALCULATE_AZ: '/api/tax/calculate/arizona',
    CALCULATE_AL: '/api/tax/calculate/alabama',
    HISTORY: '/history',
    CALC_ID: '/api/tax/calculations/{calc_id}',
    TAX_SUMMARY: '/api/tax/summary/{calc_id}',

    TAX_ANALYSIS: '/api/tax/analyze',
    SUBSCRIPTION_STATUS: '/api/subscription/status',
    SUBSCRIPTION_CREATE: '/api/subscription/create',
    SUBSCRIPTION_VERIFY: '/api/subscription/verify',
    TAX_ANALYSIS_INIT: '/api/tax/analysis/init',
    STRIPE_WEBHOOK: '/api/webhook/stripe',

    // Chat endpoints
    CHAT: '/taxmeai-chat',
    CHAT_HISTORY: '/taxmeai-history',
    UPLOAD_DOCUMENT: '/upload-document'
};

// Tax calculation constants
export const TAX_CONSTANTS = {
    SUPPORTED_STATES: {
        CA: {
            name: 'California',
            hasStateTax: true,
            description: 'California uses a progressive tax system with nine tax brackets.',
            features: ['State tax credits', 'Renter\'s credit', 'Social security exempt']
        },
        NC: {
            name: 'North Carolina',
            hasStateTax: true,
            description: 'North Carolina uses a flat tax rate system.',
            features: ['Flat tax rate', 'Limited credits', 'Follows federal rules']
        },
        GA: {
            name: 'Georgia',
            hasStateTax: true,
            description: 'Georgia uses a progressive tax system with six brackets.',
            features: [
                'Progressive tax system',
                'Retirement income exclusions',
                'State tax credits available',
                'Standard deduction allowed'
            ]
        },
        FL: {
            name: 'Florida',
            hasStateTax: true,
            description: 'Florida does not have state income tax',
            features: ['Zero Income tax', 'State specific credit']
        },
        NY: {
            name: 'New York',
            hasStateTax: true,
            description: 'New York uses a progressive tax rate system',
            features: ['Progressive tax system']
        },
        WA: {
            name: 'Washington',
            hasStateTax: true,
            description: 'Washington does not have state income tax',
            features: ['Zero Income tax', 'State specific credit']
        },
        TX: {
            name: 'Texas',
            hasStateTax: true,
            description: 'Texas does not have state income tax',
            features: ['Zero Income tax', 'State specific credit']
        },
        CO: {
            name: 'Colorado',
            hasStateTax: true,
            description: 'Colorado uses a flat tax rate of 4.4% with various credits and deductions.',
            features: [
                'Single flat tax rate of 4.4%',
                'Follows federal deductions',
                'State earned income tax credit',
                'Child care contribution credit'
            ],
        },
        PA: {
            name: 'Pennsylvania',
            hasStateTax: true,
            description: 'Pennsylvania uses a flat tax rate of 3.07% with tax forgiveness program.',
            features: [
                'Single flat tax rate of 3.07%',
                'No standard deduction',
                'Tax forgiveness program',
                'Retirement income exclusion'
            ],
        },
        MD: {
            name: 'Maryland',
            hasStateTax: true,
            description: 'Progressive tax system with local taxes included',
            features: [
                'Eight progressive tax brackets',
                'Combined state and local taxes',
                'Multiple credits available',
                'Military pay exemptions'
            ],
        },
        DC: {
            name: 'District of Columbia',
            hasStateTax: true,
            description: 'Progressive tax system tailored for the capital region',
            features: [
                'Six progressive tax brackets',
                'High standard deduction and exemptions',
                'Credits for low-income residents',
                'No sales tax on groceries or prescription drugs'
            ],
        },
        NJ: {
            name: 'New Jersey',
            hasStateTax: true,
            description: 'Progressive tax system with seven brackets',
            features: [
                'Seven progressive tax brackets',
                'Property tax credits available',
                'Social Security benefits exempt',
                'Military pension exemption'
            ],
        }
    },
    TAX_YEARS: {
        CURRENT: 2024,
        AVAILABLE: [2024, 2023]
    },
    FILING_STATUSES: {
        SINGLE: 'single',
        MARRIED_JOINT: 'married_joint',
        MARRIED_SEPARATE: 'married_separate',
        HEAD_OF_HOUSEHOLD: 'head_of_household'
    }
};

export default API_URL;