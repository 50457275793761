// src/pages/Blog/EditBlogPost.jsx
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import BlogEditor from './components/BlogEditor';
import {blogService} from '../../services/blogService';
import {toast} from '../../components/ui/toast';

const EditBlogPost = () => {
    const [loading, setLoading] = useState(true);
    const [initialData, setInitialData] = useState(null);
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        fetchBlogPost();
    }, [id]);

    const fetchBlogPost = async () => {
        try {
            const data = await blogService.getBlogById(id);
            setInitialData(data);
        } catch (error) {
            toast({
                title: "Error",
                description: error.message || "Failed to fetch blog post",
                type: "error"
            });
            navigate('/admin/blog');
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (blogData) => {
        try {
            setLoading(true);
            const response = await blogService.updateBlog(id, blogData);
            toast({
                title: "Success",
                description: "Blog post updated successfully",
                type: "success"
            });
            navigate('/admin/blog');
        } catch (error) {
            toast({
                title: "Error",
                description: error.message || "Failed to update blog post",
                type: "error"
            });
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <BlogEditor
            onSave={handleSave}
            initialData={initialData}
            loading={loading}
        />
    );
};

export default EditBlogPost;