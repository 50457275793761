import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import {DollarSign, Heart, HelpCircle, Home, Landmark, Receipt} from 'lucide-react';
import '../../../../styles/TaxCalculator/steps/Deductions.css';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '../../../../components/ui/tooltip';
import {Card} from '../../../../components/ui/card';
import Layout from '../shared/Layout';

const Deductions = ({formData, setFormData, error}) => {
    const navigate = useNavigate();
    const {stateCode} = useParams();

    useEffect(() => {
        if (!formData.state || !formData.year || !formData.filing_status || !Object.values(formData.income).some(v => v > 0)) {
            navigate('/tax-calculator', {replace: true});
        }
    }, [formData, navigate]);

    const handleDeductionTypeChange = (type) => {
        setFormData(prev => ({
            ...prev,
            deductions: {
                ...prev.deductions,
                type,
                home_ownership: type === 'standard' ? null : prev.deductions.home_ownership
            }
        }));
    };

    const handleItemizedDeductionChange = (field, value) => {
        const numValue = parseFloat(value) || 0;
        setFormData(prev => ({
            ...prev,
            deductions: {
                ...prev.deductions,
                itemized: {
                    ...prev.deductions.itemized,
                    [field]: numValue
                }
            }
        }));
    };

    const handleHomeOwnershipChange = (field, value) => {
        const numValue = parseFloat(value) || 0;
        setFormData(prev => ({
            ...prev,
            deductions: {
                ...prev.deductions,
                home_ownership: {
                    ...(prev.deductions.home_ownership || {}),
                    [field]: numValue
                }
            }
        }));
    };

    const homeOwnershipFields = {
        mortgage_interest: {
            label: 'Mortgage Interest',
            tooltip: 'Interest paid on your primary residence mortgage',
            icon: Landmark
        },
        property_taxes: {
            label: 'Property Taxes',
            tooltip: 'Real estate taxes paid on your home',
            icon: Home
        },
        home_insurance: {
            label: 'Home Insurance',
            tooltip: 'Annual homeowner\'s insurance premiums',
            icon: Receipt
        },
        pmi_insurance: {
            label: 'PMI Insurance',
            tooltip: 'Private Mortgage Insurance premiums',
            icon: Receipt
        }
    };

    const itemizedFields = {
        medical: {
            label: 'Medical Expenses',
            tooltip: 'Out-of-pocket medical and dental expenses',
            icon: Receipt
        },
        state_local_tax: {
            label: 'State & Local Taxes',
            tooltip: 'State and local income or sales taxes paid',
            icon: Receipt
        },
        property_tax: {
            label: 'Other Property Tax',
            tooltip: 'Property taxes for other properties',
            icon: Home
        },
        charitable: {
            label: 'Charitable Contributions',
            tooltip: 'Donations to qualified organizations',
            icon: Heart
        }
    };

    const totalItemizedDeductions = Object.values(formData.deductions.itemized)
        .reduce((sum, val) => sum + (val || 0), 0);

    const totalHomeOwnership = formData.deductions.home_ownership
        ? Object.values(formData.deductions.home_ownership)
            .reduce((sum, val) => sum + (val || 0), 0)
        : 0;

    const standardDeductions = {
        single: 14600,
        married_joint: 29200,
        married_separate: 14600,
        head_of_household: 21900
    };

    const standardDeductionAmount = standardDeductions[formData.filing_status] || 12950;

    const handleNext = () => {
        navigate(`/tax-calculator/${stateCode}/credits`, {replace: true});
    };

    const handleBack = () => {
        navigate(`/tax-calculator/${stateCode}/income`, {replace: true});
    };

    return (
        <Layout formData={formData} setFormData={setFormData}>
            <TooltipProvider>
                <motion.div
                    className="deductions-container"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
                >
                    <div className="deduction-header">
                        <div className="header-content">
                            <h3>Tax Deductions</h3>
                            <p>Choose your deduction method</p>
                        </div>
                        <Tooltip>
                            <TooltipTrigger className="help-trigger">
                                <HelpCircle size={20}/>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Choose between standard deduction or itemize your deductions</p>
                            </TooltipContent>
                        </Tooltip>
                    </div>

                    {/* Deduction Type Selection */}
                    <Card className="deduction-type-card">
                        <div className="deduction-type-options">
                            <motion.button
                                type="button"
                                onClick={() => handleDeductionTypeChange('standard')}
                                className={`deduction-option ${formData.deductions.type === 'standard' ? 'selected' : ''}`}
                                whileHover={{scale: 1.02}}
                                whileTap={{scale: 0.98}}
                            >
                                <div className="option-content">
                                    <div className="option-title">Standard Deduction</div>
                                    <div className="option-amount">
                                        ${standardDeductionAmount.toLocaleString()}
                                    </div>
                                </div>
                            </motion.button>
                            <motion.button
                                type="button"
                                onClick={() => handleDeductionTypeChange('itemized')}
                                className={`deduction-option ${formData.deductions.type === 'itemized' ? 'selected' : ''}`}
                                whileHover={{scale: 1.02}}
                                whileTap={{scale: 0.98}}
                            >
                                <div className="option-content">
                                    <div className="option-title">Itemized Deductions</div>
                                    <div className="option-amount">
                                        ${(totalItemizedDeductions + totalHomeOwnership).toLocaleString()}
                                    </div>
                                </div>
                            </motion.button>
                        </div>
                    </Card>

                    {formData.deductions.type === 'itemized' && (
                        <>
                            <div className="itemized-section">
                                {Object.entries(itemizedFields).map(([field, {label, tooltip, icon: Icon}]) => (
                                    <Tooltip key={field}>
                                        <TooltipTrigger asChild>
                                            <motion.div className="deduction-input-group">
                                                <label className="deduction-label">
                                                    <Icon size={16}/>
                                                    <span>{label}</span>
                                                </label>
                                                <div className="input-with-icon">
                                                    <DollarSign size={16}/>
                                                    <input
                                                        type="number"
                                                        value={formData.deductions.itemized[field] || ''}
                                                        onChange={(e) => handleItemizedDeductionChange(field, e.target.value)}
                                                        className="deduction-input"
                                                        min="0"
                                                        step="0.01"
                                                        placeholder="0.00"
                                                    />
                                                </div>
                                            </motion.div>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>{tooltip}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                ))}
                            </div>

                            <Card className="home-ownership-card">
                                <div className="card-header">
                                    <Home className="header-icon"/>
                                    <h4>Home Ownership Deductions</h4>
                                </div>

                                <div className="home-ownership-grid">
                                    {Object.entries(homeOwnershipFields).map(([field, {
                                        label,
                                        tooltip,
                                        icon: Icon
                                    }]) => (
                                        <Tooltip key={field}>
                                            <TooltipTrigger asChild>
                                                <motion.div className="deduction-input-group">
                                                    <label className="deduction-label">
                                                        <Icon size={16}/>
                                                        <span>{label}</span>
                                                    </label>
                                                    <div className="input-with-icon">
                                                        <DollarSign size={16}/>
                                                        <input
                                                            type="number"
                                                            value={formData.deductions.home_ownership?.[field] || ''}
                                                            onChange={(e) => handleHomeOwnershipChange(field, e.target.value)}
                                                            className="deduction-input"
                                                            min="0"
                                                            step="0.01"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </motion.div>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    ))}
                                </div>
                            </Card>
                        </>
                    )}

                    <Card className="additional-deductions-card">
                        <h4>Additional Deductions</h4>
                        <div className="additional-deductions-grid">
                            <div className="deduction-input-group">
                                <label className="deduction-label">
                                    Student Loan Interest
                                    <span className="max-limit">Maximum: $2,500</span>
                                </label>
                                <div className="input-with-icon">
                                    <DollarSign size={16}/>
                                    <input
                                        type="number"
                                        value={formData.deductions.student_loan_interest || ''}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            deductions: {
                                                ...prev.deductions,
                                                student_loan_interest: parseFloat(e.target.value) || 0
                                            }
                                        }))}
                                        className="deduction-input"
                                        min="0"
                                        max="2500"
                                        step="0.01"
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>

                            <div className="deduction-input-group">
                                <label className="deduction-label">
                                    Self-Employed Health Insurance
                                </label>
                                <div className="input-with-icon">
                                    <DollarSign size={16}/>
                                    <input
                                        type="number"
                                        value={formData.deductions.self_employed_health_insurance || ''}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            deductions: {
                                                ...prev.deductions,
                                                self_employed_health_insurance: parseFloat(e.target.value) || 0
                                            }
                                        }))}
                                        className="deduction-input"
                                        min="0"
                                        step="0.01"
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>

                            <div className="deduction-input-group">
                                <label className="deduction-label">
                                    IRA Deduction
                                    <span className="max-limit">Maximum: $6,000</span>
                                </label>
                                <div className="input-with-icon">
                                    <DollarSign size={16}/>
                                    <input
                                        type="number"
                                        value={formData.deductions.ira_deduction || ''}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            deductions: {
                                                ...prev.deductions,
                                                ira_deduction: parseFloat(e.target.value) || 0
                                            }
                                        }))}
                                        className="deduction-input"
                                        min="0"
                                        max="6000"
                                        step="0.01"
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card className="total-deductions-card">
                        <div className="total-content">
                            <div className="total-text">
                                <h4>Total Deductions</h4>
                                <p>{formData.deductions.type === 'standard' ? 'Standard Deduction' : 'Itemized with Home Ownership'}</p>
                            </div>
                            <div className="total-amount">
                                <p>
                                    ${(formData.deductions.type === 'standard'
                                    ? standardDeductionAmount
                                    : totalItemizedDeductions + totalHomeOwnership).toLocaleString('en-US', {minimumFractionDigits: 2})}
                                </p>
                            </div>
                        </div>
                    </Card>

                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}

                    <motion.div
                        className="nav-buttons"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0.3}}
                    >
                        <motion.button
                            onClick={handleBack}
                            className="nav-button nav-button-back"
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                        >
                            Back to Income
                        </motion.button>
                        <motion.button
                            onClick={handleNext}
                            disabled={!formData.filing_status}
                            className="nav-button nav-button-next"
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                        >
                            Continue to Credits
                        </motion.button>
                    </motion.div>
                </motion.div>
            </TooltipProvider>
        </Layout>
    );
};

export default Deductions;
