import axios from "axios";
import API_URL, {ENDPOINTS, TAX_CONSTANTS} from "../config/api";

// Helper function to convert numbers to string decimals
const convertToStringDecimals = (obj) => {
    if (!obj) return obj;

    const result = {};
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === "number") {
            result[key] = value.toString();
        } else if (Array.isArray(value)) {
            result[key] = value.map((item) =>
                typeof item === "object" ? convertToStringDecimals(item) : item
            );
        } else if (typeof value === "object" && value !== null) {
            result[key] = convertToStringDecimals(value);
        } else {
            result[key] = value;
        }
    }
    return result;
};

// Format tax data for API consumption
const formatTaxData = (taxData) => ({
    year: taxData.year,
    state: taxData.state,
    filing_status: taxData.filing_status,
    income: {
        ...convertToStringDecimals(taxData.income),
        w2_info: taxData.income.w2_info?.map((w2) => ({
            box1: w2.box1.toString(),
            box2: w2.box2.toString(),
            box17: w2.box17.toString(),
        })),
    },
    deductions: taxData.deductions
        ? {
            type: taxData.deductions.type,
            itemized: convertToStringDecimals(taxData.deductions.itemized),
            home_ownership: taxData.deductions.home_ownership
                ? convertToStringDecimals(taxData.deductions.home_ownership)
                : null,
            student_loan_interest:
                taxData.deductions.student_loan_interest?.toString(),
            self_employed_health_insurance:
                taxData.deductions.self_employed_health_insurance?.toString(),
            ira_deduction: taxData.deductions.ira_deduction?.toString(),
        }
        : null,
    credits: taxData.credits
        ? {
            child_tax_credit: taxData.credits.child_tax_credit
                ? {num_children: taxData.credits.child_tax_credit.num_children}
                : null,
            child_care: taxData.credits.child_care
                ? {expenses: taxData.credits.child_care.expenses?.toString()}
                : null,
            retirement_savings: taxData.credits.retirement_savings
                ? {
                    has_retirement_plan:
                    taxData.credits.retirement_savings.has_retirement_plan,
                    contributions:
                        taxData.credits.retirement_savings.contributions?.toString(),
                }
                : null,
            education: taxData.credits.education
                ? {
                    type: taxData.credits.education.type,
                    expenses: taxData.credits.education.expenses?.toString(),
                }
                : null,
        }
        : null,
});

// Tax calculation service
export const TaxCalculationService = {
    // Calculate Georgia taxes
    // calculateGeorgiaTax: async (taxData) => {
    //   return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_GA, {
    //     ...taxData,
    //     state: "GA",
    //   });
    // },

    // Base calculation method (updated with Georgia support)
    calculateTax: async (endpoint, taxData) => {
        try {
            const formattedData = formatTaxData(taxData);
            console.log(`Formatted tax data for ${endpoint}:`, formattedData);

            const response = await axios.post(
                `${API_URL}${endpoint}`,
                formattedData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error("Tax calculation error:", error);
            if (error.response) {
                throw new Error(
                    error.response.data?.detail ||
                    (typeof error.response.data === "object"
                        ? JSON.stringify(error.response.data)
                        : "Failed to calculate taxes")
                );
            }
            throw new Error("Network error occurred while calculating taxes");
        }
    },

    // Calculate California taxes
    calculateCaliforniaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_CA, {
            ...taxData,
            state: "CA",
        });
    },
    // Calculate Wisconsin taxes
    calculateWisconsinTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_WI, {
            ...taxData,
            state: "WI",
        });
    },
    // Calculate Wyoming taxes
    calculateWyomingTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_WY, {
            ...taxData,
            state: "WY",
        });
    },
    // Calculate Tennessee taxes
    calculateTennesseeTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_TN, {
            ...taxData,
            state: "TN",
        });
    },
    // Calculate South Dakota taxes
    calculateSouthDakotaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_SD, {
            ...taxData,
            state: "SD",
        });
    },
    // Calculate New Hampshire taxes
    calculateNewHampshireTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_NH, {
            ...taxData,
            state: "NH",
        });
    },
    // Calculate Nevada taxes
    calculateNevadaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_NV, {
            ...taxData,
            state: "NV",
        });
    },
    // Calculate West Virginia taxes
    calculateWestVirginiaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_WV, {
            ...taxData,
            state: "WV",
        });
    },
    // Calculate Virginia taxes
    calculateVirginiaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_VA, {
            ...taxData,
            state: "VA",
        });
    },
    // Calculate Vermont taxes
    calculateVermontTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_VT, {
            ...taxData,
            state: "VT",
        });
    },
    // Calculate Utah taxes
    calculateUtahTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_UT, {
            ...taxData,
            state: "UT",
        });
    },
    // Calculate South Carolina taxes
    calculateSouthCarolinaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_SC, {
            ...taxData,
            state: "SC",
        });
    },
    // Calculate Rhode Island taxes
    calculateRhodeIslandTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_RI, {
            ...taxData,
            state: "RI",
        });
    },
    // Calculate Oregon taxes
    calculateOregonTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_OR, {
            ...taxData,
            state: "OR",
        });
    },
    // Calculate Oklahoma taxes
    calculateOklahomaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_OK, {
            ...taxData,
            state: "OK",
        });
    },
    // Calculate Nebraska taxes
    calculateNebraskaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_NE, {
            ...taxData,
            state: "NE",
        });
    },
    // Calculate North Dakota taxes
    calculateNorthDakotaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_ND, {
            ...taxData,
            state: "ND",
        });
    },
    // Calculate New Mexico taxes
    calculateNewMexicoTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_NM, {
            ...taxData,
            state: "NM",
        });
    },
    // Calculate Montana taxes
    calculateMontanaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_MT, {
            ...taxData,
            state: "MT",
        });
    },
    // Calculate Missouri taxes
    calculateMissouriTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_MO, {
            ...taxData,
            state: "MO",
        });
    },
    // Calculate Ohio taxes
    calculateOhioTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_OH, {
            ...taxData,
            state: "OH",
        });
    },
    // Calculate Mississippi taxes
    calculateMississippiTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_MS, {
            ...taxData,
            state: "MS",
        });
    },
    // Calculate Minnesota taxes
    calculateMinnesotaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_MN, {
            ...taxData,
            state: "MN",
        });
    },
    // Calculate Michigan taxes
    calculateMichiganTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_MI, {
            ...taxData,
            state: "MI",
        });
    },
    // Calculate Massachusetts taxes
    calculateMassachusettsTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_MA, {
            ...taxData,
            state: "MA",
        });
    },
    // Calculate Maine taxes
    calculateMaineTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_ME, {
            ...taxData,
            state: "ME",
        });
    },

    // Calculate Kansas taxes
    calculateKansasTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_KS, {
            ...taxData,
            state: "KS",
        });
    },
    // Calculate Iowa taxes
    calculateIowaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_IA, {
            ...taxData,
            state: "IA",
        });
    },

    // Calculate Idaho taxes
    calculateIdahoTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_ID, {
            ...taxData,
            state: "ID",
        });
    },
    // Calculate Alaska taxes
    calculateAlaskaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_AK, {
            ...taxData,
            state: "AK",
        });
    },

    // Calculate Indiana taxes
    calculateIndianaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_IN, {
            ...taxData,
            state: "IN",
        });
    },
    // Calculate Illinois taxes
    calculateIllinoisTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_IL, {
            ...taxData,
            state: "IL",
        });
    },
    // Calculate Louisiana taxes
    calculateLouisianaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_LA, {
            ...taxData,
            state: "LA",
        });
    },
    // Calculate Kentucky taxes
    calculateKentuckyTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_KY, {
            ...taxData,
            state: "KY",
        });
    },
    // Calculate Hawaii taxes
    calculateHawaiiTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_HI, {
            ...taxData,
            state: "HI",
        });
    },

    // Calculate Delaware taxes
    calculateDelawareTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_DE, {
            ...taxData,
            state: "DE",
        });
    },
    // Calculate Connecticut taxes
    calculateConnecticutTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_CT, {
            ...taxData,
            state: "CT",
        });
    },

    // Calculate Arkansas taxes
    calculateArkansasTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_AR, {
            ...taxData,
            state: "AR",
        });
    },

    // Calculate Arizona taxes
    calculateArizonaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_AZ, {
            ...taxData,
            state: "AZ",
        });
    },

    // Calculate Alabama taxes
    calculateAlabamaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_AL, {
            ...taxData,
            state: "AL",
        });
    },

    // Calculate Newyork taxes
    calculateNewYorkTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_NY, {
            ...taxData,
            state: "NY",
        });
    },

    // Calculate Maryland taxes
    calculateMarylandTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_MD, {
            ...taxData,
            state: "MD",
        });
    },

    // Calculate Maryland taxes
    calculateNewJerseyTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_NJ, {
            ...taxData,
            state: "NJ",
        });
    },
    // Calculate Maryland taxes
    calculateDistrictOfColumbiaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_DC, {
            ...taxData,
            state: "DC",
        });
    },

    // Calculate Colorado taxes
    calculateColoradoTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_CO, {
            ...taxData,
            state: "CO",
        });
    },

    // Calculate Pennsylvania taxes
    calculatePennsylvaniaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_PA, {
            ...taxData,
            state: "PA",
        });
    },

    // Calculate Texas taxes
    calculateTexasTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_TX, {
            ...taxData,
            state: "TX",
        });
    },

    // Calculate North Carolina taxes
    calculateNorthCarolinaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_NC, {
            ...taxData,
            state: "NC",
        });
    },

    // Calculate Georgia taxes
    calculateGeorgiaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_GA, {
            ...taxData,
            state: "GA",
        });
    },

    // Calculate Florida taxes
    calculateFloridaTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_FL, {
            ...taxData,
            state: "FL",
        });
    },

    // Calculate Washington taxes
    calculateWashingtonTax: async (taxData) => {
        return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_WA, {
            ...taxData,
            state: "WA",
        });
    },

    // Fetch a specific tax calculation by ID
    getTaxCalculation: async (calculationId) => {
        try {
            const url = `${API_URL}${ENDPOINTS.CALC_ID.replace(
                "{calc_id}",
                calculationId
            )}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Get tax calculation error:", error);
            if (error.response) {
                throw new Error(
                    error.response.data?.detail || "Failed to retrieve tax calculation"
                );
            }
            throw new Error("Network error occurred while retrieving calculation");
        }
    },

    // Fetch detailed tax summary
    getTaxSummary: async (calculationId) => {
        try {
            const url = `${API_URL}${ENDPOINTS.TAX_SUMMARY.replace(
                "{calc_id}",
                calculationId
            )}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Get tax summary error:", error);
            if (error.response) {
                throw new Error(
                    error.response.data?.detail || "Failed to retrieve tax summary"
                );
            }
            throw new Error("Network error occurred while retrieving summary");
        }
    },

    // Fetch tax calculation history
    getTaxCalculationHistory: async (filters = {}) => {
        try {
            const response = await axios.get(`${API_URL}${ENDPOINTS.HISTORY}`, {
                params: filters,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Get tax history error:", error);
            if (error.response) {
                throw new Error(
                    error.response.data?.detail || "Failed to retrieve tax history"
                );
            }
            throw new Error("Network error occurred while retrieving history");
        }
    },

    // Helper method to get state name from code
    getStateName: (stateCode) => {
        return TAX_CONSTANTS.SUPPORTED_STATES[stateCode]?.name || stateCode;
    },

    // Helper method to get state tax info
    getStateTaxInfo: (stateCode) => {
        return TAX_CONSTANTS.SUPPORTED_STATES[stateCode] || null;
    },

    // Helper method to get available tax years
    getAvailableTaxYears: () => {
        return TAX_CONSTANTS.TAX_YEARS.AVAILABLE;
    },

    // Helper method to get current tax year
    getCurrentTaxYear: () => {
        return TAX_CONSTANTS.TAX_YEARS.CURRENT;
    },

    // Helper method to get filing status info
    getFilingStatuses: () => {
        return TAX_CONSTANTS.FILING_STATUSES;
    },

    // Helper method to format currency
    formatCurrency: (amount) => {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(amount);
    },

    // Helper method to format percentage
    formatPercentage: (value) => {
        return new Intl.NumberFormat("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value / 100);
    },
};

export default TaxCalculationService;
