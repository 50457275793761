import React, {useState} from 'react';
import {motion} from 'framer-motion';
import {toast} from 'react-hot-toast';
import axios from 'axios';
import API_URL, {ENDPOINTS} from '../../../src/utils/config/api';
import '../../styles/Auth/ForgotPassword.css';

const ForgotPassword = ({onBack}) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${API_URL}${ENDPOINTS.REQUEST_RESET}`, {
                email
            });

            setSent(true);
            toast.success('Reset link sent successfully!');
        } catch (error) {
            console.error('Reset request error:', error);
            toast.error(error.response?.data?.detail || 'Failed to send reset link');
        } finally {
            setLoading(false);
        }
    };

    if (sent) {
        return (
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                className="text-center"
            >
                <h2 className="text-xl font-semibold text-white mb-4">Check Your Email</h2>
                <p className="text-gray-300 mb-6">
                    If an account exists with {email}, we've sent instructions to reset your password.
                </p>
                <button
                    onClick={onBack}
                    className="text-blue-400 hover:text-blue-300 font-medium"
                >
                    Return to Login
                </button>
            </motion.div>
        );
    }

    return (
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
        >
            <h2 className="text-xl font-semibold text-white mb-2">Reset Password</h2>
            <p className="text-gray-300 mb-6">
                Enter your email address and we'll send you instructions to reset your password.
            </p>

            <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-300 mb-1">
                            Email Address
                        </label>
                        <input
                            type="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg
                                     text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your email"
                        />
                    </div>

                    <div className="flex gap-4">
                        <motion.button
                            type="button"
                            onClick={onBack}
                            className="flex-1 px-4 py-2 border border-gray-700 rounded-lg
                                     text-gray-300 hover:bg-gray-800"
                            whileHover={{scale: 1.02}}
                            whileTap={{scale: 0.98}}
                        >
                            Back to Login
                        </motion.button>

                        <motion.button
                            type="submit"
                            disabled={loading}
                            className="flex-1 px-4 py-2 bg-blue-600 rounded-lg text-white
                                     hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                            whileHover={{scale: 1.02}}
                            whileTap={{scale: 0.98}}
                        >
                            {loading ? 'Sending...' : 'Send Reset Link'}
                        </motion.button>
                    </div>
                </div>
            </form>
        </motion.div>
    );
};

export default ForgotPassword;
