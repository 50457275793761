import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {AnimatePresence, motion} from 'framer-motion';
import {FiArrowLeft, FiFileText} from 'react-icons/fi';

// Components
import BasicInfo from './components/steps/BasicInfo';
import Income from './components/steps/Income';
import Deductions from './components/steps/Deductions';
import Credits from './components/steps/Credits';
import TaxResults from './components/TaxResults';
import StateSelection from './components/StateSelection';

// Services
import {TaxCalculationService} from '../../utils/services/taxCalculationService';

// Styles
import '../../styles/TaxCalculator.css';

const INITIAL_FORM_STATE = {
    year: 2024,
    state: '',
    filing_status: '',
    income: {
        wages: 0,
        self_employment: 0,
        investments: 0,
        retirement: 0,
        unemployment: 0,
        combat_pay: 0,
        social_security: 0,
        other: 0,
        w2_info: [{box1: 0, box2: 0, box17: 0}]
    },
    deductions: {
        type: 'standard',
        itemized: {
            medical: 0,
            state_local_tax: 0,
            property_tax: 0,
            mortgage_interest: 0,
            charitable: 0
        },
        home_ownership: {
            mortgage_interest: 0,
            property_taxes: 0,
            home_insurance: 0,
            pmi_insurance: 0
        },
        student_loan_interest: 0,
        self_employed_health_insurance: 0,
        ira_deduction: 0
    },
    credits: {
        child_tax_credit: {
            num_children: 0
        },
        child_care: {
            expenses: 0
        },
        retirement_savings: {
            has_retirement_plan: false,
            contributions: 0
        },
        education: {
            type: "none",
            expenses: 0
        }
    },
    withholdings: {
        federal: 0,
        state: 0
    }
};

const TaxCalculator = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [calculationResults, setCalculationResults] = useState(null);
    const [formData, setFormData] = useState(INITIAL_FORM_STATE);

    // Effect to sync state from URL and handle navigation guards
    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const stateIndex = pathParts.indexOf('tax-calculator') + 1;
        if (stateIndex < pathParts.length) {
            const stateCode = pathParts[stateIndex].toUpperCase();
            if (stateCode && (stateCode === 'CA' || stateCode === 'ND' || stateCode === 'NM' || stateCode === 'MT' || stateCode === 'MO' || stateCode === 'OH' || stateCode === 'MS' || stateCode === 'MN' || stateCode === 'ME' || stateCode === 'MA' || stateCode === 'MI' || stateCode === 'IA' || stateCode === 'KS' || stateCode === 'AK' || stateCode === 'ID' || stateCode === 'IN' || stateCode === 'LA' || stateCode === 'KY' || stateCode === 'IL' || stateCode === 'HI' || stateCode === 'DE' || stateCode === 'AZ' || stateCode === 'AR' || stateCode === 'CT' || stateCode === 'AL' || stateCode === 'NC' || stateCode === 'GA'
                || stateCode === 'FL' || stateCode === 'NJ' || stateCode === 'WY' || stateCode === 'TN' || stateCode === 'SD' || stateCode === 'NH' || stateCode === 'NV' || stateCode === 'WI' || stateCode === 'WV' || stateCode === 'VA' || stateCode === 'VT' || stateCode === 'UT' || stateCode === 'SC' || stateCode === 'RI' || stateCode === 'OR' || stateCode === 'OK' || stateCode === 'NE' || stateCode === 'DC' || stateCode === 'NY' || stateCode === 'WA' || stateCode === 'TX' || stateCode === 'CO' || stateCode === 'PA' || stateCode === 'MD')) {
                setFormData(prev => ({
                    ...prev,
                    state: stateCode
                }));
            }
        }

        // Navigation guard for direct URL access
        const currentStep = pathParts[pathParts.length - 1];
        if (currentStep !== 'tax-calculator' && !formData.state) {
            navigate('/chat', {replace: true});
        }
    }, [location.pathname, formData.state, navigate]);

    const handleCalculateTax = async (data) => {
        setLoading(true);
        setError(null);
        try {
            let results;
            switch (data.state.toLowerCase()) {
                case 'ca':
                    results = await TaxCalculationService.calculateCaliforniaTax(data);
                    break;
                case 'wy':
                    results = await TaxCalculationService.calculateWyomingTax(data);
                    break;
                case 'tn':
                    results = await TaxCalculationService.calculateTennesseeTax(data);
                    break;
                case 'sd':
                    results = await TaxCalculationService.calculateSouthDakotaTax(data);
                    break;
                case 'nh':
                    results = await TaxCalculationService.calculateNewHampshireTax(data);
                    break;
                case 'nv':
                    results = await TaxCalculationService.calculateNevadaTax(data);
                    break;
                case 'wi':
                    results = await TaxCalculationService.calculateWisconsinTax(data);
                    break;
                case 'wv':
                    results = await TaxCalculationService.calculateWestVirginiaTax(data);
                    break;
                case 'va':
                    results = await TaxCalculationService.calculateVirginiaTax(data);
                    break;
                case 'vt':
                    results = await TaxCalculationService.calculateVermontTax(data);
                    break;
                case 'ut':
                    results = await TaxCalculationService.calculateUtahTax(data);
                    break;
                case 'sc':
                    results = await TaxCalculationService.calculateSouthCarolinaTax(data);
                    break;
                case 'ri':
                    results = await TaxCalculationService.calculateRhodeIslandTax(data);
                    break;
                case 'or':
                    results = await TaxCalculationService.calculateOregonTax(data);
                    break;
                case 'ok':
                    results = await TaxCalculationService.calculateOklahomaTax(data);
                    break;
                case 'ne':
                    results = await TaxCalculationService.calculateNebraskaTax(data);
                    break;
                case 'nd':
                    results = await TaxCalculationService.calculateNorthDakotaTax(data);
                    break;
                case 'nm':
                    results = await TaxCalculationService.calculateNewMexicoTax(data);
                    break;
                case 'mt':
                    results = await TaxCalculationService.calculateMontanaTax(data);
                    break;
                case 'mo':
                    results = await TaxCalculationService.calculateMissouriTax(data);
                    break;
                case 'oh':
                    results = await TaxCalculationService.calculateOhioTax(data);
                    break;
                case 'ms':
                    results = await TaxCalculationService.calculateMississippiTax(data);
                    break;
                case 'mn':
                    results = await TaxCalculationService.calculateMinnesotaTax(data);
                    break;
                case 'mi':
                    results = await TaxCalculationService.calculateMichiganTax(data);
                    break;
                case 'ma':
                    results = await TaxCalculationService.calculateMassachusettsTax(data);
                    break;
                case 'me':
                    results = await TaxCalculationService.calculateMaineTax(data);
                    break;
                case 'ks':
                    results = await TaxCalculationService.calculateKansasTax(data);
                    break;
                case 'ia':
                    results = await TaxCalculationService.calculateIowaTax(data);
                    break;
                case 'ak':
                    results = await TaxCalculationService.calculateAlaskaTax(data);
                    break;
                case 'la':
                    results = await TaxCalculationService.calculateLouisianaTax(data);
                    break;
                case 'id':
                    results = await TaxCalculationService.calculateIdahoTax(data);
                    break;
                case 'in':
                    results = await TaxCalculationService.calculateIndianaTax(data);
                    break;
                case 'ky':
                    results = await TaxCalculationService.calculateKentuckyTax(data);
                    break;
                case 'il':
                    results = await TaxCalculationService.calculateIllinoisTax(data);
                    break;
                case 'hi':
                    results = await TaxCalculationService.calculateHawaiiTax(data);
                    break;
                case 'nc':
                    results = await TaxCalculationService.calculateNorthCarolinaTax(data);
                    break;
                case 'ar':
                    results = await TaxCalculationService.calculateArkansasTax(data);
                    break;
                case 'al':
                    results = await TaxCalculationService.calculateAlabamaTax(data);
                    break;
                case 'az':
                    results = await TaxCalculationService.calculateArizonaTax(data);
                    break;
                case 'ct':
                    results = await TaxCalculationService.calculateConnecticutTax(data);
                    break;
                case 'de':
                    results = await TaxCalculationService.calculateDelawareTax(data);
                    break;
                case 'ga':
                    results = await TaxCalculationService.calculateGeorgiaTax(data);
                    break;
                case 'fl':
                    results = await TaxCalculationService.calculateFloridaTax(data);
                    break;
                case 'ny':
                    results = await TaxCalculationService.calculateNewYorkTax(data);
                    break;
                case 'wa':
                    results = await TaxCalculationService.calculateWashingtonTax(data);
                    break;
                case 'tx':
                    results = await TaxCalculationService.calculateTexasTax(data);
                    break;
                case 'co':
                    results = await TaxCalculationService.calculateColoradoTax(data);
                    break;
                case 'pa':
                    results = await TaxCalculationService.calculatePennsylvaniaTax(data);
                    break;
                case 'md':
                    results = await TaxCalculationService.calculateMarylandTax(data);
                    break;
                case 'dc':
                    results = await TaxCalculationService.calculateDistrictOfColumbiaTax(data);
                    break;
                case 'nj':
                    results = await TaxCalculationService.calculateNewJerseyTax(data);
                    break;
                default:
                    throw new Error('Unsupported state selected');
            }

            if (results && results.federal_tax) {
                setCalculationResults(results);
                navigate(`/tax-calculator/${data.state.toLowerCase()}/results`, {replace: true});
                window.scrollTo({top: 0, behavior: 'smooth'});
            } else {
                throw new Error('Invalid response format from server');
            }
        } catch (err) {
            console.error('Calculation error:', err);
            setError(err.message || 'Failed to calculate taxes');
        } finally {
            setLoading(false);
        }
    };

    const handleBackToChat = () => {
        // Show confirmation if form has data
        if (Object.values(formData.income).some(val => val > 0) ||
            formData.filing_status ||
            formData.deductions.type !== 'standard') {
            if (window.confirm('Are you sure you want to leave? Your progress will be lost.')) {
                navigate('/dashboard');
            }
        } else {
            navigate('/dashboard');
        }
    };

    const handleRecalculate = () => {
        setCalculationResults(null);
        setFormData(prev => ({
            ...INITIAL_FORM_STATE,
            year: prev.year // Preserve the selected year
        }));
        navigate('/tax-calculator', {replace: true});
    };

    const sharedProps = {
        formData,
        setFormData,
        loading,
        error
    };

    return (
        <div className="tax-calculator-page">
            <div className="galaxy-background"/>
            <div className="galaxy-spiral"/>

            <div className="tax-calculator-container">
                <motion.button
                    onClick={handleBackToChat}
                    className="tax-back-button"
                    whileHover={{scale: 1.05}}
                    whileTap={{scale: 0.95}}
                >
                    <FiArrowLeft size={20}/>
                    <span>Dashbaord</span>
                </motion.button>

                <motion.div
                    className="tax-calculator-header"
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                >

                    <h1>Free Tax Calculator</h1>
                </motion.div>

                <AnimatePresence mode="wait">
                    {error && (
                        <motion.div
                            className="error-message"
                            initial={{opacity: 0, y: -10}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: -10}}
                        >
                            <FiFileText size={20}/>
                            <span>{error}</span>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence mode="wait">
                    <Routes>
                        <Route index element={<StateSelection {...sharedProps} />}/>

                        <Route path=":stateCode/*">
                            <Route path="basic-info" element={<BasicInfo {...sharedProps} />}/>
                            <Route path="income" element={<Income {...sharedProps} />}/>
                            <Route path="deductions" element={<Deductions {...sharedProps} />}/>
                            <Route path="credits" element={<Credits {...sharedProps} onSubmit={handleCalculateTax}/>}/>
                            <Route path="results" element={
                                <TaxResults
                                    results={calculationResults}
                                    onRecalculate={handleRecalculate}
                                />
                            }/>
                            <Route index element={<Navigate to="/tax-calculator" replace/>}/>
                        </Route>

                        <Route path="*" element={<Navigate to="/tax-calculator" replace/>}/>
                    </Routes>
                </AnimatePresence>
            </div>
        </div>
    );
};

export default TaxCalculator;
