import React from 'react';
import {motion} from 'framer-motion';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {ArrowRight, DollarSign, Download, LineChart, PiggyBank, TrendingUp} from 'lucide-react';
import {Card} from '../../../components/ui/card';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../../../components/ui/dialog';
import {Button} from '../../../components/ui/button';
import {TaxCalculationService} from '../../../utils/services/taxCalculationService';
import '../../../styles/PDFExport.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import API_URL, {ENDPOINTS} from '../../../utils/config/api';

// Investment Dialog Component
const InvestmentDialog = ({refundAmount, onInvest}) => (
    <Dialog>
        <DialogTrigger asChild>
            <Button
                className="w-full bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white shadow-lg hover:shadow-xl transition-all duration-300">
                <PiggyBank className="mr-2 h-5 w-5"/>
                Invest Your ${refundAmount.toLocaleString()} In The Stock Market
            </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-md bg-gray-900/95 backdrop-blur-lg border border-gray-800">
            <DialogHeader>
                <DialogTitle className="text-2xl font-bold text-white flex items-center gap-2">
                    <LineChart className="text-green-400"/>
                    INVEST IN STOCK MARKET TODAY
                </DialogTitle>
                <DialogDescription className="text-gray-300 mt-2">
                    Let our AI help you analyze the stock market to invest your ${refundAmount.toLocaleString()} tax
                    refund wisely
                </DialogDescription>
            </DialogHeader>

            <div className="space-y-4 my-4">
                <div
                    className="bg-gradient-to-r from-blue-600/20 to-green-600/20 rounded-lg p-6 border border-blue-500/30">
                    <h4 className="text-xl font-bold text-white mb-4">Our Stock AI Features:</h4>
                    <ul className="space-y-3">
                        <li className="flex items-center gap-3 text-gray-200">
                            <TrendingUp className="text-green-400"/>
                            AI-Powered Market Analysis with Reports
                        </li>
                        <li className="flex items-center gap-3 text-gray-200">
                            <DollarSign className="text-green-400"/>
                            Ability to choose which industry to invest
                        </li>
                        <li className="flex items-center gap-3 text-gray-200">
                            <LineChart className="text-green-400"/>
                            Risk Level Tolerance Options
                        </li>
                    </ul>
                </div>
            </div>

            <DialogFooter>
                <Button
                    className="w-full bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white text-lg py-6"
                    onClick={onInvest}
                >
                    Get Stock Now
                    <ArrowRight className="ml-2"/>
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
);

// State name mapping
const STATE_NAMES = {
    'AL': 'Alabama', 'AK': 'Alaska', 'AZ': 'Arizona', 'AR': 'Arkansas', 'CA': 'California',
    'CO': 'Colorado', 'CT': 'Connecticut', 'DE': 'Delaware', 'FL': 'Florida', 'GA': 'Georgia',
    'HI': 'Hawaii', 'ID': 'Idaho', 'IL': 'Illinois', 'IN': 'Indiana', 'IA': 'Iowa',
    'KS': 'Kansas', 'KY': 'Kentucky', 'LA': 'Louisiana', 'ME': 'Maine', 'MD': 'Maryland',
    'MA': 'Massachusetts', 'MI': 'Michigan', 'MN': 'Minnesota', 'MS': 'Mississippi',
    'MO': 'Missouri', 'MT': 'Montana', 'NE': 'Nebraska', 'NV': 'Nevada', 'NH': 'New Hampshire',
    'NJ': 'New Jersey', 'NM': 'New Mexico', 'NY': 'New York', 'NC': 'North Carolina',
    'ND': 'North Dakota', 'OH': 'Ohio', 'OK': 'Oklahoma', 'OR': 'Oregon', 'PA': 'Pennsylvania',
    'RI': 'Rhode Island', 'SC': 'South Carolina', 'SD': 'South Dakota', 'TN': 'Tennessee',
    'TX': 'Texas', 'UT': 'Utah', 'VT': 'Vermont', 'VA': 'Virginia', 'WA': 'Washington',
    'WV': 'West Virginia', 'WI': 'Wisconsin', 'WY': 'Wyoming', 'DC': 'District of Columbia'
};

const TaxResults = ({results, onRecalculate}) => {
    const navigate = useNavigate();
    const {stateCode} = useParams();

    if (!results) return <Navigate to="/tax-calculator" replace/>;

    const {federal_tax, state_tax, summary, breakdown} = results;

    const formatCurrency = (amount) => TaxCalculationService.formatCurrency(amount || 0);

    // Updated percentage formatter to show whole numbers
    const formatPercentage = (value) => {
        if (!value) return '0%';
        return `${(parseFloat(value) * 100).toFixed(2)}%`;
    };

    // Calculate state effective tax rate
    const calculateStateEffectiveRate = () => {
        const stateTax = parseFloat(state_tax.net_tax || 0);
        const stateTaxableIncome = parseFloat(state_tax.taxable_income || 0);
        return stateTaxableIncome > 0 ? stateTax / stateTaxableIncome : 0;
    };

    // Calculate state refund/due
    const stateWithholdings = parseFloat(breakdown?.federal?.withholdings?.state || 0);
    const stateNetTax = parseFloat(state_tax.net_tax || 0);
    const stateRefundOrDue = stateWithholdings - stateNetTax;

    const getStateDescription = (code) => {
        const stateName = STATE_NAMES[code.toUpperCase()] || code;
        if (code.toUpperCase() === 'MD') {
            return `${stateName} uses a state tax rate plus a flat average rate of 3% for local county tax. 
                   Your tax is determined by applying these rates to your taxable income after state-specific 
                   deductions and credits.`;
        }
        return `${stateName} uses ${code.toUpperCase() === 'NC' ? 'a flat tax rate' : 'tax brackets'} to 
                calculate state income tax. Your tax is determined by applying the state tax rate to your 
                taxable income after state-specific deductions and credits.`;
    };

    const handleNavigate = () => {
        navigate('/tax-calculator');
    };

    const handleExportPDF = async () => {
        try {
            const content = document.getElementById('tax-results-content');

            // Create temporary element for PDF version
            const pdfContent = content.cloneNode(true);
            pdfContent.classList.add('pdf-export-mode');
            document.body.appendChild(pdfContent);

            // Replace H&R Block CTA with TaxMeAI branding
            const ctaCard = pdfContent.querySelector('#tax-cta');
            if (ctaCard) {
                ctaCard.innerHTML = `
                <div class="pdf-branding">
                    <img src="/tax-logo-dark.svg" alt="TaxMeAI" style="height: 60px;" />
                    <h2 class="text-2xl font-bold text-white">Tax Calculation by TaxMe AI</h2>
                    <p class="text-gray-300">
                        This tax calculation was computed for FREE using https://taxmeai.com<br/>
                        Get accurate tax estimates and Stock Market insights with TaxMe AI.
                    </p>
                </div>
            `;
            }

            // Add watermark
            const watermark = document.createElement('div');
            watermark.className = 'watermark';
            watermark.style.background = `url('/tax-logo-dark.svg') center/150px no-repeat`;
            watermark.style.transform = 'rotate(-30deg)';
            pdfContent.insertBefore(watermark, pdfContent.firstChild);

            // Create canvas with better resolution
            const canvas = await html2canvas(pdfContent, {
                scale: 2,
                useCORS: true,
                logging: false,
                backgroundColor: '#111827',
                windowWidth: 1100,
            });

            // Remove temporary element
            document.body.removeChild(pdfContent);

            const imgData = canvas.toDataURL('image/png');

            // Initialize PDF
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4'
            });

            // Calculate dimensions
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const aspectRatio = canvas.height / canvas.width;
            const contentHeight = pdfWidth * aspectRatio;
            const pageCount = Math.ceil(contentHeight / pdfHeight);

            // Split content across pages
            for (let i = 0; i < pageCount; i++) {
                if (i < 0) {
                    pdf.addPage();
                }

                // Calculate section of image for this page
                const sourceY = i * canvas.height / pageCount;
                const sourceHeight = canvas.height / pageCount;

                // Add content
                pdf.addImage(
                    imgData,
                    'PNG',
                    0,
                    20,
                    pdfWidth,
                    pdfHeight - 30,
                    '',
                    'FAST',
                    0,
                    sourceY,
                    canvas.width,
                    sourceHeight
                );

            }

            pdf.save(`${STATE_NAMES[stateCode.toUpperCase()]}-Tax-Result-${summary.year}.pdf`);

        } catch (error) {
            console.error('PDF export error:', error);
        }
    };
    return (
        <motion.div
            id="tax-results-content"
            className="max-w-4xl mx-auto space-y-8 p-6"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
        >
            {/* Advertising CTA */}
            <Card id="tax-cta" className="bg-gradient-to-r from-green-600/20 to-blue-600/20 p-6">
                <div className="flex flex-col items-center text-center space-y-4">
                    <h2 className="text-2xl font-bold text-white">Ready to File Your Taxes?</h2>
                    <p className="text-gray-300">
                        File with confidence from the comfort of your Couch....(Send Us an Email: support@taxmeai.com
                        for CTA placement)
                    </p>
                    <Button
                        className="bg-green-600 hover:bg-green-700 text-white px-8 py-3"
                        onClick={() => window.open('https://www.google.com', '_blank')}
                    >
                        Start Filing Today
                        <ArrowRight className="ml-2"/>
                    </Button>
                </div>
            </Card>

            {/* Federal Tax Card */}
            <Card className="p-6 bg-blue-900/30 border-blue-500/50">
                <h3 className="text-xl font-bold text-white mb-4">Federal Tax Summary</h3>
                <div className="text-center my-6">
                    {parseFloat(summary.refund_amount) > 0 ? (
                        <div className="text-green-400">
                            <p className="text-xl">Federal Refund</p>
                            <p className="text-4xl font-bold">+{formatCurrency(summary.refund_amount)}</p>
                        </div>
                    ) : (
                        <div className="text-red-400">
                            <p className="text-xl">Federal Tax Due</p>
                            <p className="text-4xl font-bold">-{formatCurrency(summary.amount_due)}</p>
                        </div>
                    )}
                </div>
                <div className="space-y-3 text-sm">
                    <div className="flex justify-between">
                        <span className="text-gray-400">Total Annual Income</span>
                        <span className="text-white">{formatCurrency(summary.total_income)}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-400">Total Federal Tax</span>
                        <span className="text-white">{formatCurrency(federal_tax.net_tax)}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-400">Federal Withholdings</span>
                        <span className="text-white">{formatCurrency(federal_tax.summary.federal_withholdings)}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-400">Effective Tax Rate</span>
                        <span className="text-white">{formatPercentage(federal_tax.effective_rate)}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-400">Credits Applied</span>
                        <span className="text-green-400">-{formatCurrency(federal_tax.credits_applied)}</span>
                    </div>
                </div>
                <p className="mt-4 text-xs text-gray-400 border-t border-gray-700 pt-4">
                    Federal tax is calculated using progressive tax brackets ranging from 10% to 37%.
                    Your effective tax rate represents the actual percentage of your income paid in taxes
                    after deductions and credits.
                </p>
            </Card>

            {/* State Tax Card */}
            <Card className="p-6 bg-blue-900/30 border-blue-500/50">
                <h3 className="text-xl font-bold text-white mb-4">
                    {STATE_NAMES[stateCode.toUpperCase()] || stateCode} Tax Summary
                </h3>
                <div className="text-center my-6">
                    {stateRefundOrDue > 0 ? (
                        <div className="text-green-400">
                            <p className="text-xl">State Refund</p>
                            <p className="text-4xl font-bold">+{formatCurrency(stateRefundOrDue)}</p>
                        </div>
                    ) : (
                        <div className="text-red-400">
                            <p className="text-xl">State Tax Due</p>
                            <p className="text-4xl font-bold">-{formatCurrency(Math.abs(stateRefundOrDue))}</p>
                        </div>
                    )}
                </div>
                <div className="space-y-3 text-sm">
                    <div className="flex justify-between">
                        <span className="text-gray-400">State Taxable Income</span>
                        <span className="text-white">{formatCurrency(state_tax.taxable_income)}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-400">Total State Tax</span>
                        <span className="text-white">{formatCurrency(state_tax.net_tax)}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-400">State Withholdings</span>
                        <span className="text-white">{formatCurrency(stateWithholdings)}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-400">Effective Tax Rate</span>
                        <span className="text-white">{formatPercentage(calculateStateEffectiveRate())}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-gray-400">Credits Applied</span>
                        <span className="text-green-400">-{formatCurrency(state_tax.credits_applied)}</span>
                    </div>
                </div>
                <p className="mt-4 text-xs text-gray-400 border-t border-gray-700 pt-4">
                    {getStateDescription(stateCode)}
                </p>
            </Card>

            {/* Investment Dialog */}
            {parseFloat(summary.refund_amount) > 0 && (
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 0.3}}
                >
                    <InvestmentDialog
                        refundAmount={parseFloat(summary.refund_amount)}
                        onInvest={() => navigate('/stock-ai')}
                    />
                </motion.div>
            )}

            {/* Action Buttons */}
            <div className="flex flex-col sm:flex-row gap-4 w-full">
                <Button
                    onClick={handleExportPDF}
                    className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700"
                >
                    <Download size={20} className="mr-2"/>
                    Export Summary
                </Button>
                <Button
                    onClick={async () => {
                        try {
                            const token = localStorage.getItem('access_token');

                            // Generate initial analysis entry
                            const initResponse = await axios.post(
                                `${API_URL}${ENDPOINTS.TAX_ANALYSIS_INIT}`,
                                {
                                    federal_tax: federal_tax,
                                    state_tax: state_tax,
                                    summary: {
                                        ...summary,
                                        year: summary.year
                                    }
                                },
                                {
                                    headers: {Authorization: `Bearer ${token}`}
                                }
                            );
                            console.log('Init response:', initResponse.data);

                            // Store and pass the analysis_id
                            const analysis_id = initResponse.data.analysis_id;
                            // Navigate to analysis with the analysis_id
                            navigate('/tax-calculator/analysis', {
                                state: {
                                    federalResults: federal_tax,
                                    stateResults: state_tax,
                                    summary: {
                                        ...summary,
                                        year: summary.year
                                    },
                                    analysis_id
                                }
                            });
                        } catch (err) {
                            console.error('Error initializing analysis:', err);
                            // You might want to show an error message to the user
                            alert('Failed to initialize analysis. Please try again.');
                        }
                    }}
                    className="w-full sm:w-auto bg-green-600 hover:bg-green-700"
                >
                    <TrendingUp size={20} className="mr-2"/>
                    Maximize My Refund $$$
                </Button>
                <Button
                    onClick={handleNavigate}
                    variant="outline"
                    className="w-full sm:w-auto border-gray-700 text-gray-300"
                >
                    Calculate Another
                </Button>
            </div>

            {/* Disclaimer */}
            <div className="mt-8 p-4 bg-gray-900/50 border border-gray-800 rounded-lg">
                <p className="text-sm text-gray-400">
                    This tax calculation provides an estimate based on the information you provided
                    and {summary.year} tax rates.
                    Actual tax liability may vary. For accurate tax filing and professional advice, please consult with
                    a
                    qualified tax professional or visit (CTA Placement).
                </p>
            </div>
        </motion.div>
    );
};

export default TaxResults;
