// src/pages/Blog/AdminBlogList.jsx
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {blogService} from '../../services/blogService';
import {Card} from '../../components/ui/card';
import {Button} from '../../components/ui/button';
import {Edit, Eye, Star, Trash2} from 'lucide-react';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../../components/ui/alert-dialog';
import {toast} from '../../components/ui/toast';

const AdminBlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteId, setDeleteId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const data = await blogService.getAllBlogs();
            setBlogs(data);
        } catch (error) {
            toast({
                title: "Error",
                description: error.message,
                type: "error"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            await blogService.deleteBlogPost(deleteId);
            toast({
                title: "Success",
                description: "Blog post deleted successfully",
                type: "success"
            });
            fetchBlogs();
        } catch (error) {
            toast({
                title: "Error",
                description: error.message,
                type: "error"
            });
        } finally {
            setDeleteId(null);
        }
    };

    const handleFeatureToggle = async (id) => {
        try {
            await blogService.toggleFeaturePost(id);
            toast({
                title: "Success",
                description: "Featured status updated",
                type: "success"
            });
            fetchBlogs();
        } catch (error) {
            toast({
                title: "Error",
                description: error.message,
                type: "error"
            });
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="max-w-7xl mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-2xl font-bold text-white">Manage Blog Posts</h1>
                <Button
                    onClick={() => navigate('/admin/blog/new')}
                    className="bg-blue-600 hover:bg-blue-700"
                >
                    Create New Post
                </Button>
            </div>

            <div className="space-y-4">
                {blogs.map((blog) => (
                    <Card key={blog.id} className="p-4 bg-gray-900/50">
                        <div className="flex items-center justify-between">
                            <div className="flex-1">
                                <h2 className="text-xl font-bold text-white mb-2">
                                    {blog.title}
                                </h2>
                                <div className="flex items-center gap-4 text-sm text-gray-400">
                                    <span>{blog.status}</span>
                                    <span>{new Date(blog.published_date).toLocaleDateString()}</span>
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={() => handleFeatureToggle(blog.id)}
                                    className={blog.featured ? "text-yellow-400" : "text-gray-400"}
                                >
                                    <Star className="w-5 h-5"/>
                                </Button>
                                <Link to={`/blog/${blog.slug}`}>
                                    <Button variant="ghost" size="sm">
                                        <Eye className="w-5 h-5"/>
                                    </Button>
                                </Link>
                                <Link to={`/admin/blog/edit/${blog.id}`}>
                                    <Button variant="ghost" size="sm">
                                        <Edit className="w-5 h-5"/>
                                    </Button>
                                </Link>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={() => setDeleteId(blog.id)}
                                    className="text-red-400 hover:text-red-300"
                                >
                                    <Trash2 className="w-5 h-5"/>
                                </Button>
                            </div>
                        </div>
                    </Card>
                ))}
            </div>

            <AlertDialog open={!!deleteId} onOpenChange={() => setDeleteId(null)}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. This will permanently delete the blog post.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                            onClick={handleDelete}
                            className="bg-red-600 hover:bg-red-700"
                        >
                            Delete
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
};

export default AdminBlogList;