import axios from 'axios';
import {ENDPOINTS} from '../config/api';

class StockAIService {
    constructor() {
        this.baseURL = 'https://taxmeai.com';
    }

    async analyzeStocks(params) {
        try {
            const token = localStorage.getItem('access_token');

            const transformedParams = {
                industry: typeof params.industry === 'object' ? params.industry.id : params.industry,
                risk_level: typeof params.riskLevel === 'object' ? params.riskLevel.id : params.riskLevel,
            };

            console.log('Analyzing stocks with params:', transformedParams);

            const response = await axios.post(
                `${this.baseURL}${ENDPOINTS.STOCK_ANALYZE}`,
                transformedParams,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error('Stock analysis error:', error.response?.data);
            this._handleError(error, 'analyze stocks', params);
        }
    }

    async getMarketAnalysis(params) {
        try {
            const token = localStorage.getItem('access_token');

            // Structure payload using the data from first analysis call
            const transformedParams = {
                request: {
                    industry: typeof params.industry === 'object' ? params.industry.id : params.industry,
                    risk_level: typeof params.riskLevel === 'object' ? params.riskLevel.id : params.riskLevel,
                },
                stock_data: {
                    recommendations: params.stockData.recommendations,
                    technical_analysis: params.stockData.technical_analysis,
                    analysis_timestamp: params.stockData.analysis_timestamp,
                    industry: params.stockData.industry,
                    risk_level: params.stockData.risk_level
                }
            };

            const response = await axios.post(
                `${this.baseURL}${ENDPOINTS.STOCK_MARKET_ANALYSIS}`,
                transformedParams,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error analyzing market data:', error.response?.data);
            this._handleError(error, 'analyze market data', params);
        }
    }

    async initializeAnalysis(params) {
        try {
            const token = localStorage.getItem('access_token');
            const transformedParams = {
                industry: typeof params.industry === 'object' ? params.industry.id : params.industry,
                risk_level: typeof params.riskLevel === 'object' ? params.riskLevel.id : params.riskLevel,
            };

            const response = await axios.post(
                `${this.baseURL}${ENDPOINTS.STOCK_ANALYSIS_INIT}`,
                transformedParams,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error initializing analysis:', error.response?.data);
            this._handleError(error, 'initialize analysis', params);
        }
    }

    async createStockSubscription(analysisId) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post(
                `${this.baseURL}${ENDPOINTS.STOCK_SUBSCRIPTION_CREATE}`,
                {analysis_id: analysisId},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error creating subscription:', error.response?.data);
            this._handleError(error, 'create subscription');
        }
    }

    async verifyStockSubscription(sessionId, analysisId) {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(
                `${this.baseURL}${ENDPOINTS.STOCK_SUBSCRIPTION_VERIFY}/${sessionId}?analysis_id=${analysisId}`,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );
            // Ensure the response structure matches what the frontend expects
            if (response.data?.success) {
                return {
                    success: true,
                    analysis: {
                        industry: response.data.analysis.industry,
                        risk_level: response.data.analysis.risk_level
                    },
                    subscription_status: {
                        is_active: response.data.subscription_status.is_active,
                        uses_remaining: response.data.subscription_status.uses_remaining,
                        days_remaining: response.data.subscription_status.days_remaining
                    }
                };
            }

            throw new Error('Verification response format invalid');
        } catch (error) {
            console.error('Error verifying subscription:', error.response?.data);
            this._handleError(error, 'verify subscription');
        }
    }

    async getStockSubscriptionStatus() {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(
                `${this.baseURL}${ENDPOINTS.STOCK_SUBSCRIPTION_STATUS}`,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching subscription status:', error.response?.data);
            this._handleError(error, 'fetch subscription status');
        }
    }

    _handleError(error, action, params = {}) {
        if (error.response?.status === 404) {
            throw new Error(
                `No data found ${params.industry ? `for ${params.industry.name || params.industry} industry` : ''} 
                ${params.riskLevel ? `with ${params.riskLevel.name || params.riskLevel} risk level` : ''}.
                Try different parameters.`
            );
        }

        if (error.response?.status === 401) {
            throw new Error('Session expired. Please log in again.');
        }

        // Add specific error messages for subscription-related errors
        if (action.includes('subscription')) {
            const errorMessage = error.response?.data?.detail || 'Subscription error occurred';
            if (action === 'verify subscription') {
                throw new Error(`Payment verification failed: ${errorMessage}`);
            }
            if (action === 'create subscription') {
                throw new Error(`Failed to create subscription: ${errorMessage}`);
            }
        }

        throw new Error(
            error.response?.data?.detail ||
            `Failed to ${action}. Please try again later.`
        );
    }
}

const stockAIService = new StockAIService();
export {stockAIService};
export default stockAIService;