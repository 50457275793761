import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import {cn} from "../../utils/cn";

const TooltipProvider = TooltipPrimitive.Provider;
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef(({className, sideOffset = 4, ...props}, ref) => (
    <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(
            "z-50 overflow-hidden rounded-md bg-gray-900 px-3 py-1.5 text-sm text-gray-50 shadow-md fade-in-0 zoom-in-95",
            className
        )}
        {...props}
    />
));
TooltipContent.displayName = "TooltipContent";

export {Tooltip, TooltipTrigger, TooltipContent, TooltipProvider};
