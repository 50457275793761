// src/utils/services/contactService.js
import axios from 'axios';
import API_URL from '../config/api';

export const ContactService = {
    sendContactForm: async (formData) => {
        try {
            const response = await axios.post(`${API_URL}/api/get-in-touch`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error sending contact form:', error);
            throw new Error(error.response?.data?.detail || 'Failed to send message');
        }
    }
};

export default ContactService;
