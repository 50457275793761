import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {
    FiArrowLeft,
    FiCalendar,
    FiClock,
    FiDollarSign,
    FiFileText,
    FiHelpCircle,
    FiLogOut,
    FiMenu,
    FiPaperclip,
    FiSend,
    FiUpload,
    FiX
} from 'react-icons/fi';
import {AnimatePresence, motion} from 'framer-motion';
import '../styles/Chat.css';
import API_URL, {ENDPOINTS} from '../utils/config/api';

const GalaxyBackground = () => {
    useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';

            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;

                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);

                starsContainer.appendChild(star);
            }

            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background"/>
            <div className="galaxy-spiral"/>
        </>
    );
};

const suggestedQuestions = [
    {
        icon: <FiDollarSign size={24}/>,
        text: "How do I calculate my taxable income?",
        description: "Get help with income calculations"
    },
    {
        icon: <FiFileText size={24}/>,
        text: "What tax deductions am I eligible for?",
        description: "Learn about available deductions"
    },
    {
        icon: <FiCalendar size={24}/>,
        text: "When is the tax filing deadline?",
        description: "Important dates and deadlines"
    },
    {
        icon: <FiHelpCircle size={24}/>,
        text: "What documents do I need for tax filing?",
        description: "Required documentation guide"
    }
];

// Updated WebSearchButton Component
const WebSearchButton = ({enabled, onToggle}) => {
    const [showSearchText, setShowSearchText] = useState(false);

    const handleButtonClick = () => {
        onToggle(!enabled);
        setShowSearchText(!showSearchText);
    };

    return (
        <>
            <button
                className={`web-search-button ${enabled ? 'active' : ''}`}
                onClick={handleButtonClick}
                title={enabled ? 'Web search enabled' : 'Enable web search'}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="12" cy="12" r="10"/>
                    <line x1="2" y1="12" x2="22" y2="12"/>
                    <path
                        d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/>
                </svg>
            </button>
            {showSearchText && (
                <div className="search-text">
                    {enabled ? 'IRS website search enabled' : 'Enable IRS website search'}
                </div>
            )}
        </>
    );
};

const Chat = ({isWidget, persistentChatHistory, onChatHistoryUpdate}) => {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState(persistentChatHistory || []);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUploading, setFileUploading] = useState(false);
    const [uploadedFileInfo, setUploadedFileInfo] = useState(null);
    const [webSearchEnabled, setWebSearchEnabled] = useState(false);


    const chatEndRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const menuRef = useRef(null);
    const textareaRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [chatHistory]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isWidget && onChatHistoryUpdate) {
            onChatHistoryUpdate(chatHistory);
        }
    }, [chatHistory, isWidget, onChatHistoryUpdate]);

    const handleNavigation = (path) => {
        setIsMenuOpen(false);
        if (path === '/chat' && location.pathname === '/chat') return;
        if (path === '/') {
            localStorage.removeItem('access_token');
        }
        navigate(path);
    };

    const handleQuestionClick = (question) => {
        handleSendMessage(question.text);
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 150)}px`;
        }
    };

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileUploading(true);
            const formData = new FormData();
            formData.append('file', file);

            try {
                const token = localStorage.getItem('access_token');
                const response = await axios.post(
                    `${API_URL}${ENDPOINTS.UPLOAD_DOCUMENT}`,
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                );

                setSelectedFile(file);
                setUploadedFileInfo(response.data);

            } catch (error) {
                console.error('File upload error:', error);
                setSelectedFile(null);
            } finally {
                setFileUploading(false);
            }
        }
    };

    const handleSendMessage = async (customMessage = null) => {
        const messageToSend = customMessage || message;
        if (!messageToSend.trim()) return;
        try {
            setMessage('');
            setIsTyping(true);

            const token = localStorage.getItem('access_token');
            const payload = {
                message: messageToSend,
                file_content: uploadedFileInfo?.content,
                web_search: webSearchEnabled
            };

            const res = await axios.post(
                `${API_URL}${ENDPOINTS.CHAT}`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setChatHistory(prev => [...prev, {
                type: 'user',
                content: messageToSend
            }]);

            setChatHistory(prev => [...prev, {
                type: 'ai',
                content: res.data.response,
                webResults: res.data.web_results ? {
                    content: res.data.web_results.content,
                    sources: res.data.web_results.sources
                } : null
            }]);

            setSelectedFile(null);
            setUploadedFileInfo(null);
        } catch (error) {
            console.error('Error:', error.response?.data?.detail || error.message);
            setChatHistory(prev => [...prev, {
                type: 'ai',
                content: 'An error occurred while fetching the response.',
                isError: true
            }]);
        } finally {
            setIsTyping(false);
        }
    };

    return (
        <div className={`chat-page ${isWidget ? 'widget-mode' : ''}`}>
            {!isWidget && <GalaxyBackground/>}

            {!isWidget && (
                <div className="chat-nav">
                    <button
                        className={`menu-toggle ${isMenuOpen ? 'active' : ''}`}
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label="Toggle menu"
                    >
                        {isMenuOpen ? <FiX size={24}/> : <FiMenu size={24}/>}
                    </button>
                </div>
            )}

            {!isWidget && (
                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            ref={menuRef}
                            className="menu-panel"
                            initial={{x: '-100%'}}
                            animate={{x: 0}}
                            exit={{x: '-100%'}}
                            transition={{type: "spring", stiffness: 300, damping: 30}}
                        >
                            <nav className="menu-nav">

                                <button
                                    onClick={() => navigate('/dashboard')}
                                    className={location.pathname.startsWith('/tax-calculator') ? 'active' : ''}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        background: 'rgba(255, 255, 255, 0.05)',
                                        border: '1px solid rgba(255, 255, 255, 0.1)',
                                        borderRadius: '8px',
                                        color: 'rgba(255, 255, 255, 0.8)',
                                        padding: '8px 16px',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        transition: 'all 0.2s ease'
                                    }}
                                >
                                    <FiArrowLeft/>
                                    <span>Dashboard</span>
                                </button>
                                <button onClick={() => handleNavigation('/history')}>
                                    <FiClock/>
                                    <span>Chat History</span>
                                </button>

                                <button onClick={() => handleNavigation('/')} className="logout-btn">
                                    <FiLogOut/>
                                    <span>Logout</span>
                                </button>
                            </nav>
                        </motion.div>
                    )}
                </AnimatePresence>
            )}

            <main className="chat-main">
                {chatHistory.length === 0 ? (
                    <div className="welcome-section">
                        <h1 className="welcome-title">TaxMe AI</h1>
                        <p className="welcome-prompt">What tax question can I help with?</p>
                        <div className="suggested-questions">
                            {suggestedQuestions.map((question, index) => (
                                <motion.div
                                    key={index}
                                    className="question-suggestion"
                                    onClick={() => handleQuestionClick(question)}
                                    whileHover={{scale: 1.02}}
                                    whileTap={{scale: 0.98}}
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: index * 0.1}}
                                >
                                    <span className="question-icon">{question.icon}</span>
                                    <div className="question-text">
                                        <h3>{question.text}</h3>
                                        <p>{question.description}</p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="messages-container">
                        {chatHistory.map((msg, index) => (
                            <motion.div
                                key={index}
                                className={`message ${msg.type}-message`}
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.2}}
                            >
                                {msg.type === 'ai' ? (
                                    <motion.div className="message-avatar">
                                        <motion.img
                                            src="/tax-logo-dark.svg"
                                            alt="AI"
                                            animate={{rotate: 360}}
                                            transition={{
                                                duration: 20,
                                                repeat: Infinity,
                                                ease: "linear"
                                            }}
                                        />
                                    </motion.div>
                                ) : (
                                    <div className="message-avatar">
                                        T
                                    </div>
                                )}
                                <div className="message-content">
                                    {msg.content}
                                    {msg.webResults && (
                                        <div className="web-results">
                                            <h3>Web Results</h3>
                                            <div className="web-content">
                                                {msg.webResults.content}
                                                {msg.webResults.sources && msg.webResults.sources.length > 0 && (
                                                    <div className="web-sources">
                                                        <h4>Sources:</h4>
                                                        <ul>
                                                            {msg.webResults.sources.map((source, index) => (
                                                                <li key={index}>
                                                                    <a
                                                                        href={source.url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {source.title}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </motion.div>
                        ))}
                        <div ref={chatEndRef}/>
                    </div>
                )}

                <div className={`input-container ${isWidget ? 'sticky bottom-0' : ''}`}>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        style={{display: 'none'}}
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                    />
                    {selectedFile && (
                        <div className="attached-file">
                            <span>{selectedFile.name}</span>
                            <button
                                onClick={() => {
                                    setSelectedFile(null);
                                    setUploadedFileInfo(null);
                                }}
                            >
                                <FiX size={16}/>
                            </button>
                        </div>
                    )}
                    <button
                        className="upload-button"
                        onClick={() => fileInputRef.current.click()}
                        disabled={fileUploading}
                    >
                        {fileUploading ? <FiUpload/> : <FiPaperclip/>}
                    </button>
                    <WebSearchButton
                        enabled={webSearchEnabled}
                        onToggle={setWebSearchEnabled}
                    />
                    <textarea
                        ref={textareaRef}
                        className="message-input"
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                            adjustTextareaHeight();
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                        placeholder="Ask TaxMe AI..."
                        rows={1}
                    />
                    <button
                        className="send-button"
                        onClick={() => handleSendMessage()}
                        disabled={!message.trim() || isTyping}
                    >
                        {isTyping ? 'Thinking...' : <FiSend size={20}/>}
                    </button>
                </div>

                {isTyping && (
                    <motion.div
                        className="typing-indicator"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    >
                        <motion.img
                            src="/tax-logo-dark.svg"
                            alt="Typing"
                            animate={{rotate: 360}}
                            transition={{
                                duration: 2,
                                repeat: Infinity,
                                ease: "linear"
                            }}
                        />
                    </motion.div>
                )}
            </main>
        </div>
    );
};

export default Chat;
