import React from 'react';
import {motion} from 'framer-motion';
import {BarChart, ChevronRight, Clock, DollarSign, Shield, TrendingUp, Zap} from 'lucide-react';
import {Card} from '../../../../components/ui/card';
import {TooltipProvider,} from '../../../../components/ui/tooltip';
import '../../../../styles/StockAI/RiskLevel.css';

const riskLevels = [
    {
        id: 'low',
        name: 'Low Risk Stock',
        description: 'Stable, well-established companies with consistent returns',
        icon: Shield,
        color: 'blue',
        characteristics: [
            'Lower potential returns',
            'High market capitalization',
            'Strong dividend history',
            'Established market presence'
        ],
        timeHorizon: '5+ years',
        expectedReturn: '5-8%',
        volatility: 'Low',
        examples: ['Blue-chip stocks', 'Dividend aristocrats', 'Large-cap value stocks']
    },
    {
        id: 'medium',
        name: 'Medium Risk Stock',
        description: 'Balance of growth potential and stability',
        icon: TrendingUp,
        color: 'yellow',
        characteristics: [
            'Moderate growth potential',
            'Mid-market capitalization',
            'Some market volatility',
            'Growing market share'
        ],
        timeHorizon: '3-5 years',
        expectedReturn: '8-12%',
        volatility: 'Moderate',
        examples: ['Growth stocks', 'Mid-cap stocks', 'Emerging market leaders']
    },
    {
        id: 'high',
        name: 'High Risk Stock',
        description: 'High growth potential with significant volatility',
        icon: Zap,
        color: 'red',
        characteristics: [
            'High growth potential',
            'Small market capitalization',
            'High market volatility',
            'Emerging technologies'
        ],
        timeHorizon: '1-3 years',
        expectedReturn: '12%+',
        volatility: 'High',
        examples: ['Small-cap stocks', 'Startups', 'Emerging markets']
    }
];

const RiskLevel = ({onSelect, loading, selectedIndustry}) => {
    const containerAnimation = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const itemAnimation = {
        hidden: {opacity: 0, y: 20},
        show: {opacity: 1, y: 0}
    };

    const handleSelect = (level) => {
        if (!loading) {
            onSelect(level);
        }
    };

    return (
        <TooltipProvider>
            <motion.div
                variants={containerAnimation}
                initial="hidden"
                animate="show"
                className="max-w-6xl mx-auto px-4"
            >
                <motion.div variants={itemAnimation} className="text-center mb-8">
                    <h1 className="text-3xl font-bold text-white mb-3">Select Your Risk Level</h1>
                    <p className="text-lg text-gray-400">
                        Choose an investment strategy that matches your risk tolerance
                        {selectedIndustry && ` for ${selectedIndustry.name}`}
                    </p>
                </motion.div>

                <motion.div variants={itemAnimation} className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {riskLevels.map((level) => (
                        <motion.div
                            key={level.id}
                            variants={itemAnimation}
                            whileHover={{scale: 1.02}}
                            whileTap={{scale: 0.98}}
                            onClick={() => handleSelect(level)}
                            className="cursor-pointer"
                        >
                            <Card
                                className={`h-full transition-all duration-300 ${
                                    level.color === 'blue' ? 'hover:border-blue-500/50 bg-blue-900/20' :
                                        level.color === 'yellow' ? 'hover:border-yellow-500/50 bg-yellow-900/20' :
                                            'hover:border-red-500/50 bg-red-900/20'
                                } border-gray-700`}
                            >
                                <div className="p-4 space-y-4">
                                    <div className="flex items-center gap-3">
                                        <level.icon
                                            className={`w-8 h-8 ${
                                                level.color === 'blue' ? 'text-blue-400' :
                                                    level.color === 'yellow' ? 'text-yellow-400' :
                                                        'text-red-400'
                                            }`}
                                        />
                                        <div>
                                            <h3 className="text-lg font-bold text-white">{level.name}</h3>
                                            <p className="text-sm text-gray-400">{level.description}</p>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-2 gap-3">
                                        <div className="space-y-1">
                                            <div className="flex items-center gap-2 text-gray-400">
                                                <Clock size={14}/>
                                                <span className="text-sm">Time Horizon</span>
                                            </div>
                                            <p className="text-sm font-medium text-white">{level.timeHorizon}</p>
                                        </div>
                                        <div className="space-y-1">
                                            <div className="flex items-center gap-2 text-gray-400">
                                                <DollarSign size={14}/>
                                                <span className="text-sm">Average Annual Return</span>
                                            </div>
                                            <p className="text-sm font-medium text-white">{level.expectedReturn}</p>
                                        </div>
                                    </div>

                                    <div className="space-y-2">
                                        <h4 className="text-sm font-medium text-white">Characteristics</h4>
                                        <ul className="space-y-1">
                                            {level.characteristics.map((char, index) => (
                                                <li key={index}
                                                    className="flex items-center gap-2 text-xs text-gray-400">
                                                    <span className="w-1 h-1 rounded-full bg-gray-400"/>
                                                    {char}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div>
                                        <div className="flex items-center justify-between mb-2">
                                            <h4 className="text-sm font-medium text-white">Example Types</h4>

                                        </div>
                                        <div className="flex flex-wrap gap-1.5">
                                            {level.examples.map((example) => (
                                                <span
                                                    key={example}
                                                    className="px-2 py-0.5 text-xs rounded-full bg-transparent border border-gray-700
                     text-gray-300 transition-all duration-200 hover:scale-95" // Changed these classes
                                                >
            {example}
        </span>
                                            ))}
                                        </div>
                                    </div>

                                    <button
                                        className={`w-full py-2 px-3 rounded-full flex items-center justify-center gap-2 text-sm
                                            ${level.color === 'blue' ? 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-400' :
                                            level.color === 'yellow' ? 'bg-yellow-500/20 hover:bg-yellow-500/30 text-yellow-400' :
                                                'bg-red-500/20 hover:bg-red-500/30 text-red-400'
                                        } transition-colors duration-200`}
                                        onClick={() => handleSelect(level)}
                                        disabled={loading}
                                    >
                                        <span>Select {level.name}</span>
                                        <ChevronRight size={16}/>
                                    </button>
                                </div>
                            </Card>
                        </motion.div>
                    ))}
                </motion.div>

                {loading && (
                    <motion.div
                        className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                    >
                        <Card className="p-4 bg-gray-900/90 border-gray-700">
                            <div className="text-center">
                                <motion.div
                                    animate={{rotate: 360}}
                                    transition={{duration: 2, repeat: Infinity, ease: "linear"}}
                                    className="w-10 h-10 mb-3 mx-auto"
                                >
                                    <BarChart className="w-full h-full text-blue-400"/>
                                </motion.div>
                                <p className="text-sm text-white">Analyzing market data...</p>
                            </div>
                        </Card>
                    </motion.div>
                )}
            </motion.div>
        </TooltipProvider>
    );
};

export default RiskLevel;