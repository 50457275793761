// src/pages/Blog/NewBlogPost.jsx
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BlogEditor from './components/BlogEditor';
import {blogService} from '../../services/blogService';
import {toast} from '../../components/ui/toast';

const NewBlogPost = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Define handleSave explicitly as a named function
    const handleSave = async (blogData) => {
        console.log('Saving blog:', blogData); // Debug log
        try {
            setLoading(true);
            const response = await blogService.createBlog(blogData);
            console.log('Blog created:', response); // Debug log
            toast({
                title: "Success",
                description: "Blog post created successfully",
                type: "success"
            });
            navigate('/admin/blog');
        } catch (error) {
            console.error('Create blog error:', error);
            toast({
                title: "Error",
                description: error.message || "Failed to create blog post",
                type: "error"
            });
        } finally {
            setLoading(false);
        }
    };

    console.log('Rendering NewBlogPost'); // Debug log

    return (
        <div className="max-w-7xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold text-white mb-6">Create New Blog Post</h1>
            <BlogEditor
                onSave={handleSave} // Make sure this is passed correctly
                loading={loading}
                initialData={null}
            />
        </div>
    );
};

export default NewBlogPost;