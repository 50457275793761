import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {motion} from 'framer-motion';
import {FiCheck} from 'react-icons/fi';
import TaxYearSelector from '../TaxYearSelector';
import FloatingWidgets from '../../../../components/FloatingWidgets';

import '../../../../styles/TaxCalculator/Layout.css';

const Layout = ({children, formData = {}, setFormData}) => {
    const location = useLocation();
    const [chatHistory, setChatHistory] = useState([]);

    // Add this effect to clear chat history at results page
    useEffect(() => {
        if (location.pathname.includes('/results')) {
            setChatHistory([]);
        }
    }, [location]);

    const steps = [
        {id: 1, name: 'Basic Info', path: '/basic-info'},
        {id: 2, name: 'Income', path: '/income'},
        {id: 3, name: 'Deductions', path: '/deductions'},
        {id: 4, name: 'Credits', path: '/credits'}
    ];

    const isResultsPage = location.pathname.includes('/results');
    const isStateSelectionPage = location.pathname === '/tax-calculator';
    const isNotChatPage = !location.pathname.includes('/chat');

    const getCurrentStepIndex = () => {
        const currentPath = location.pathname;
        return steps.findIndex(step => currentPath.includes(step.path));
    };

    const handleYearChange = (year) => {
        setFormData(prev => ({
            ...prev,
            year: year
        }));
    };

    return (
        <div className="tax-layout">
            {/* Year Selector - Only show if not on state selection or results page */}
            {!isStateSelectionPage && !isResultsPage && (
                <TaxYearSelector
                    selectedYear={formData.year}
                    onChange={handleYearChange}
                />
            )}

            {/* Progress Steps - Only show if not on results or state selection page */}
            {!isResultsPage && !isStateSelectionPage && (
                <div className="steps-container">
                    <div className="steps-track">
                        {steps.map((step, index) => {
                            const currentStep = getCurrentStepIndex();
                            const isActive = index === currentStep;
                            const isCompleted = index < currentStep;

                            return (
                                <div key={step.id} className="step-wrapper">
                                    <div
                                        className={`step-item ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}`}>
                                        <div className="step-indicator">
                                            {isCompleted ? (
                                                <motion.div
                                                    initial={{scale: 0}}
                                                    animate={{scale: 1}}
                                                    className="check-icon"
                                                >
                                                    <FiCheck size={12}/>
                                                </motion.div>
                                            ) : (
                                                <span>{step.id}</span>
                                            )}
                                        </div>
                                        <span className="step-name">{step.name}</span>
                                    </div>
                                    {index < steps.length - 1 && (
                                        <div className={`step-connector ${isCompleted ? 'completed' : ''}`}/>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {/* Main Content */}
            <motion.div
                className="main-content"
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.3}}
            >
                {children}
            </motion.div>

            {/* Floating Widgets - Only show if not on chat page */}
            <FloatingWidgets
                persistentChatHistory={chatHistory}
                onChatHistoryUpdate={setChatHistory}
            />
        </div>
    );
};

export default Layout;